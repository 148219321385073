import React, { useMemo } from 'react'

import { Alert } from '@barracuda-internal/bds-core'

import Loading from 'global/components/lib/loading/Loading'
import { useErrorFormatMessage } from 'global/lib/localization'

import ScanInProgress from 'sen/components/pages/dashboard/scanInProgress/ScanInProgress'
import useSentinelContentCondition, {
  UseSentinelContentConditionParams,
  UIState
} from 'sen/components/lib/useSentinelContentCondition/useSentinelContentCondition'
import NoPermission from 'sen/components/lib/noPermission/NoPermission'
import ContentLayout from 'sen/components/lib/layout/ContentLayout'

export type UseSentinelContentConditionProps = UseSentinelContentConditionParams

export interface SentintelContentLoader {
  default: (props: UseSentinelContentConditionProps, userEmail: string) => any
  loading?: (props: UseSentinelContentConditionProps, userEmail: string) => any
  missedEntitlement?: (props: UseSentinelContentConditionProps, userEmail: string) => any
  scanInProgress?: (props: UseSentinelContentConditionProps, userEmail: string) => any
  failedToLoadScan?: (props: UseSentinelContentConditionProps, userEmail: string) => any
}

export default function sentintelContentLoader(loaderConfig: SentintelContentLoader) {
  const SentintelContentLoader: React.FC<UseSentinelContentConditionProps> = props => {
    const formatErrorMessage = useErrorFormatMessage()
    const [uiState, userEmail, apiError] = useSentinelContentCondition()

    return useMemo(() => {
      switch (uiState) {
        case UIState.failedToLoadScan:
          return (
            loaderConfig.failedToLoadScan?.(props, userEmail) || (
              <Alert variant="filled" severity="error" data-field="sentinelcontentloader-alert">
                {formatErrorMessage(apiError as string)}
              </Alert>
            )
          )
        case UIState.loading:
          return loaderConfig.loading?.(props, userEmail) || <Loading data-field="loading" />
        case UIState.missedEntitlement:
          return loaderConfig.missedEntitlement?.(props, userEmail) || <NoPermission product="sen" />
        case UIState.scanInProgress:
          return loaderConfig.scanInProgress?.(props, userEmail) || <ScanInProgress userEmail={userEmail} />
        default:
          return <ContentLayout data-field="content-layout">{loaderConfig.default(props, userEmail)}</ContentLayout>
      }
    }, [uiState, userEmail, apiError, formatErrorMessage, props])
  }

  return SentintelContentLoader
}
