import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import reducerHandlers from 'global/redux/features/dataTables/reducerHandlers'
import { UpdateDataTable } from 'global/types/dataTables/dataTables'

import { DataTableState, ColumnsConfig, GridColumns } from 'sen/redux/types/dataTables'

export const GRID_COLUMNS: GridColumns = {
  COUNTRY: 'country',
  REPORTS: 'reports'
}

export const COLUMNS_CONFIG: ColumnsConfig = {
  [GRID_COLUMNS.COUNTRY]: {
    sortable: false,
    orderIndex: 0,
    show: true
  },
  [GRID_COLUMNS.REPORTS]: {
    sortable: false,
    orderIndex: 1,
    show: true
  }
}

const ITEMS_PER_PAGE = 5

// Store
export const INITIAL_STATE: DataTableState = {
  SORT_FIELDS: {},
  GRID_COLUMNS,
  ITEMS_PER_PAGE,
  columnsConfig: COLUMNS_CONFIG,
  skip: 0, // offset
  take: ITEMS_PER_PAGE, // limit
  ITEMS_PER_REQUEST: 5,
  filter: null,
  search: '',
  sort: []
}

export const dmarcFraudUnifiedDataSlice = createSlice({
  name: 'DATA_TABLE/DMARC_FRAUD_UNIFIED_DATA',
  initialState: INITIAL_STATE,
  reducers: {
    update: {
      reducer: (state: DataTableState, action: PayloadAction<{ config: UpdateDataTable }>) => {
        return reducerHandlers({ COLUMNS_CONFIG }).update({ state, payload: action.payload })
      },
      prepare: (config: UpdateDataTable) => ({
        payload: { config }
      })
    },
    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  }
})

export const { update, reset } = dmarcFraudUnifiedDataSlice.actions

export default dmarcFraudUnifiedDataSlice.reducer
