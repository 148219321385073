import { useEffect } from 'react'

import { useAppSelector } from 'global/redux/toolkit/hooks'

declare global {
  interface Window {
    noticeable: any
  }
}

export interface NavbarNoticableWidgetLogicParams {
  noticableInfo: any
  title: string
  trackNoticeableClick: any
}

export default function useNoticableWidgetLogic(params: NavbarNoticableWidgetLogicParams): [] {
  const hasUser = useAppSelector((_stores: any) => !!_stores.user.data.id)

  useEffect(() => {
    if (hasUser && window.noticeable) {
      window.noticeable.render(params.noticableInfo.type, params.noticableInfo.widgetId)
    }
  }, [params.noticableInfo.type, params.noticableInfo.widgetId, hasUser])

  return []
}
