import React, { useMemo } from 'react'

import { Grid } from '@barracuda-internal/bds-core'

import RecentAttacksTab from 'sen/components/pages/dashboard/content/tabs/recentAttacks/RecentAttacks'

const DashboardContent: React.FC = () => {
  return useMemo(
    () => (
      <Grid container direction="column" data-field="root-grid">
        <RecentAttacksTab />
      </Grid>
    ),
    []
  )
}

export default DashboardContent
