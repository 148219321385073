import { reduxStore } from 'global/lib/reduxStore'
import { setCurrentAccessToken } from 'global/redux/features/accessToken/accessTokenSlice'
import { User } from 'global/types/api/userType'
import accessTokenLib from 'global/lib/accessToken/accessToken'
import { resetLoggedOut } from 'global/redux/features/app/appSlice'
import { validSerialStates, validateOnPremUser } from 'global/components/lib/authLayer/validateUser'

import { setUser } from 'sen/redux/features/user/userSlice'
import routesConfig, { UiRoute } from 'sen/lib/routes/routesConfig'

const CLEANUP_ROUTES: UiRoute['id'][] = [routesConfig.CLEANUP_TABLE.id, routesConfig.CLEANUP.id]

export interface GotoReportPageParams {
  user?: User
  scan?: any
  accessTokenId?: string
}

export default function gotoReportPage({ user, accessTokenId }: GotoReportPageParams) {
  // onPrem check
  if (user && validateOnPremUser(user, routesConfig)) {
    return
  }

  // goto demo if guest user
  if (user?.isGuest) {
    routesConfig.DEMO_REPORT.goto()

    return
  }

  reduxStore.dispatch(resetLoggedOut())

  // store optional values
  if (user) {
    reduxStore.dispatch(setUser(user))
  }

  const validatedAccessTokenId =
    accessTokenId || accessTokenLib.getDefaultBccAccountAccessTokenId() || accessTokenLib.getAllAccessTokens()[0]?.id

  if (!validatedAccessTokenId) {
    if (!user?.accounts.some(account => validSerialStates.includes(account?.bccSerialNumbers?.sentinel?.state))) {
      routesConfig.START_TRIAL.goto()
      return
    }

    routesConfig.SIGNIN_CONNECT.goto()

    return
  }

  // set new accessToken then move to the new report page
  reduxStore.dispatch(setCurrentAccessToken(validatedAccessTokenId))

  // goto the report page
  const activePath = reduxStore.getState()?.app?.activePath
  // check if it is cleanup page without reportId so we can redirect to cleanup_table
  const isCleanupPage = CLEANUP_ROUTES.some(routeId => routeId === activePath?.id)

  if (!activePath || activePath?.isPublicRoute) {
    routesConfig.DASHBOARD.goto({ reportId: validatedAccessTokenId })
  } else if (isCleanupPage) {
    routesConfig.CLEANUP_TABLE.goto({ reportId: validatedAccessTokenId })
  } else {
    const activeRoute = routesConfig[activePath.id]
    const targetRoute = activeRoute.metadata?.accountSwitchTargetId
      ? routesConfig[activeRoute.metadata?.accountSwitchTargetId]
      : activeRoute
    targetRoute.goto({ reportId: validatedAccessTokenId })
  }
}
