import { useEffect, useMemo, useCallback } from 'react'

import useProductLib from 'global/lib/product/useProduct'
import useAccessTokenLib from 'global/lib/accessToken/useAccessToken'
import useUserDataLib from 'global/lib/userData/useUserData'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { FEATURES, isMyFeatureOn } from 'global/lib/splitio/splitio'
import { Products } from 'global/types/productsType'
import { config } from 'global/lib/config'

import { useAppSelector } from 'sen/redux/toolkit/hooks'

export interface LicenseContentLogicProps {
  enabled: boolean
  serial: string
  accessTokenId: string | undefined
}

export interface UseLicenseContentLogicProps {
  product: Products
}

export default function useLicenseContentLogic({ product }: UseLicenseContentLogicProps): [LicenseContentLogicProps] {
  const { accessTokenId, splitStore } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id,
    splitStore: _stores.splitio
  }))
  const [productLib] = useProductLib()
  const [accessTokenLib] = useAccessTokenLib()
  const [userDataLib] = useUserDataLib()

  // Use the current account Id to select splitio treatment value
  const accountId = useMemo(() => userDataLib.getAccountByAccessToken(accessTokenId)?.accountId, [
    accessTokenId,
    userDataLib
  ])
  const isLicenseComplianceOn = useMemo(() => isMyFeatureOn(splitStore, FEATURES.IP_LICENSING_COMPLIANCE, accountId), [
    accountId,
    splitStore
  ])

  // init
  useEffect(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.LC_PAGEVIEW, { url: window.location.href })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const serial = useMemo(() => {
    return productLib.getProductSerialNumberForAccessToken(accessTokenId, product)
  }, [accessTokenId, productLib, product])

  const hasEntitlement = useCallback(() => {
    switch (true) {
      case config.domainConfig.isSentinel:
        return accessTokenLib.hasSentinelEntitlement(accessTokenId)
      case config.domainConfig.isForensics:
        return accessTokenLib.hasForensicsEntitlement(accessTokenId)
      default:
        return undefined
    }
  }, [accessTokenLib, accessTokenId])

  return useMemo(() => {
    return [
      {
        enabled:
          isLicenseComplianceOn && !!serial && hasEntitlement() && !userDataLib.isMspManagedAccount(accessTokenId),
        serial,
        accessTokenId
      }
    ]
  }, [isLicenseComplianceOn, serial, accessTokenId, hasEntitlement, userDataLib])
}
