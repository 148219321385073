import React from 'react'
import PropTypes from 'prop-types'

import { MenuItem, ListItemIcon } from '@barracuda-internal/bds-core'
import { UserGroup as GroupIcon } from '@barracuda-internal/bds-core/dist/Icons/Core'

import styles from 'global/components/lib/layout/navbar/navbarStyles'
import useDialogLogic from 'global/lib/dialogs/useDialogLogic'
import { useFormatMessage } from 'global/lib/localization'

export interface NavbarContextMenuManageUsersProps {
  Dialog: any
}

const BASE_I18N_KEY = 'app.logoutmenu'

const NavbarContextMenuManageUsers: React.FC<NavbarContextMenuManageUsersProps> = ({ Dialog }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()
  const [isDialogOpened, toggleDialog] = useDialogLogic()

  return (
    <MenuItem className={classes.navbarMenuContextItem} onClick={toggleDialog}>
      <ListItemIcon>
        <GroupIcon />
      </ListItemIcon>
      {isDialogOpened && <Dialog open={isDialogOpened} onClose={toggleDialog} />}
      {formatMessage('manage_users')}
    </MenuItem>
  )
}

NavbarContextMenuManageUsers.propTypes = {
  Dialog: PropTypes.any.isRequired
}

export default NavbarContextMenuManageUsers
