import React, { useMemo } from 'react'

import { Button, Grid, Alert } from '@barracuda-internal/bds-core'

import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import { useFormatMessage, useErrorFormatMessage } from 'global/lib/localization'

import useAtoTabLogic from 'sen/components/lib/dialogs/settings/atoTab/useAtoTabLogic'
import styles from 'sen/components/lib/dialogs/settings/atoTab/atoTabStyles'
import settingsStyles from 'sen/components/lib/dialogs/settings/settingsDialogStyles'
import AdminModeSelector from 'sen/components/lib/dialogs/settings/shared/AdminMode'

const BASE_I18N_KEY = 'sen.app.settings_dialog.ato_tab'

export interface AtoTabProps {
  onClose: () => void
}

export const AtoTab: React.FC<AtoTabProps> = ({ onClose }) => {
  const classes = styles()
  const settingsClasses = settingsStyles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const errorFormatMessage = useErrorFormatMessage()

  const [tabConfig, adminModeSection] = useAtoTabLogic()

  const isEmailFieldDisabled =
    adminModeSection.isDisabled || Number(adminModeSection.radioButtonsConfig.selectedRadioButton) !== 1

  return useMemo(
    () => (
      <Grid container className={settingsClasses.dialogTabContent} direction="column">
        {tabConfig.errorMsg && (
          <Alert className={settingsClasses.error} severity="error">
            {errorFormatMessage(tabConfig.errorMsg)}
          </Alert>
        )}

        {tabConfig.isTabInProgress && <LinearProgress className={settingsClasses.loadingIndicator} />}

        <Grid container className={classes.lastSection} data-section="admin-mode" direction="column">
          <AdminModeSelector
            withTitle
            BASE_I18N_KEY={BASE_I18N_KEY}
            isTabInProgress={tabConfig.isTabInProgress}
            {...adminModeSection}
          />
        </Grid>

        <Grid container className={settingsClasses.buttonsContainer} alignItems="center" justifyContent="flex-end">
          <Button className={settingsClasses.formButton} color="primary" variant="text" size="large" onClick={onClose}>
            {formatMessage('cancel')}
          </Button>
          <Button
            className={settingsClasses.formButton}
            onClick={tabConfig.onSaveChanges}
            disabled={tabConfig.isSaveButtonDisabled || (!adminModeSection.isValidAdminEmail && !isEmailFieldDisabled)}
            color="primary"
            variant="contained"
            size="large"
          >
            {formatMessage('save')}
          </Button>
        </Grid>
      </Grid>
    ),
    [
      classes,
      settingsClasses,
      formatMessage,
      errorFormatMessage,
      tabConfig,
      adminModeSection,
      onClose,
      isEmailFieldDisabled
    ]
  )
}

export default AtoTab
