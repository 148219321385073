import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Button, Grid } from '@barracuda-internal/bds-core'
import { Settings as SettingsIcon } from '@barracuda-internal/bds-core/dist/Icons/Core'

import useDialogLogic from 'global/lib/dialogs/useDialogLogic'
import * as analyticsLib from 'global/lib/analytics/analyticsService'

import useDashboardHeaderLogic from 'sen/components/pages/dashboard/header/useDashboardHeaderLogic'
import ReportMissedAttackDialog from 'sen/components/lib/dialogs/reportMissedAttack/ReportMissedAttackDialog'
import TestAiDialog from 'sen/components/lib/dialogs/testAi/TestAiDialog'
import SettingsDialog from 'sen/components/lib/dialogs/settings/SettingsDialog'
import styles from 'sen/components/pages/dashboard/header/dashboardHeaderStyles'

import { useFormatMessage } from 'global/lib/localization'

const BASE_I18N_KEY = 'sen.app.dashboard.header'

export interface DashboardHeaderProps {
  scanInProgress?: boolean
}

const DashboardHeader: React.FC<DashboardHeaderProps> = ({ scanInProgress }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()
  const [isReportMissedAttackDialogOpened, toggleReportMissedAttackDialog] = useDialogLogic()
  const [isTestAiDialogOpened, toggleTestAiDialog] = useDialogLogic()
  const [isSettingsDialogOpened, toggleSettingsDialog] = useDialogLogic()
  const [sendTrackEventInfo] = useDashboardHeaderLogic()

  return useMemo(() => {
    return (
      <Grid container justifyContent="flex-end" direction="row" data-field="root-grid">
        {isReportMissedAttackDialogOpened && <ReportMissedAttackDialog onClose={toggleReportMissedAttackDialog} />}
        {isTestAiDialogOpened && <TestAiDialog onClose={toggleTestAiDialog} />}
        {isSettingsDialogOpened && <SettingsDialog onClose={toggleSettingsDialog} />}
        {!scanInProgress && (
          <Button
            color="primary"
            size="large"
            variant="text"
            onClick={() => {
              toggleReportMissedAttackDialog()
              sendTrackEventInfo(analyticsLib.EVENTS.REPORT_MISSED_ATTACK)
            }}
            data-field="missed-attack-button"
          >
            {formatMessage('report_missed_attack')}
          </Button>
        )}
        {!scanInProgress && (
          <Button
            color="primary"
            variant="contained"
            size="large"
            className={classes.aiButton}
            onClick={() => {
              toggleTestAiDialog()
              sendTrackEventInfo(analyticsLib.EVENTS.TEST_AI_OPEN)
            }}
            data-field="ai-button"
          >
            &nbsp;{formatMessage('test_ai')}&nbsp;
          </Button>
        )}
        <SettingsIcon
          data-field="settings"
          data-testid="settings"
          className={classes.icon}
          onClick={() => {
            toggleSettingsDialog()
            sendTrackEventInfo(analyticsLib.EVENTS.SP_OPEN_SETTINGS)
          }}
        />
      </Grid>
    )
  }, [
    isReportMissedAttackDialogOpened,
    toggleReportMissedAttackDialog,
    isTestAiDialogOpened,
    toggleTestAiDialog,
    isSettingsDialogOpened,
    toggleSettingsDialog,
    formatMessage,
    classes,
    sendTrackEventInfo,
    scanInProgress
  ])
}

DashboardHeader.propTypes = {
  scanInProgress: PropTypes.bool
}

DashboardHeader.defaultProps = {
  scanInProgress: false
}

export default DashboardHeader
