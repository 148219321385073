import axios, { AxiosPromise } from 'axios'
import qs from 'qs'

import { config } from 'global/lib/config'

const CONTENT_TYPE = 'application/json;charset=utf-8'
const EXPIRES = '-1'
const CACHE_CONTROL = 'no-cache,no-store,must-revalidate,max-age=-1,private'

interface Request {
  method: any
  path(params: {}): string
}

interface Options {
  data?: {} // body values
  params?: {} // query params
  urlParams?: {} // url params
  auth?: Auth // basic auth header
  headers?: {}
}

export interface Auth {
  username?: string
  password?: string
}

export interface ResponseGenerator {
  config?: any
  data?: any
  headers?: any
  request?: any
  status?: number
  statusText?: string
}

export interface ApiSuccessResponse<Data> {
  data: Data
}

export interface ApiSuccessReportWithOffset<Report> {
  report: Report
  offset: number
}

export interface ApiRejectResponse {
  rejectValue: string
}

// interceptors
let requestInterceptors: any = null
let responseInterceptors: any = null

// TODO: add host check when deployed
export function setRequestInterceptors(requestMiddleware: any): void {
  requestInterceptors = axios.interceptors.request.use(requestMiddleware as any)
}

export function setResponseInterceptors(responseError: any): void {
  responseInterceptors = axios.interceptors.response.use(undefined, error => {
    return responseError(error.response)
  })
}

export function ejectInterceptors(): void {
  axios.interceptors.request.eject(requestInterceptors)
  axios.interceptors.response.eject(responseInterceptors)

  requestInterceptors = null
  responseInterceptors = null
}

export function generateQueryParams(params?: {}): string {
  if (params) {
    return `?${qs.stringify(params)}`
  }

  return ''
}

export function initRestClient({ requestMiddleware, responseError }: { requestMiddleware: any; responseError: any }) {
  setRequestInterceptors(requestMiddleware)
  setResponseInterceptors(responseError)
}

function generateRequestParams(request: Request, options: Options = {}): any {
  const { path, method } = request
  const { urlParams, ...requestOptions } = options
  const url = path(urlParams || {})

  return {
    ...requestOptions,
    headers: {
      'Content-Type': CONTENT_TYPE,
      Accept: CONTENT_TYPE,
      Expires: EXPIRES,
      'Cache-Control': CACHE_CONTROL,
      ...(requestOptions.headers || {})
    },
    method,
    url
  }
}

// api errors
export function validateApiError(e: any) {
  if (!e) {
    return 'default'
  }

  switch (true) {
    case e === config.apiError?.DUPLICATE_INCIDENT_IN_PROGRESS:
      return 'duplicate_incident_in_progress'
    case e === config.apiError?.SERIAL_LINKING_CODE_INVALID:
      return 'serial_linking_code_invalid'
    case e.data?.error === config.apiError?.OFFICE_GRAPH_FINAL_ERROR && e.data?.isFinal:
      return 'inbox_rules_final_error'
    case e.data?.error === config.apiError?.FORENSICS_DEMO_ENABLED_ERROR:
    case e.data?.error === config.apiError?.SENTINEL_DEMO_ENABLED_ERROR:
      return 'demo_error'
    case e.data?.error === config.apiError?.DUPLICATE_WHITELIST:
      return 'duplicate_whitelist'
    case e.data?.error === config.apiError?.TENANT_ALREADY_ON_SENTINEL:
      return 'account_exists_on_sentinel'
    case e.data?.error === config.apiError?.ETS_IS_READ_ONLY:
      return 'account_is_read_only'
    case e.data?.error === config.API_ERROR.INVALID_CREDENTIALS:
      return 'incorrect_authentication'
    case e.data?.error === config.apiError?.USER_ALREADY_EXISTS:
      return 'user_already_exists'
    case e.data?.error === config.apiError?.DOMAIN_ALREADY_EXISTS:
      return 'domain_already_exists'
    case e.data?.error === config.apiError?.BAD_PASSOWORD_EXCEPTION:
      return 'bad_password_exception'
    case e.data?.error === config.apiError?.ADDRESS_BOOK_ALREADY_CREATED:
      return 'address_book_already_created'
    case e.data?.error?.code === config.apiError?.USER_DPL_CHECK_FAILURE:
    case e.data?.error?.code === config.apiError?.USER_CREATE_DPL_CHECK_FAILURE:
      return 'user_create_dpl_check_failure'
    case e.data?.error?.code === config.apiError?.INCIDENT_DOES_NOT_EXIST:
      return 'incident_does_not_exist'
    case e.data?.error.includes(config.apiError?.REPORT_DOES_NOT_EXIST):
      return 'report_does_not_exist'
    case e.message:
      return 'validation_issue'
    default:
      return 'default'
  }
}

// rest interface
export default function restClient(request: Request, options: Options = {}): AxiosPromise<any> {
  return axios(generateRequestParams(request, options))
}
