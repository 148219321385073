import { useMemo, useCallback, useReducer, useState } from 'react'

import { process } from '@progress/kendo-data-query'
import validator from 'validator'

import { BDSGridPagerConfig } from 'global/types/dataTables/dataTables'
import { useEffectOnInit, useEffectWhenRendered } from 'global/lib/useCustomEffect'

import {
  getWhitelistItemList,
  editWhitelistItem,
  addWhitelistItem,
  deleteWhitelistItem,
  resetWhitelistItemList,
  resetWhitelistItem
} from 'sen/redux/features/settings/settingsSlice'
import { GridColumns, ColumnsConfig } from 'sen/redux/types/dataTables'
import { WhitelistItem } from 'sen/redux/features/settings/types'
import {
  update as updateAllowedSendersTable,
  reset as resetAllowedSendersTable
} from 'sen/redux/features/dataTables/allowedSenders/allowedSendersSlice'
import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import { isPending, isSuccess } from 'global/redux/toolkit/api'

export type IsTabInProgress = boolean
export type OnSave = () => void
export type Error = string | undefined
export type OnToggleView = (id?: string) => void
export type OnEditAllowedSender = (id: string) => void
export type OnDeleteAllowedSender = (id: string) => void
export interface TabView {
  isTableView: boolean
  toggle: OnToggleView
}

export interface FormHandlers {
  onUpdateAllowedSender: (newValue: Partial<AllowedSenderForm>) => void
  onSave: OnSave
}

export interface FormStates {
  isValidAllowedSender: boolean
  isSaveButtonDisabled: boolean
  error: Error
}

export interface AllowedSenderForm extends Omit<WhitelistItem, 'id'> {
  id: string | undefined
}

export type DataTableRow = WhitelistItem & {
  onEdit: OnEditAllowedSender
  onDelete: OnDeleteAllowedSender
  isDeleting: boolean
}

export interface TableData {
  total: number
  data: DataTableRow[]
}

export interface TableConfig {
  tableData: TableData
  pageConfig: BDSGridPagerConfig
  columns: GridColumns
  columnsConfig: ColumnsConfig
  isLoaded: boolean
}

const DEFAULT_ALLOWED_SENDER: AllowedSenderForm = {
  id: undefined,
  pattern: '',
  remarks: ''
}

export default function useAllowedSendersTabLogic(): [
  IsTabInProgress,
  TabView,
  TableConfig,
  AllowedSenderForm,
  FormHandlers,
  FormStates
] {
  const dispatch = useAppDispatch()

  const {
    isGetWhitelistItems,
    isGetWhitelistItemsSuccess,
    whitelist,
    isAddWhitelistItem,
    isEditWhitelistItem,
    isDeleteWhitelistItem,
    isAddWhitelistItemSuccess,
    isEditWhitelistItemSuccess,
    isDeleteWhitelistItemSuccess,
    allowedSendersTable,
    settingsError
  } = useAppSelector(_stores => ({
    isGetWhitelistItems: isPending(_stores.settings.getWhitelistItemListApiStatus),
    isGetWhitelistItemsSuccess: isSuccess(_stores.settings.getWhitelistItemListApiStatus),
    whitelist: _stores.settings.whitelist,
    isAddWhitelistItem: isPending(_stores.settings.addWhitelistItemApiStatus),
    isEditWhitelistItem: isPending(_stores.settings.editWhitelistItemApiStatus),
    isDeleteWhitelistItem: isPending(_stores.settings.deleteWhitelistItemApiStatus),
    isAddWhitelistItemSuccess: isSuccess(_stores.settings.addWhitelistItemApiStatus),
    isEditWhitelistItemSuccess: isSuccess(_stores.settings.editWhitelistItemApiStatus),
    isDeleteWhitelistItemSuccess: isSuccess(_stores.settings.deleteWhitelistItemApiStatus),
    allowedSendersTable: _stores.dataTables.allowedSenders,
    settingsError: _stores.settings.errorMsg
  }))
  const [allowedSender, setAllowedSender] = useReducer(
    (_state: AllowedSenderForm, newState: Partial<AllowedSenderForm>) => ({ ..._state, ...newState }),
    DEFAULT_ALLOWED_SENDER
  )
  const [isTableView, setIsTableView] = useState<boolean>(true)
  const [deletedId, setDeletedId] = useState<string | undefined>()

  // init
  useEffectOnInit(() => {
    if (!isGetWhitelistItems && !isGetWhitelistItemsSuccess) {
      dispatch(getWhitelistItemList())
    }
  }, [])

  // after successfully updated or added an allowed sender
  useEffectWhenRendered(() => {
    if (isAddWhitelistItemSuccess || isEditWhitelistItemSuccess) {
      dispatch(resetWhitelistItemList())
      dispatch(getWhitelistItemList())

      onToggleView()
    }
  }, [isAddWhitelistItemSuccess, isEditWhitelistItemSuccess])

  // after successfully deleted an allowed sender
  useEffectWhenRendered(() => {
    if (isDeleteWhitelistItemSuccess) {
      dispatch(resetAllowedSendersTable())
      dispatch(resetWhitelistItemList())
      dispatch(getWhitelistItemList())
      setDeletedId(undefined)
    }
  }, [isDeleteWhitelistItemSuccess])

  const isTabInProgress: boolean = useMemo(() => {
    return isGetWhitelistItems || isAddWhitelistItem || isEditWhitelistItem || isDeleteWhitelistItem
  }, [isGetWhitelistItems, isAddWhitelistItem, isEditWhitelistItem, isDeleteWhitelistItem])

  const isValidAllowedSender: boolean = useMemo(() => {
    return (
      !!allowedSender.pattern.length &&
      (validator.isEmail(allowedSender.pattern) || validator.isURL(allowedSender.pattern))
    )
  }, [allowedSender.pattern])

  const getSelectedAllowedSender = useCallback(
    (id: string | undefined) => {
      if (!id) {
        return undefined
      }

      return whitelist.report?.data?.find(sender => sender && sender.id === id)
    },
    [whitelist]
  )

  const isSaveButtonDisabled: boolean = useMemo(() => {
    const selectedAllowedSender = getSelectedAllowedSender(allowedSender.id)

    return (
      isTabInProgress ||
      !isValidAllowedSender ||
      (!!allowedSender.id &&
        allowedSender.pattern === selectedAllowedSender?.pattern &&
        allowedSender.remarks === selectedAllowedSender?.remarks)
    )
  }, [isTabInProgress, isValidAllowedSender, getSelectedAllowedSender, allowedSender])

  const onToggleView: OnToggleView = useCallback(
    id => {
      if (isTableView) {
        const selectedAllowedSender = getSelectedAllowedSender(id)

        setAllowedSender({
          id,
          pattern: selectedAllowedSender?.pattern || '',
          remarks: selectedAllowedSender?.remarks || ''
        })
      } else {
        setAllowedSender(DEFAULT_ALLOWED_SENDER)
      }

      dispatch(resetWhitelistItem())
      setIsTableView(!isTableView)
    },
    [isTableView, dispatch, getSelectedAllowedSender]
  )

  const onEditAllowedSender: OnEditAllowedSender = useCallback(
    id => {
      onToggleView(id)
    },
    [onToggleView]
  )

  const onDeleteAllowedSender: OnDeleteAllowedSender = useCallback(
    id => {
      dispatch(deleteWhitelistItem({ id }))
      setDeletedId(id)
    },
    [dispatch]
  )

  const onSave: OnSave = useCallback(() => {
    if (allowedSender.id) {
      dispatch(editWhitelistItem(allowedSender as WhitelistItem))
    } else {
      dispatch(
        addWhitelistItem({
          pattern: allowedSender.pattern,
          remarks: allowedSender.remarks
        })
      )
    }
  }, [allowedSender, dispatch])

  const tableData: TableData = useMemo(() => {
    const { skip, take } = allowedSendersTable

    const { data } = process(
      (whitelist.report?.data || []).map(whitelistItem => ({
        ...(whitelistItem && {
          ...whitelistItem,
          onEdit: () => {
            onEditAllowedSender(whitelistItem.id as string)
          },
          onDelete: () => {
            onDeleteAllowedSender(whitelistItem.id as string)
          },
          isDeleting: deletedId === whitelistItem.id
        })
      })),
      { skip, take }
    )

    return {
      data: data.filter(report => report.id),
      total: whitelist.report?.totalCount || 0
    }
  }, [whitelist, allowedSendersTable, onDeleteAllowedSender, onEditAllowedSender, deletedId])

  const pageConfig: BDSGridPagerConfig = useMemo(() => {
    const { skip, take } = allowedSendersTable

    return {
      pageable: {
        buttonCount: 5
      },
      skip,
      take,
      total: tableData.total,
      onPageChange: (e: any) => {
        dispatch(updateAllowedSendersTable(e.page))
      }
    }
  }, [allowedSendersTable, tableData, dispatch])

  return useMemo(() => {
    return [
      isTabInProgress,
      {
        isTableView,
        toggle: onToggleView
      },
      {
        tableData,
        pageConfig,
        columns: allowedSendersTable.GRID_COLUMNS,
        columnsConfig: allowedSendersTable.columnsConfig,
        isLoaded: !!whitelist.report?.data
      },
      allowedSender,
      {
        onUpdateAllowedSender: setAllowedSender,
        onSave
      },
      {
        isValidAllowedSender,
        isSaveButtonDisabled,
        error: settingsError
      }
    ]
  }, [
    isTabInProgress,
    onSave,
    isTableView,
    settingsError,
    isSaveButtonDisabled,
    whitelist,
    allowedSendersTable,
    onToggleView,
    pageConfig,
    tableData,
    allowedSender,
    isValidAllowedSender
  ])
}
