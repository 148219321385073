import React, { useMemo } from 'react'

import { Button, Card, Typography } from '@barracuda-internal/bds-core'

import styles from 'global/components/features/startTrial/startTrialStyles'
import { useFormatMessage } from 'global/lib/localization'
import { config } from 'global/lib/config'

import useStartTrialLogic from './useStartTrialLogic'

export interface StartTrialProps {
  BASE_I18N_KEY: string
}

const StartTrial: React.FC<StartTrialProps> = ({ BASE_I18N_KEY }) => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const [startTrialLogic] = useStartTrialLogic()

  const { onAlreadyPurchased, onStartTrial, onUseAnotherAccount, userEmail, salesLeadEpId } = startTrialLogic

  return useMemo(
    () => (
      <div
        className={classes.wrapper}
        // TODO: Remove next line in BNFIR-3960
        style={config.domainConfig.isSentinel ? { left: 0, width: '100%', height: '100vh' } : undefined}
      >
        <Card className={classes.innerWrapper}>
          <Typography variant="h4" component="h1">
            {formatMessage('barracuda')}
          </Typography>
          <Typography variant="h4" component="h2" className={`${classes.bold} ${classes.marginBottomMedium}`}>
            {formatMessage('product')}
          </Typography>
          <Typography variant="h5" component="h3" className={classes.marginBottomMedium}>
            {formatMessage('subtitle')}
          </Typography>
          <Typography variant="body1" component="h4" className={classes.marginBottomSmall}>
            {formatMessage('product_description')}
          </Typography>
          <Typography variant="body1" component="h4">
            {formatMessage('try_free', {
              b: (txt: string) => (
                <b style={{ fontWeight: 600 }} key="bold-text">
                  {txt}
                </b>
              )
            })}
          </Typography>
          <Typography variant="body1" component="h4" className={classes.marginBottomSmall}>
            {formatMessage('no_credit_card')}
          </Typography>
          <Button
            href={`${config.emailProtectionStartTrialUrl}?ep_id=${salesLeadEpId}`}
            variant="contained"
            onClick={onStartTrial}
            color="primary"
            size="large"
            className={`${classes.button} ${classes.buttonSpacing} ${classes.marginBottomMedium}`}
          >
            {formatMessage('start_trial')}
          </Button>
          <Typography variant="body1" onClick={onAlreadyPurchased} className={classes.underline}>
            {formatMessage('already_purchased')}
          </Typography>
        </Card>
        <div className={classes.footerWrapper}>
          <Typography className={classes.footer1} align="center" data-field="footer-1">
            {userEmail}
          </Typography>
          <Typography variant="body2" component="a" onClick={onUseAnotherAccount} className={classes.footer2}>
            {formatMessage('use_another_account')}
          </Typography>
        </div>
      </div>
    ),
    [
      classes.bold,
      classes.button,
      classes.buttonSpacing,
      classes.footer1,
      classes.footer2,
      classes.footerWrapper,
      classes.innerWrapper,
      classes.marginBottomMedium,
      classes.marginBottomSmall,
      classes.underline,
      classes.wrapper,
      formatMessage,
      onAlreadyPurchased,
      onStartTrial,
      onUseAnotherAccount,
      salesLeadEpId,
      userEmail
    ]
  )
}

export default StartTrial
