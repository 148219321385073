const APP = {
  NAME: 'fir',
  LANGUAGE: 'en',
  LINKS: {
    CAMPUS_UNIFIED_REPORTING: 'https://campus.barracuda.com/product/forensics/doc/104366284/reports/',
    MSTEAMS_WEBHOOK_HELP:
      'https://docs.microsoft.com/en-us/microsoftteams/platform/webhooks-and-connectors/how-to/add-incoming-webhook',
    PRIVACY_POLICY: 'https://www.barracuda.com/support/security',
    SLACK_WEBHOOK_HELP: 'https://slack.com/help/articles/115005265063-Incoming-webhooks-for-Slack',
    REQUEST_A_DEMO: 'https://www.barracuda.com/products/email-protection/incident-response'
  },
  NEW_FEATURE_RELEASE_DATES: {
    AW_MSTEAMS_ACTION: '2022-12-29T00:00:00.00000Z'
  }
}

export default APP
