import { useCallback, useMemo, useReducer } from 'react'
import { ChipProps } from '@barracuda-internal/bds-core'

export type SubMemuItems = {
  routeId: string
  text: string
}

type SubMenuOpenState = {
  id: string
  isSubMenuOpen: boolean
}

export type ListItemProps = {
  disabled?: boolean
  id: string
  text: string
  chip?: (props: ChipProps) => JSX.Element
  hasSubMenu?: boolean
  icon?: (props: any) => JSX.Element
  subMenuItems?: SubMemuItems[]
}

export type NavbarSideMenuLogicParams = {
  listItems: ListItemProps[]
  onListItemSelected: (selectedMenu: string) => void
  selectedIndex?: number
}

export type NavbarSideMenuLogic = {
  subMenuOpenState: SubMenuOpenState
  toggle: boolean
  handleListItemClick: (index: number, routeId?: string) => () => void
  handleListItemMouseOver: (listItemId: string, hasSubMenu: boolean) => void
  setToggle: (value: boolean) => void
  toggleHamburger: () => void
}

export default function useSideMenuLogic(params: NavbarSideMenuLogicParams): [NavbarSideMenuLogic] {
  const [state, setState] = useReducer((_state: any, newState: any) => ({ ..._state, ...newState }), {
    toggle: false,
    subMenuOpenState: { id: '', isSubMenuOpen: false }
  })

  const toggleHamburger = useCallback(() => {
    setState({ toggle: !state.toggle })
  }, [state.toggle])

  const handleListItemClick = useCallback(
    (index: number, routeId?: string) => () => {
      if (routeId) {
        params.onListItemSelected(routeId)

        setState({ setSubMenuOpenState: { id: '', isSubMenuOpen: false } })
      } else {
        params.onListItemSelected(params.listItems[index].id)
      }
      toggleHamburger()
    },
    [params, toggleHamburger]
  )

  const handleListItemMouseOver = useCallback((listItemId: string, hasSubMenu: boolean) => {
    setState({ subMenuOpenState: { id: listItemId, isSubMenuOpen: hasSubMenu } })
  }, [])

  return useMemo(
    () => [
      {
        handleListItemClick,
        handleListItemMouseOver,
        subMenuOpenState: state.subMenuOpenState,
        setToggle: value => setState({ toggle: value }),
        toggle: state.toggle,
        toggleHamburger
      }
    ],
    [handleListItemClick, handleListItemMouseOver, state.subMenuOpenState, state.toggle, toggleHamburger]
  )
}
