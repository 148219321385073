import React, { useMemo } from 'react'

import { Button, Typography, Grid, Paper } from '@barracuda-internal/bds-core'

import CircularProgress from 'global/components/lib/circularProgress/CircularProgress'
import { useFormatMessage } from 'global/lib/localization'
import { formatDate, formatTime } from 'global/lib/datetime'

import NewIncidentDialog from 'sen/components/lib/dialogs/newIncidentDialog/NewIncidentDialog'
import NewIncidentDialogWizard from 'sen/components/lib/dialogs/newIncidentDialog/NewIncidentDialogWizard'
import useNewIncidentDialogLogic from 'sen/components/lib/dialogs/newIncidentDialog/useNewIncidentDialogLogic'

import useAtoOverviewLogic from 'sen/components/pages/ato/overview/useAtoOverviewLogic'
import styles from 'sen/components/pages/ato/overview/atoOverviewStyles'

const BASE_I18N_KEY = 'sen.app.account_takeover.overview'

const AtoOverview: React.FC = () => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()
  const [atoOverviewCardsConfig] = useAtoOverviewLogic()
  const [newIncidentDialogConfig, newIncidentDialogWizardConfig] = useNewIncidentDialogLogic()

  return useMemo(
    () => (
      <Grid container spacing={2} data-field="attack-cards-grid">
        {atoOverviewCardsConfig.map((cardConfig, idx) => {
          const [isLoaded, value, isSuccess, isDate = false] = cardConfig
          const countClassName = `cardCount${isSuccess ? 'Green' : 'Red'}${
            isDate ? 'Date' : ''
          }` as keyof typeof classes

          return (
            // eslint-disable-next-line react/no-array-index-key
            <Grid key={idx + 1} item xs={12} sm={12} md={4} lg={4} data-field={`${idx + 1}-card-grid`}>
              <Paper className={classes.card} elevation={0}>
                <Grid className={classes.cardCountWrapper} container alignItems="center" direction="row">
                  {!isLoaded && (
                    <Grid item>
                      <CircularProgress
                        className={classes.circularProgress}
                        data-field={`${idx + 1}-card-circular-progress`}
                      />
                    </Grid>
                  )}
                  {isLoaded && (
                    <Grid item data-field={`${idx + 1}-card`}>
                      <Typography className={classes[countClassName]} align="center">
                        {!isDate && value}
                        {isDate && (
                          <>
                            {formatDate(value)}
                            <br />
                            {formatTime(value)}
                          </>
                        )}
                      </Typography>
                    </Grid>
                  )}
                  <Grid item xs zeroMinWidth data-field={`${idx + 1}-card-count`}>
                    <Typography className={classes.cardCountTitle} align="left">
                      {formatMessage(`card_${idx + 1}_text`, { count: Number(value) })}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          )
        })}
        <Grid container item className={classes.newIncidentButtonWrapper}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              newIncidentDialogConfig.onOpen()
            }}
            data-field="incident-dialog-button"
            data-action="incident-dialog-button"
          >
            {formatMessage('new_incident_button')}
          </Button>
        </Grid>
        {newIncidentDialogConfig.open && (
          <NewIncidentDialog
            cleanupMessages={newIncidentDialogWizardConfig.onOpen}
            onClose={newIncidentDialogConfig.onClose}
          />
        )}
        {newIncidentDialogWizardConfig.open && (
          <NewIncidentDialogWizard onClose={newIncidentDialogWizardConfig.onClose} />
        )}
      </Grid>
    ),
    [classes, formatMessage, atoOverviewCardsConfig, newIncidentDialogConfig, newIncidentDialogWizardConfig]
  )
}

export default AtoOverview
