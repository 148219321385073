import { makeStyles } from '@material-ui/core/styles'

import tableStyles, { TABLE_FOOTER_HEIGHT } from 'global/components/lib/grid/tableStyles'
import tabStyles from 'global/theme/tabStyles'
import { COLORS } from 'global/configs/theme/defaults'

const DMARC_TAB_WIDTH = 250
export const CELL_HEIGHT = 75
const EMPTY_TABLE_HEIGHT = 100
const TABLE_MIN_HEIGHT = CELL_HEIGHT * 10 + TABLE_FOOTER_HEIGHT + 7

export default makeStyles(theme => {
  const tabClasses = tabStyles(theme)
  const defaultTableStyles = tableStyles(theme, CELL_HEIGHT, EMPTY_TABLE_HEIGHT)

  const tabMargin = theme.spacing(2)

  return {
    // table styles
    ...defaultTableStyles,
    tableWrapper: {
      padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
      paddingTop: 0
    },
    tableArea: {
      ...defaultTableStyles.tableArea,
      marginTop: theme.spacing(1),
      minHeight: TABLE_MIN_HEIGHT
    },
    flexibleTableArea: {
      ...defaultTableStyles.tableArea,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2)
    },
    reservedTableArea: {
      height: EMPTY_TABLE_HEIGHT
    },
    dataTable: {
      ...defaultTableStyles.dataTable,
      height: TABLE_MIN_HEIGHT,

      '& td': {
        lineHeight: `${theme.spacing(2.5)}px`
      },
      '& .k-grid-header': {
        height: 0
      }
    },
    tableDataWithHeader: {
      ...defaultTableStyles.dataTable,
      height: TABLE_MIN_HEIGHT,

      '& td': {
        lineHeight: `${theme.spacing(2.5)}px`
      }
    },
    emptyDataTable: {
      ...defaultTableStyles.emptyDataTable,
      padding: `0 ${theme.spacing(1)}px`,

      '& .k-grid-header': {
        height: 0
      }
    },
    emptyDataTableWithHeader: {
      ...defaultTableStyles.emptyDataTable,
      padding: `0 ${theme.spacing(1)}px`
    },
    flexibleDataTable: {
      ...defaultTableStyles.dataTable,

      '& td': {
        lineHeight: `${theme.spacing(2.5)}px`
      },
      '& .k-grid-header': {
        height: 0
      }
    },
    flexibleDataTableWithHeader: {
      ...defaultTableStyles.dataTable,

      '& td': {
        lineHeight: `${theme.spacing(2.5)}px`
      }
    },
    actionCell: {
      ...defaultTableStyles.normalCell,
      width: '100%'
    },
    greenNormaCell: {
      ...defaultTableStyles.normalCell,
      color: COLORS.GREEN_400
    },
    failedNormaCell: {
      ...defaultTableStyles.normalCell,
      color: COLORS.RED_600
    },

    // tab styles
    ...tabClasses,
    tabs: {
      ...tabClasses.tabs,
      borderBottom: 'none'
    },
    indicator: {
      ...tabClasses.indicator,
      top: 0
    },
    tabRoot: {
      textTransform: 'none',
      width: DMARC_TAB_WIDTH
    },
    container: {
      marginTop: theme.spacing(1)
    },
    headerTitle: {
      display: 'inline',
      marginBottom: theme.spacing(1),
      fontWeight: 500,
      fontSize: 20
    },
    linkTitle: {
      display: 'inline',
      marginTop: 3,
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(3),
      fontSize: 14
    },
    error: {
      marginBottom: theme.spacing(2)
    },
    errorWithTabMargin: {
      margin: `0 ${tabMargin}px`,
      marginBottom: theme.spacing(2)
    },
    title: {
      marginBottom: theme.spacing(1)
    },
    subtitle: {
      marginBottom: theme.spacing(2),
      lineHeight: '10px',
      color: COLORS.GRAY
    },
    sectionPaper: {
      padding: theme.spacing(2)
    },
    sourceSectionPaper: {
      paddingBottom: theme.spacing(2)
    },
    tabTitle: {
      marginTop: theme.spacing(1),
      cursor: 'pointer',
      color: '#000006',
      fontSize: 20
    },
    tabSubtitle: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      cursor: 'pointer',
      color: COLORS.GRAY,
      fontSize: 12,
      lineHeight: '12px'
    },
    dmarcTotalsContainer: {
      padding: `0 ${tabMargin}px`,
      width: DMARC_TAB_WIDTH
    },
    dmarcTotalCount: {
      fontWeight: 500,
      fontSize: 16
    },
    dmarcTotalText: {
      fontSize: 12,
      lineHeight: '13px'
    },
    tabInfoContainer: {
      margin: `${theme.spacing(2)}px ${tabMargin}px`,
      padding: theme.spacing(2),
      backgroundColor: COLORS.LIGHT_BACKGROUND
    },
    tabMargin: {
      margin: `0 ${tabMargin}px`
    },
    tabInfoTitle: {
      fontSize: 16,
      marginBottom: theme.spacing(1)
    },
    tabInfoSubtitle: {
      fontSize: 14,
      lineHeight: '18px'
    },
    searchfieldContainer: {
      margin: `0 ${tabMargin}px`,
      marginBottom: theme.spacing(3),

      '& .search-field-container': {
        backgroundColor: COLORS.LIGHT_BACKGROUND
      }
    },
    customHeaderTitle: {
      display: 'inline-block',
      fontSize: 12,
      lineHeight: '14px',
      fontWeight: 400,
      width: 200
    },
    tableTitleContainer: {
      marginTop: theme.spacing(1),
      position: 'relative',
      paddingTop: theme.spacing(0.5)
    },
    tableTitleSecondContainer: {
      position: 'relative',
      paddingTop: theme.spacing(0.5)
    },
    progressContainer: {
      position: 'relative'
    },
    tableTitleProgress: {
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0
    },
    tableTitle: {
      borderTop: `1px solid ${COLORS.PRODUCT_UI_ELEMENT}`,
      borderBottom: `1px solid ${COLORS.PRODUCT_UI_ELEMENT}`,
      padding: tabMargin,
      backgroundColor: COLORS.LIGHT_BACKGROUND,
      fontSize: 13,
      fontWeight: 500
    },
    lightText: {
      color: COLORS.GRAY
    },
    linkText: {
      cursor: 'pointer',
      color: COLORS.BLUE
    },
    linkIcon: {
      width: 14,
      verticalAlign: -theme.spacing(1)
    },
    blockIcon: {
      width: 14,
      verticalAlign: -theme.spacing(1),
      color: COLORS.RED_600,
      marginRight: theme.spacing(0.5)
    },
    checkmarIcon: {
      width: 14,
      verticalAlign: -theme.spacing(1),
      color: COLORS.GREEN_400,
      marginRight: theme.spacing(0.5)
    },
    passedIcon: {
      width: 20,
      verticalAlign: -7,
      color: COLORS.GREEN_400,
      marginRight: theme.spacing(0.5)
    },
    failedIcon: {
      width: 20,
      verticalAlign: -7,
      color: COLORS.RED_600,
      marginRight: theme.spacing(0.5)
    },
    titleButton: {
      float: 'right'
    },
    investigateButton: {
      marginBottom: theme.spacing(0.5)
    }
  }
})
