import { createAsyncThunk } from '@reduxjs/toolkit'
import restClient, { validateApiError } from 'global/lib/api/restClient'
import apiRoutes from 'sen/lib/api/apiRoutes'
import { buildReportQueryFor } from 'global/redux/features/dataTables/buildQueryForTable'
import { updateCurrentSettings } from 'global/redux/features/accessToken/accessTokenSlice'
import { RootState } from '../../toolkit/store'
import {
  AddWhitelistItemRequest,
  AddWhitelistItemResponse,
  DeleteWhitelistItemRequest,
  DeleteWhitelistItemResponse,
  EditWhitelistItemRequest,
  EditWhitelistItemResponse,
  GetCustomNotificationEmailTemplateRequest,
  GetCustomNotificationEmailTemplateResponse,
  GetNotificationEmailRequest,
  GetNotificationEmailResponse,
  GetWhitelistItemListRequest,
  GetWhitelistItemListResponse,
  OptionalGetWhitelistItemListRequest,
  SaveCustomNotificationTemplateRequest,
  SaveCustomNotificationTemplateResponse,
  SetNotificationEmailRequest,
  SetNotificationEmailResponse,
  UpdateAccessTokenRequest,
  UpdateAccessTokenResponse,
  WhitelistItem
} from './types'

/**
 * API call to settingsController::setAccessTokenSettings
 */
export const updateAccessToken = createAsyncThunk<UpdateAccessTokenResponse, UpdateAccessTokenRequest, {}>(
  'SETTINGS/updateAccessTokens',
  async function doUpdateAccessToken(payload: UpdateAccessTokenRequest, { dispatch, rejectWithValue }) {
    try {
      const resp = await restClient(apiRoutes.UPDATE_ACCESS_TOKEN, {
        data: payload
      })
      dispatch(updateCurrentSettings(resp.data.settings))
      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

/**
 * API call to settingsController::getWhitelistItemList
 */
export const getWhitelistItemList = createAsyncThunk<
  GetWhitelistItemListResponse,
  OptionalGetWhitelistItemListRequest,
  {}
>('SETTINGS/getWhitelistItemList', async function doGetWhitelistItemList(
  payload: GetWhitelistItemListRequest = { query: {} },
  { getState, rejectWithValue }
) {
  try {
    const query = buildReportQueryFor((getState() as RootState).dataTables.allowedSenders)
    const {
      data: { whitelistItems, ...reportData }
    } = await restClient(apiRoutes.GET_WHITELIST_ITEMS, {
      data: {
        query: {
          ...query,
          ...(payload.query || {}),
          offset: 0
        }
      }
    })
    return {
      report: {
        ...reportData,
        data: whitelistItems?.sort((a: WhitelistItem, b: WhitelistItem) =>
          a.pattern.toLowerCase() > b.pattern.toLowerCase() ? 1 : -1
        )
      }
    }
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

/**
 * API call to settingsController::addWhitelistItem
 */
export const addWhitelistItem = createAsyncThunk<AddWhitelistItemResponse, AddWhitelistItemRequest, {}>(
  'SETTINGS/addWhitelistItem',
  async function doAddWhitelistItem(payload: AddWhitelistItemRequest, { rejectWithValue }) {
    try {
      const resp = await restClient(apiRoutes.ADD_WHITELIST_ITEM, {
        data: payload
      })
      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

/**
 * API call to settingsController::editWhitelistItem
 */
export const editWhitelistItem = createAsyncThunk<EditWhitelistItemResponse, EditWhitelistItemRequest, {}>(
  'SETTINGS/editWhitelistItem',
  async function doEditWhitelistItem(payload: EditWhitelistItemRequest, { rejectWithValue }) {
    try {
      const resp = await restClient(apiRoutes.EDIT_WHITELIST_ITEM, {
        data: payload
      })
      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

/**
 * API call to settingsController::deleteWhitelistItem
 */
export const deleteWhitelistItem = createAsyncThunk<DeleteWhitelistItemResponse, DeleteWhitelistItemRequest, {}>(
  'SETTINGS/deleteWhitelistItem',
  async function doDeleteWhitelistItem(payload: DeleteWhitelistItemRequest, { rejectWithValue }) {
    try {
      const resp = await restClient(apiRoutes.DELETE_WHITELIST_ITEM, {
        data: payload
      })
      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

/**
 * API call to settingsController::getNotificationEmail
 */
export const getNotificationEmail = createAsyncThunk<GetNotificationEmailResponse, GetNotificationEmailRequest, {}>(
  'SETTINGS/getNotificationEmail',
  async function doGetNotificationEmail(_payload, { rejectWithValue }) {
    try {
      const resp = await restClient(apiRoutes.GET_NOTIFICATION_EMAIL, {
        data: {}
      })
      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

/**
 * API call to settingsController::setNotificationEmail
 */
export const setNotificationEmail = createAsyncThunk<SetNotificationEmailResponse, SetNotificationEmailRequest, {}>(
  'SETTINGS/setNotificationEmail',
  async function doSetNotificationEmail(payload: SetNotificationEmailRequest, { rejectWithValue }) {
    try {
      const resp = await restClient(apiRoutes.SET_NOTIFICATION_EMAIL, {
        data: payload
      })
      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

/**
 * 3 concurrent API calls to
 * settingsController::getCustomTemplate
 * settingsController::getNotificationPreviewEmailSubject
 * settingsController::getDefaultTemplate
 */
export const getCustomNotificationEmailTemplate = createAsyncThunk<
  GetCustomNotificationEmailTemplateResponse,
  GetCustomNotificationEmailTemplateRequest,
  {}
>('SETTINGS/getCustomNotificationEmailTemplate', async function doGetCustomNotificationEmailTemplate(
  payload: GetCustomNotificationEmailTemplateRequest,
  { rejectWithValue }
) {
  try {
    const [template, preview, defaults] = await Promise.all([
      restClient(apiRoutes.GET_CUSTOM_TEMPLATE, { data: payload }),
      restClient(apiRoutes.GET_NOTIFICATION_PREVIEW_EMAIL_SUBJECT, { data: payload }),
      restClient(apiRoutes.GET_NOTIFICATION_EMAIL_DEFAULTS, { data: payload })
    ])
    return [template.data, preview.data, defaults.data] as GetCustomNotificationEmailTemplateResponse
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

/**
 * API call to settingsController::saveCustomTemplate
 */
export const saveCustomNotificationTemplate = createAsyncThunk<
  SaveCustomNotificationTemplateResponse,
  SaveCustomNotificationTemplateRequest,
  {}
>('SETTINGS/saveCustomNotificationTemplate', async function doSaveCustomNotificationTemplate(
  payload: SaveCustomNotificationTemplateRequest,
  { rejectWithValue }
) {
  try {
    const resp = await restClient(apiRoutes.SAVE_CUSTOM_TEMPLATE, {
      data: payload
    })
    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})
