import { reduxStore } from 'global/lib/reduxStore'

import { analyticsMixpanelTrackEvent } from 'global/redux/features/analytics/analyticsSlice'

import { User } from 'global/types/api/userType'

import { config } from 'global/lib/config'
import userDataLib from 'global/lib/userData/userData'
import productLib from 'global/lib/product/product'
import * as configurationLib from 'global/lib/configuration/configuration'
import * as mixpanelService from 'global/lib/analytics/mixpanelService'
import * as intercomService from 'global/lib/analytics/intercomService'
import EVENTS from 'global/lib/analytics/mixpanel_events'

export interface Flags {
  [key: string]: string
}

export const QUERY_PARAMS = {
  PDF: 'fromPdfReport'
}

export { EVENTS }

const STRIP_CHARS = /\D/g

export function setUser(user: User, signUp?: any, mixpanelDistinctId?: string) {
  intercomService.setUser(user, signUp)
  mixpanelService.setUser(user, signUp, mixpanelDistinctId)
}

export function setUserFlags(user: User, flags: Flags) {
  intercomService.setUserFlags(user, flags)
  mixpanelService.setUserFlags(user, flags)
}

// grab the SN from FIR or SEN products
// strip the characters from the FIR SN
export function getSerialNumber(accessTokenId: string) {
  switch (true) {
    case config.domainConfig.isForensics: {
      const firSerialNumber = productLib.getForensicsSerialNumberForAccessToken(accessTokenId)
      return firSerialNumber ? firSerialNumber.replace(STRIP_CHARS, '') : firSerialNumber
    }
    case config.domainConfig.isSentinel:
      return productLib.getSentinelSerialNumberForAccessToken(accessTokenId)
    default:
      return undefined
  }
}

export function trackEvent(eventName: string, metadata?: {}, signupUser?: User) {
  const updatedMetadata = updateMetadata(metadata)
  const user = signupUser || userDataLib.getUser()
  const { justLoggedOut } = reduxStore.getState().app

  intercomService.trackEvent(eventName, updatedMetadata)

  // send user object to mixpanel
  if (user?.id && !justLoggedOut) {
    // send mixpanel event to common service
    return reduxStore.dispatch(analyticsMixpanelTrackEvent({ user, eventName, metadata: updatedMetadata }))
  }
  // fall back to mixpaneljs tracking if user does not exist

  if (config.isDev) {
    // eslint-disable-next-line no-console
    console.log('Mixpanel is not defined, blocked event:', { eventName, metadata: updatedMetadata })
  }

  return mixpanelService.trackEvent(eventName, updatedMetadata)
}

export function updateMetadata(metadata?: {}) {
  // add productVersion to all metadata to make it
  // easy to differentiate between CASB and the React Port in Mixpanel
  const productVersion = 'v2'
  const productAbbr = configurationLib.getProductAbbr()

  // add etsVersion to metadata so it gets sent in all events in the signup flow
  return {
    ...metadata,
    productAbbr,
    productVersion,
    ...(productAbbr === config.PRODUCT_ABBR.ETS && { etsVersion: 'v2a' })
  }
}

export function appEventName(eventName: string) {
  return `${configurationLib.getProductAbbr()} ${eventName}`
}

export function trackAppEvent(eventName: string, metadata?: {}, signupUser?: User) {
  trackEvent(appEventName(eventName), metadata, signupUser)
}

export function trackEventDelayed(eventName: string, metadata?: {}) {
  window.setTimeout(() => {
    trackEvent(eventName, metadata)
  }, 3000)
}

export function openNewIntercomMessage() {
  intercomService.openNewMessage()
}
