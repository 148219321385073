import { SortDescriptor } from '@progress/kendo-data-query'
import { createSlice } from '@reduxjs/toolkit'

import { ColumnsConfig, GridColumns } from 'fir/redux/types/DataTables'
import { config } from 'global/lib/config'

interface UserReportedState {
  GRID_COLUMNS: GridColumns
  columnsConfig: ColumnsConfig
  group: any[]
  collapsedGroups: any[]
  expandedRows: any[]
  page: { skip: number; take: number }
  sort: SortDescriptor[]
}

export const DISMISSED_STATE = 'DISMISSED'
export const SUBMITTED_STATE = 'SUBMITTED'

export const GRID_COLUMNS = {
  LAST_REPORTED_DATE: 'lastReported',
  NUMBER_OF_USERS_REPORTED: 'numberOfUsersReported',
  SENDER_EMAIL: 'senderEmail',
  SUBJECT: 'subject',
  AFFECTED_MAILBOXES: 'maxMatchedCount',
  ACTIONS: 'actions'
}

export const COLUMNS_CONFIG = {
  [GRID_COLUMNS.LAST_REPORTED_DATE]: {
    orderIndex: 0,
    show: true
  },
  [GRID_COLUMNS.NUMBER_OF_USERS_REPORTED]: {
    orderIndex: 1,
    show: true
  },
  [GRID_COLUMNS.SENDER_EMAIL]: {
    orderIndex: 2,
    show: true
  },
  [GRID_COLUMNS.SUBJECT]: {
    show: true,
    orderIndex: 3
  },
  [GRID_COLUMNS.AFFECTED_MAILBOXES]: {
    orderIndex: 4,
    show: true
  },
  [GRID_COLUMNS.ACTIONS]: {
    orderIndex: 5,
    show: true
  }
}

// Store
export const INITIAL_STATE: UserReportedState = {
  GRID_COLUMNS,
  columnsConfig: COLUMNS_CONFIG,
  group: [],
  collapsedGroups: [],
  expandedRows: [],
  page: { skip: 0, take: config.DATA_TABLE.FORENSICS.ITEMS_PER_PAGE },
  sort: [{ field: GRID_COLUMNS.LAST_REPORTED_DATE, dir: 'desc' }]
}

export const userReportedSlice = createSlice({
  name: 'DATATABLE/USER_REPORTED',
  initialState: INITIAL_STATE,
  reducers: {
    update: (state, action) => {
      return {
        ...state,
        ...action.payload?.config
      }
    },
    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  }
})

export const { update, reset } = userReportedSlice.actions

export default userReportedSlice.reducer
