import React, { useMemo } from 'react'

import { Button, MenuItem, Popover } from '@barracuda-internal/bds-core'
import { DropDownArrow as ArrowDropDownIcon } from '@barracuda-internal/bds-core/dist/Icons/Controls'

import { Products } from 'global/types/productsType'
import { AccessToken } from 'global/types/api/accessTokenType'

import styles from 'global/components/lib/layout/navbar/navbarStyles'
import useCommonAnchorElLogic from 'global/components/lib/anchorElLogic/useAnchorElLogic'
import ProductTypeLabel from 'global/components/lib/productTypeLabel/ProductTypeLabel'

export interface MenuItemProps {
  id: string
  name: string
  displayName: string
  products: Products[]
  bundleVersion?: string
}

export interface NavbarAccountSwitcherProps {
  userDisplayName?: string
  menuListItems: MenuItemProps[]
  onAccountSwitcherButton: (id: string) => void
  currentAccessToken: AccessToken | null
}

const NavbarAccountSwitcher: React.FC<NavbarAccountSwitcherProps> = ({
  userDisplayName,
  menuListItems,
  onAccountSwitcherButton,
  currentAccessToken
}) => {
  const ITEM_HEIGHT = 36

  const classes = styles()
  const [anchorEl, handleClick, handleClose] = useCommonAnchorElLogic()

  const menuItemList = useMemo(
    () => (
      <div>
        {menuListItems.map((menuItem: MenuItemProps) => (
          <MenuItem
            onClick={() => {
              handleClose()
              onAccountSwitcherButton(menuItem.id)
            }}
            id={`fir-${menuItem.id}`}
            key={`fir-${menuItem.id}`}
            selected={menuItem.id === (currentAccessToken || {}).id}
            className={classes.accountMenuItem}
            classes={{ selected: classes.currentAccountMenuItem }}
          >
            <span className={`${classes.flexGrow} ${classes.menuItemText}`}>{menuItem.displayName}</span>
            <ProductTypeLabel
              accessTokenId={menuItem.id}
              products={menuItem.products}
              bundleVersion={menuItem.bundleVersion}
            />
          </MenuItem>
        ))}
      </div>
    ),
    [classes, currentAccessToken, handleClose, menuListItems, onAccountSwitcherButton]
  )

  return useMemo(() => {
    if (!menuListItems.length) {
      return null
    }

    return (
      <div>
        {menuListItems.length !== 1 && (
          <Button
            className={classes.accountSwitcherButton}
            variant="outlined"
            endIcon={<ArrowDropDownIcon />}
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            {currentAccessToken && <span>{userDisplayName}</span>}
          </Button>
        )}
        {anchorEl && (
          <Popover
            id="fir-account-list"
            className={classes.accSwitcherPopover}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 7
              }
            }}
          >
            {menuItemList}
          </Popover>
        )}
        {menuListItems.length === 1 && (
          <span>{menuListItems[0].name === 'Default' ? 'Unknown Account' : menuListItems[0].name}</span>
        )}
      </div>
    )
  }, [menuItemList, menuListItems, classes, anchorEl, currentAccessToken, handleClick, handleClose, userDisplayName])
}

export default NavbarAccountSwitcher
