import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(5)
  },
  card: {
    align: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '55%'
  },
  header: {
    fontsize: theme.font.size.xlarge,
    fontWeight: theme.font.weight.regular
  },
  image: {
    width: '200px',
    paddingLeft: theme.spacing(1.875),
    paddingTop: theme.spacing(1.25)
  },
  missingRequirementBox: {
    border: '2px solid',
    borderColor: theme.colors.barracudaBlue,
    marginBottom: theme.spacing(4),
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(3.125),
    width: '95%'
  },
  missingRequirementText: {
    background: 'white',
    color: theme.colors.barracudaBlue,
    marginLeft: theme.spacing(1.25),
    marginTop: -theme.spacing(1.25),
    paddingRight: theme.spacing(0.625),
    width: theme.spacing(10)
  }
}))
