import React, { Children, cloneElement, useMemo } from 'react'

import { AccountSwitcher, Box, Masthead } from '@barracuda-internal/bds-core'
// TODO: Import Account type directly when BDS exports it
import { Account as BDSAccountSwitcherAccount } from '@barracuda-internal/bds-core/dist/UnifiedComponents/AccountSwitcher/AccountSwitcher'

import ConnectO365Dialog from 'global/components/lib/dialogs/connectO365/ConnectO365Dialog'
import uselayoutLogic from 'global/components/lib/layout/useLayoutLogic'
import { navbarHeight } from 'global/components/lib/layout/navbar/navbarStyles'
import LicenseBanner from 'global/components/lib/layout/licenseBanner/LicenseBanner'
import MaintenanceBanner from 'global/components/lib/layout/maintenanceBanner/MaintenanceBanner'
import NavbarImpersonationBanner from 'global/components/lib/layout/navbar/impersonationBanner/NavbarImpersonationBanner'
import PermissionBanner from 'global/components/lib/layout/permissionBanner/PermissionBanner'
import FeatureBanner from 'global/components/lib/layout/featureBanner/FeatureBanner'
import styles from 'global/components/lib/layout/layoutStyles'
import { config } from 'global/lib/config'

import 'global/types/mui/index'

import { NavbarContextMenuO365Props } from './navbar'

export type GlobalLayoutProps = {
  children: any
  isLicenseBannerEnabled?: boolean
  isMaintenanceBannerEnabled?: boolean
  isNewLayout?: boolean
  isPermissionBannerEnabled?: boolean
  isUnifiedReportingBannerEnabled?: boolean
  initAccount?: string
  navbar?: JSX.Element
  navbarContextMenuO365Props?: NavbarContextMenuO365Props
  onSelectAccountCallback?: (selectedAccount: BDSAccountSwitcherAccount) => void
  pageTitle?: string
  pageSubtitle?: string
  rightContent?: JSX.Element
}

// TODO: Import theme directly when BDS dependency is updated with theme fix
function Layout({
  children,
  isLicenseBannerEnabled = true,
  isMaintenanceBannerEnabled = false,
  isNewLayout = false,
  isPermissionBannerEnabled = false,
  isUnifiedReportingBannerEnabled = false,
  navbar,
  navbarContextMenuO365Props = {},
  initAccount,
  onSelectAccountCallback,
  pageTitle = '',
  pageSubtitle,
  rightContent,
  ...rest
}: GlobalLayoutProps): JSX.Element {
  const classes = styles()
  const [layoutConfig] = uselayoutLogic()
  const {
    accessTokenId,
    accounts,
    button1Text,
    button2Text,
    button3Text,
    isAdmin,
    isDialogOpened,
    isLoggedOut,
    isNavBarVisible,
    navbarImpersonationBannerProps,
    productTitle,
    sendNavO365TrackingEvent,
    toggleDialog
  } = layoutConfig

  const showNavBar = !!(isNavBarVisible && !isLoggedOut)

  // TODO: Remove this and make onSelectAccountCallback required when all apps use the new layout
  const noop = () => null // Define a noop function that does nothing

  return useMemo(() => {
    if (isNewLayout) {
      return (
        <Box className={classes.app}>
          <Box>{showNavBar && navbar}</Box>
          <Box className={`${classes.main} ${!showNavBar && classes.mainFullWidth}`}>
            <Box className={classes.content}>
              {!isLoggedOut && <NavbarImpersonationBanner {...navbarImpersonationBannerProps} />}
              {!isLoggedOut && isLicenseBannerEnabled && <LicenseBanner />}
              {!isLoggedOut && isMaintenanceBannerEnabled && <MaintenanceBanner />}
              {!isLoggedOut && isPermissionBannerEnabled && <PermissionBanner />}
              {!isLoggedOut && isUnifiedReportingBannerEnabled && <FeatureBanner />}
              <Masthead
                bannerContent={
                  <AccountSwitcher
                    accounts={accounts}
                    initAccount={initAccount}
                    onSelectAccountCallback={onSelectAccountCallback || noop}
                    button1Text={button1Text}
                    button2Text={isAdmin ? button2Text : undefined}
                    button3Text={button3Text}
                    onButton2Click={() => {
                      toggleDialog()
                      sendNavO365TrackingEvent()
                    }}
                    onButton3Click={(): void => {
                      window.open(config.bccAccountUserUrl, '_blank')
                    }}
                  />
                }
                rightContent={rightContent}
                productTitle={productTitle}
                pageTitle={pageTitle}
                pageSubtitle={pageSubtitle}
              />
              <ConnectO365Dialog
                toggleDialog={toggleDialog}
                isDialogOpened={isDialogOpened}
                accessTokenId={accessTokenId}
                successCb={navbarContextMenuO365Props.successCb}
                showScanTypeSelection={navbarContextMenuO365Props.showScanTypeSelection}
                accounts={navbarContextMenuO365Props.accounts}
              />
              {children}
            </Box>
          </Box>
        </Box>
      )
    }

    return (
      <div className="App">
        {!isLoggedOut && navbar}
        <div className="content" style={{ paddingTop: navbarHeight }}>
          {!isLoggedOut && <NavbarImpersonationBanner {...navbarImpersonationBannerProps} />}
          {!isLoggedOut && isLicenseBannerEnabled && <LicenseBanner />}
          {!isLoggedOut && isMaintenanceBannerEnabled && <MaintenanceBanner />}
          {!isLoggedOut && isPermissionBannerEnabled && <PermissionBanner />}
          {!isLoggedOut && isUnifiedReportingBannerEnabled && <FeatureBanner />}
          {Children.map(children, child => cloneElement(child as React.ReactElement<any>, { ...rest }))}
        </div>
      </div>
    )
  }, [
    accessTokenId,
    accounts,
    button1Text,
    button2Text,
    button3Text,
    children,
    classes.app,
    classes.content,
    classes.main,
    classes.mainFullWidth,
    initAccount,
    isAdmin,
    isDialogOpened,
    isLicenseBannerEnabled,
    isLoggedOut,
    isMaintenanceBannerEnabled,
    isNewLayout,
    isPermissionBannerEnabled,
    isUnifiedReportingBannerEnabled,
    navbar,
    navbarContextMenuO365Props.accounts,
    navbarContextMenuO365Props.showScanTypeSelection,
    navbarContextMenuO365Props.successCb,
    navbarImpersonationBannerProps,
    onSelectAccountCallback,
    pageSubtitle,
    pageTitle,
    productTitle,
    rest,
    rightContent,
    sendNavO365TrackingEvent,
    showNavBar,
    toggleDialog
  ])
}

export default Layout
