import { useCallback, useMemo } from 'react'
// TODO: Import Account type directly when BDS exports it
import { Account as BDSAccountSwitcherAccount } from '@barracuda-internal/bds-core/dist/UnifiedComponents/AccountSwitcher/AccountSwitcher'
import { useAppDispatch, useAppSelector } from 'global/redux/toolkit/hooks'

import useUserDataLib from 'global/lib/userData/useUserData'
import { Account } from 'global/types/api/accountType'
import { AccessToken } from 'global/types/api/accessTokenType'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { useFormatMessage } from 'global/lib/localization'
import { config } from 'global/lib/config'
import useDialogLogic from 'global/lib/dialogs/useDialogLogic'
import { Products } from 'global/types/productsType'
import { revertImpersonation } from 'global/redux/features/admin/adminSlice'

import { NavbarImpersonationBannerProps } from './navbar'

export interface LayoutLogic {
  accessTokenId?: string
  accounts: BDSAccountSwitcherAccount[]
  button1Text: string
  button2Text: string
  button3Text: string
  isAdmin: boolean
  isDialogOpened: boolean
  isLoggedOut: boolean
  isNavBarVisible?: boolean
  navbarImpersonationBannerProps: NavbarImpersonationBannerProps
  productTitle: string
  sendNavO365TrackingEvent: () => void
  toggleDialog: () => void
}

const BASE_I18N_KEY = 'app.layout'

export default function useLayoutLogic(): [LayoutLogic] {
  const dispatch = useAppDispatch()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [userDataLib] = useUserDataLib()

  const { accessTokenId, isLoggedOut, isNavBarVisible } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id || '',
    isLoggedOut: _stores.app.justLoggedOut,
    isNavBarVisible: _stores.app.activePath.isNavbarVisible
  }))

  const [isDialogOpened, toggleDialog] = useDialogLogic()

  const accounts = userDataLib.getAccounts()
  const isAdmin = useMemo(() => userDataLib.isUserAdmin(accessTokenId), [accessTokenId, userDataLib])

  // Filter out ETS accounts (TODO: support IP account filter rules)
  const formattedAccounts: BDSAccountSwitcherAccount[] = accounts.map((account: Account) => ({
    id: account.bccId,
    name: account.accountName,
    nestedAccounts: account.accessTokens?.reduce(
      (acc: BDSAccountSwitcherAccount['nestedAccounts'], accessToken: AccessToken) => {
        if (accessToken.products.some((product: Products): boolean => product === config.PRODUCTS.ETS)) {
          return acc
        }
        return acc?.concat({
          id: accessToken.id,
          name: accessToken.name
        })
      },
      []
    )
  }))

  const navbarImpersonationBannerProps: NavbarImpersonationBannerProps = useMemo(() => {
    return {
      shouldShowBanner: userDataLib.isImpersonationMode(),
      alertContent: formatMessage('impersonation_banner', { user: userDataLib.getUser().email }),
      closeAction: () => {
        const { v2Impersonate } = userDataLib.getUser()
        analyticsLib.trackAppEvent(analyticsLib.EVENTS.NAVBAR_REVERT_IMPERSONATION)
        dispatch(revertImpersonation({ v2Impersonate }))
      },
      closeText: formatMessage('impersonation_banner_button'),
      pageAlert: false,
      showClose: true
    }
  }, [dispatch, formatMessage, userDataLib])

  const sendNavO365TrackingEvent = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.NAVBAR_CLOUD_CONNECT)
  }, [])

  return useMemo(
    () => [
      {
        accessTokenId,
        accounts: formattedAccounts,
        button1Text: formatMessage('button1_text'),
        button2Text: formatMessage('button2_text'),
        button3Text: formatMessage('button3_text'),
        isAdmin,
        isLoggedOut,
        isNavBarVisible,
        navbarImpersonationBannerProps,
        productTitle: formatMessage(config.NAME),
        isDialogOpened,
        sendNavO365TrackingEvent,
        toggleDialog
      }
    ],
    [
      accessTokenId,
      formattedAccounts,
      formatMessage,
      isAdmin,
      isLoggedOut,
      isNavBarVisible,
      navbarImpersonationBannerProps,
      isDialogOpened,
      sendNavO365TrackingEvent,
      toggleDialog
    ]
  )
}
