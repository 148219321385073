import React, { useMemo } from 'react'

import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import { Typography, Radio, RadioGroup, TextField, Grid } from '@barracuda-internal/bds-core'

import { useFormatMessage } from 'global/lib/localization'
import { BaseRadioButtonsConfig } from 'global/lib/useRadioButtons/useRadioButtons'

import styles from 'sen/components/lib/dialogs/settings/shared/adminModeStyles'

export type OnSetAdminEmail = (e: any) => void

export interface AdminModeProps {
  withTitle?: boolean
  withLabel?: boolean
  isTabInProgress: boolean
  BASE_I18N_KEY: string
  ADMIN_VALUES: number[]
  isDisabled: boolean
  radioButtonsConfig: BaseRadioButtonsConfig
  adminEmail: string
  setAdminEmail: OnSetAdminEmail
  isValidAdminEmail: boolean
}

export const AdminMode: React.FC<AdminModeProps> = ({
  withTitle = false,
  withLabel = false,
  isTabInProgress,
  BASE_I18N_KEY,
  ADMIN_VALUES,
  isDisabled,
  radioButtonsConfig,
  adminEmail,
  setAdminEmail,
  isValidAdminEmail
}) => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const isEmailFieldDisabled = isDisabled || Number(radioButtonsConfig.selectedRadioButton) !== 1

  return useMemo(
    () => (
      <>
        <FormControl>
          {withTitle && (
            <FormLabel>
              <Typography>{formatMessage('title')}</Typography>
            </FormLabel>
          )}
          <RadioGroup
            className={classes.radioButtonGroup}
            value={radioButtonsConfig.selectedRadioButton}
            onChange={radioButtonsConfig.onSelectRadioButton}
          >
            {ADMIN_VALUES.map((key, index) => (
              <Grid container key={String(key)}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label={formatMessage(`section_admin_mode.select_${index + 1}`)}
                    disabled={isDisabled}
                    value={String(key)}
                  />
                </Grid>
                {key === 1 && (
                  <Grid container direction="column" className={classes.adminContainer}>
                    <Grid container direction="row">
                      <Grid item xs={4}>
                        <TextField
                          id="admin-email"
                          label={withLabel ? formatMessage('security_team_email') : ''}
                          disabled={isEmailFieldDisabled}
                          error={!isValidAdminEmail}
                          value={adminEmail}
                          helperText={
                            !isEmailFieldDisabled &&
                            !isTabInProgress &&
                            !isValidAdminEmail &&
                            formatMessage('email_error')
                          }
                          onChange={setAdminEmail}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={2} />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            ))}
          </RadioGroup>
        </FormControl>
      </>
    ),
    [
      withTitle,
      withLabel,
      classes,
      formatMessage,
      isEmailFieldDisabled,
      isTabInProgress,
      ADMIN_VALUES,
      isDisabled,
      radioButtonsConfig,
      adminEmail,
      setAdminEmail,
      isValidAdminEmail
    ]
  )
}

export default AdminMode
