import globalMessages, {
  ADD_PERMISSION,
  APPLY,
  AUTH_CODE_ERROR,
  BACK,
  BARRACUDA_EMAIL_PROTECTION,
  CANCEL,
  CLOSE,
  CREATE,
  DEFAULT_ERROR,
  DEMO_ERROR,
  EMAIL,
  FIR_APP_NAME,
  HEADERS,
  MICROSOFT_365,
  NEXT,
  SAVE,
  SENDER,
  SIGN_IN,
  START_TRIAL,
  SUBJECT,
  SUBMIT,
  LINKING_CODE_INVALID,
  SERIAL_NUMBER_INVALID,
  NO_PERMISSIONS
} from 'global/lib/i18n/en'

/* eslint-disable @typescript-eslint/naming-convention, quotes */
const ACTIONS = 'Actions'
const ACTIONS_TAKEN = 'Actions taken'
const ATTACHMENT = 'Attachment'
const AUTOMATED_WORKFLOWS = 'Automated workflows'
const BLOCK = 'Block'
const CONDITIONS = 'Conditions'
const COUNTRY = 'Country'
const CREATE_INCIDENT = 'Create incident'
const CREATE_INCIDENT_TOOLTIP = `<b>Create incident</b> is available with the ${BARRACUDA_EMAIL_PROTECTION} <b>Premium</b> and <b>Premium Plus</b> plans.`
const CREATED_ON = 'Created on'
const CREATE_WORKFLOW = 'Create workflow'
const DELETE_EMAILS = "Delete emails from users' mailboxes"
const DISMISS = 'Dismiss'
const DUPLICATE_INCIDENT_IN_PROGRESS = 'Incident with matching search criteria is in progress'
const EMAIL_NOTIFICATION = 'Email notification'
const EMAIL_SUBJECT = 'Email subject'
const EMAILS_BY_REGION = 'Emails by region'
const EXPORT_CSV = 'Export to CSV'
const EXTERNAL_EMAIL = 'External email'
const FIR = 'Incident Response'
const GENERIC_ERROR = 'Something went wrong'
const IGNORE_SAT_EMAILS = 'Ignore Security Awareness Training campaign emails'
const INCIDENT = 'Incident'
const INCIDENTS = 'Incidents'
const INSIGHTS = 'Insights'
const INTERNAL_EMAIL = 'Internal email'
const INVALID_EMAIL = 'Enter a valid email address.'
const LEARN_MORE = 'Learn more'
const MESSAGE_BODY_LINKS = 'Message body URL'
const MESSAGE_BODY_TEXT = 'Message body text'
const MS_TEAMS_NOTIFICATION = 'Microsoft Teams notification'
const MS_TEAMS_WEBHOOK = 'MS Teams webhook'
const NO = 'No'
const NO_RECORDS_AVAILABLE = 'No records available'
const NOTIFIED = 'Notified'
const NUMBER_EMAILS_DETECTED = 'Number of emails detected'
const NUMBER_MAILBOXES_AFFECTED = 'Number of mailboxes affected'
const PAGE_ERROR = 'Error loading page'
const PERMISSION_DIALOG_TITLE = `Why are so many permissions needed to run ${FIR_APP_NAME}?`
const POTENTIAL_INCIDENTS = 'Potential incidents'
const QUARANTINE = 'Quarantine'
const RECEIVED_DATE = 'Received date'
const REVIEW_MESSAGES = 'Review messages'
const STATUS = 'Status'
const SECURITY_TEAM_EMAIL = 'Security team email'
const SENDER_COUNTRY = 'Sender country'
const SENDER_EMAIL = 'Sender email'
const SENDER_NAME = 'Sender name'
const SETTINGS = 'Settings'
const SLACK_NOTIFICATION = 'Slack notification'
const SLACK_WEBHOOK = 'Slack webhook'
const START_CR_ERROR = 'Unable to start continuous remediation'
const STATS_NOT_AVAILABLE = 'No records available please check back in 24 hours'
const TAG_INVALID = 'Tags must be alphanumeric and less than 100 characters'
const TAGS = 'Tags'
const TRIGGERS = 'Triggers'
const TRY_FREE_NOW = 'Try free now'
const TYPE = 'Type'
const UNIFIED_REPORTING = 'Reports'
const UNKNOWN = 'Unknown'
const UPDATE_WORKFLOW = 'Update workflow'
const USE_AUTOMATIC_REMEDIATION_EMAIL = 'Use email address provided in the automatic remediation settings'
const USE_MANUAL_REMEDIATION_EMAIL = 'Use email address provided in the manual remediation settings'
const USER_REPORTED_EMAILS = 'User-reported emails'
const USERS_INVOLVED = 'Users involved'
const VIEW_INCIDENT = 'View incident'
const VIEW_EMAIL = 'View email'
const WORKFLOW_NAME = 'Workflow name'
const WORKFLOW_REVISION = 'Workflow revision'
const YES = 'Yes'

export default {
  ...globalMessages,
  fir: {
    automated_workflows: {
      automated_workflow_table: {
        actions_taken: ACTIONS_TAKEN,
        conditions_checked: 'Conditions checked',
        created_on: 'Last edited',
        edited_by: 'Edited by',
        times_triggered: 'Times triggered',
        workflow_name: WORKFLOW_NAME,
        workflow_revision: WORKFLOW_REVISION
      },
      buttons: {
        create_workflow: CREATE_WORKFLOW,
        tooltips: {
          disable_workflow: 'Disable workflow',
          enable_workflow: 'Enable workflow',
          edit_workflow: 'Edit workflow',
          view_workflow: 'View workflow'
        },
        view_disabled: 'Show disabled',
        view_enabled: 'Show enabled'
      },
      charts: {
        top_workflow_actions: {
          actions: {
            createIncident: CREATE_INCIDENT,
            emailNotification: EMAIL_NOTIFICATION,
            slackNotification: SLACK_NOTIFICATION,
            msteamsNotification: MS_TEAMS_NOTIFICATION
          },
          noData: NO_RECORDS_AVAILABLE,
          title: 'Top actions taken',
          tooltip: ACTIONS_TAKEN
        },
        top_workflow_runs: {
          noData: NO_RECORDS_AVAILABLE,
          title: 'Top 5 workflows',
          tooltip_triggers: TRIGGERS,
          tooltip_conditions: CONDITIONS,
          tooltip_actions: ACTIONS
        },
        workflow_runs: {
          highlight: 'in the last 6 months',
          noData: NO_RECORDS_AVAILABLE,
          title: 'Workflows triggered',
          tooltip: 'Total workflows triggered'
        }
      },
      workflow_details: {
        automated_workflows: AUTOMATED_WORKFLOWS,
        buttons: {
          edit_workflow: 'Edit workflow'
        },
        labels: {
          description: 'Description',
          editor: 'Edited by',
          page_title: 'View workflow',
          workflow_revision: WORKFLOW_REVISION,
          workflow_run_table_title: 'Automated workflow runs',
          workflow_run_table_subtitle: 'List of runs generated by this workflow'
        },
        automated_workflow_runs_table: {
          actions_taken: ACTIONS_TAKEN,
          created_on: CREATED_ON,
          workflow_name: WORKFLOW_NAME
        },
        run_details_table: {
          created_incident_link: 'Incident created',
          DuplicateIncidentInProgress: DUPLICATE_INCIDENT_IN_PROGRESS,
          event_details: 'Event details',
          event_result: 'Event result',
          event_time: 'Event time',
          event_type: 'Event type',
          no_matching_messages: 'No matching messages found'
        }
      },
      webhook_dialog: {
        buttons: {
          save: SAVE,
          cancel: CANCEL
        },
        labels: {
          input_label: '{webhookSource} webhook',
          help_link_label: 'Learn more about {webhookSource} webhook',
          msteams: 'MS Teams',
          msteams_webhook: MS_TEAMS_WEBHOOK,
          slack: 'Slack',
          slack_webhook: SLACK_WEBHOOK,
          webhook_dialog_description:
            'For automated workflows with {webhookSource} notification action, specify {webhookSource} webhook',
          webhook_dialog_helper_text:
            'Default {webhookSource} webhook can be changed at any time at Settings > Automated workflow tab',
          webhook_dialog_title: 'Save a default {webhookSource} webhook'
        }
      },
      workflow_dialog: {
        buttons: {
          cancel: CANCEL,
          create_workflow: CREATE_WORKFLOW,
          new_action: 'New action',
          new_condition: 'New condition',
          update_workflow: UPDATE_WORKFLOW
        },
        alerts: {
          webhook_save_success: 'Successfully saved webhook'
        },
        errors: {
          condition_too_short: 'Enter at least 3 characters.',
          demo_user: DEMO_ERROR,
          invalid_email: INVALID_EMAIL,
          invalid_number: 'Enter a number.',
          invalid_positive_integer: 'Enter a positive whole number.',
          no_condition_or_action: 'Add an action and, optionally, a condition.',
          no_trigger: 'Create a trigger to start.',
          no_workflow_name: 'Enter a workflow name.',
          save_error: 'Unable to save workflow. Contact Barracuda technical support.',
          select_country: 'Select a country',
          select_operator: 'Select an operator.'
        },
        events: {
          actions: {
            createIncident: CREATE_INCIDENT,
            emailNotification: EMAIL_NOTIFICATION,
            slackNotification: SLACK_NOTIFICATION,
            msteamsNotification: MS_TEAMS_NOTIFICATION
          },
          conditions: {
            potentialIncidentEmailsDetectedCount: NUMBER_EMAILS_DETECTED,
            potentialIncidentIsExternal_noInput: EXTERNAL_EMAIL,
            potentialIncidentIsInternal_noInput: INTERNAL_EMAIL,
            potentialIncidentIsTypePostDelivery_noInput: 'Post-delivery threat',
            potentialIncidentIsTypeRelated_noInput: 'Related threat',
            potentialIncidentMailboxesAffectedCount: NUMBER_MAILBOXES_AFFECTED,
            potentialIncidentSenderCountry: SENDER_COUNTRY,
            potentialIncidentSenderEmail: SENDER_EMAIL,
            potentialIncidentSubject: EMAIL_SUBJECT,
            senderPolicyBlock_noInput: 'Block sender policy',
            senderPolicyBy: 'Policy created by',
            senderPolicyEmailsDetectedCount: NUMBER_EMAILS_DETECTED,
            senderPolicyMailboxesAffectedCount: NUMBER_MAILBOXES_AFFECTED,
            senderPolicyQuarantine_noInput: 'Quarantine sender policy',
            senderPolicySenderCountry: SENDER_COUNTRY,
            userReportedBy: 'Reported by',
            userReportedIsExternal_noInput: EXTERNAL_EMAIL,
            userReportedIsInternal_noInput: INTERNAL_EMAIL,
            userReportedMatchedEmailCount: NUMBER_MAILBOXES_AFFECTED,
            userReportedReportedEmailCount: 'Number of users reported',
            userReportedSenderCountry: SENDER_COUNTRY,
            userReportedSenderEmail: SENDER_EMAIL,
            userReportedSubject: EMAIL_SUBJECT
          },
          operators: {
            contains: 'Contains',
            equals: 'Equals',
            greaterThan: 'Greater than',
            lessThan: 'Less than',
            notContains: 'Does not contain',
            notEquals: 'Does not equal'
          },
          triggers: {
            senderPolicy: 'Sender policy created in Email Gateway Defense',
            userReported: 'User-reported email submitted',
            potentialIncident: 'Potential incident detected'
          }
        },
        labels: {
          actions: ACTIONS,
          condition_details: 'Condition details',
          conditions: CONDITIONS,
          country: COUNTRY,
          event_types: 'Event types',
          description: 'Description (optional)',
          name: 'Workflow name',
          node_order_helper_text: 'Create a trigger to start.',
          operator: 'Operator',
          page_title: CREATE_WORKFLOW,
          page_title_update: UPDATE_WORKFLOW,
          trigger_delete_1: 'You cannot delete a trigger if a condition is present.',
          trigger_delete_2:
            'To change the trigger, remove all conditions or click <b>Cancel</b> and create a new workflow.',
          triggers: TRIGGERS,
          workflow_templates: 'Workflow templates'
        }
      },
      labels: {
        disabled_page_title: 'Disabled automated workflows',
        page_subtitle: 'Workflows to automatically create and remediate incidents',
        page_title: AUTOMATED_WORKFLOWS
      }
    },
    cta: {
      emails_by_region: {
        description:
          'Receiving emails from uncommon regions can signal malicious activity. Click a region on the map or row in the table to review the emails.',
        learn_more: LEARN_MORE
      },
      potential_incidents: {
        description: `Potential incidents are either related threats, based on an incident you already created, or post-delivery threats, based on Barracuda's threat intelligence on currently circulating threats. These threats might already be in your ${MICROSOFT_365} account.`,
        learn_more: LEARN_MORE
      },
      remediation: {
        description:
          'Investigate and remediate suspicious or malicious emails. Click <b>Create incident</b> to launch the wizard.',
        learn_more: LEARN_MORE
      },
      user_reported: {
        description: 'You can investigate all user-reported emails from this page.',
        learn_more: LEARN_MORE,
        list: {
          description: 'Users in your organization reported the following emails as suspicious through',
          ess_message_log: 'the <a>message log in Email Gateway Defense</a>',
          outlook_addin: 'the <a>Barracuda Email Protection Add-in</a>'
        }
      }
    },
    customize_email_notification: {
      buttons: {
        save: SAVE,
        reset_to_default: 'Reset to default',
        cancel: CANCEL
      },
      email_notification_preview: {
        title: 'Notification preview',
        from: 'From',
        subject: SUBJECT
      },
      errors: {
        demo_user: DEMO_ERROR,
        generic_error: GENERIC_ERROR
      },
      form_labels: {
        intro: 'Introduction',
        signature: 'Signature',
        subject: SUBJECT,
        subject_placeholder: 'Customize the subject of the notification',
        intro_placeholder: 'Customize the introduction of the notification',
        signature_placeholder: 'Customize the signature of the notification'
      },
      sub_title:
        'Enter your own customized text for any or all of the email sections. Your changes appear in the notification preview below',
      title: 'Email notification to recipient'
    },
    email_details: {
      content_type: 'Content Type',
      copied: 'Copied',
      copy_to_clipboard: 'Copy to clipboard',
      date: 'Date',
      email_error: 'Unable to load email details',
      email_from: 'Email from {sender} on {date}',
      headers: HEADERS,
      name: 'Name',
      outbound_no_header: 'Outbound messages do not contain message headers',
      outbound_no_threat_details: 'Outbound messages do not contain threat details',
      recipients: 'Recipients',
      sender: SENDER,
      size: 'Size',
      subject: SUBJECT,
      tabs: {
        attachments: 'Attachments',
        email: EMAIL,
        headers: HEADERS,
        threat_details: 'Threat details'
      },
      threats: {
        dmarc: 'DMARC compliance',
        spf: 'SPF IP check',
        dkim: 'DKIM alignment'
      },
      type: TYPE,
      value: 'Value'
    },
    follow_up: {
      labels: {
        follow_up: 'Suggested follow-up actions'
      },
      buttons: {
        close: CLOSE
      },
      suggested_follow_up_actions: 'Suggested follow-up actions',
      consider_remaining_user_endpoints:
        "Consider re-imaging a user's endpoints (desktops/laptops) if you suspect a user has:",
      opened_a_malicious_attachment: 'Opened a malicious attachment',
      clicked_on_suspiious_link: 'Clicked on a suspicious link',
      goto_barracuda_email_security_service:
        'Go to <a>Email Gateway Defense</a> and review the outbound emails being sent by the recipients in the incident to see if their account is sending unsolicited outbound email.',
      ask_your_endusers_to_watch:
        'Ask your end users to <a>watch a Security Awareness Training video</a> to train them to identify suspicious emails.'
    },
    empty_report: {
      page_title: 'Empty report'
    },
    insights: {
      tabs: {
        potential_incidents: POTENTIAL_INCIDENTS,
        emails_by_region: EMAILS_BY_REGION
      },
      potential_incidents: {
        labels: {
          page_title: POTENTIAL_INCIDENTS,
          page_subtitle: `Potential threats that might exist in your ${MICROSOFT_365} account`
        }
      },
      emails_by_region: {
        allowed_emails: 'Allowed emails',
        country: COUNTRY,
        emails_by_region: EMAILS_BY_REGION,
        insights: INSIGHTS,
        labels: {
          page_error: PAGE_ERROR,
          page_title: 'Emails received',
          page_subtitle: 'Review emails originating from locations where you do not usually conduct business'
        },
        review: 'Review'
      },
      emails_by_region_country: {
        country: COUNTRY,
        create_incident: CREATE_INCIDENT,
        emails_in: 'Emails in',
        emails_by_region: EMAILS_BY_REGION,
        mailbox: 'Affected mailbox',
        page_error: PAGE_ERROR,
        received_date: RECEIVED_DATE,
        sender: SENDER,
        subject: SUBJECT,
        unique_recipients: 'Unique recipients'
      }
    },
    unified_reporting: {
      report_header: {
        download_options: {
          data_csv: 'CSV data sheet',
          summary_pdf: 'PDF report'
        },
        report_options: {
          delete_report: 'Delete report',
          rename: 'Rename',
          save_as_copy: 'Save as copy',
          schedule: 'Schedule'
        }
      },
      singleDataChartLabel: {
        actions_taken: 'Actions taken',
        attack_remediated: 'Attack remediated',
        incidents_created: 'Total incidents created',
        threats_remediated: 'Total threats remediated',
        workflows_triggered: 'Total workflows triggered'
      },
      column_headers: {
        automated_workflows_event: {
          event_details: 'Event details',
          event_result: 'Event result',
          event_success: 'Event success',
          event_time: 'Event time',
          last_edited_by: 'Last edited by',
          last_edited_on: 'Run started on',
          node_event_type: 'Event name',
          node_type: 'Event type',
          run_any_action: 'Actions taken',
          workflow_created_on: 'Workflow created on',
          workflow_deleted: 'Workflow deleted',
          workflow_description: 'Workflow description',
          workflow_enabled: 'Workflow enabled',
          workflow_name: 'Workflow name',
          workflow_saved_version: 'Workflow version',
          workflow_uuid: 'Workflow unique ID'
        },
        insights_user_submission: {
          delivered_date: 'Received date',
          from_address: 'Sender email',
          is_phishline: 'Security awareness campaign email',
          matched_email_count: 'Total emails found',
          ms_message_id: 'Microsoft Message ID',
          reported_date: 'Reported date',
          reporter: 'Reporter email',
          sender_domain: 'Sender domain',
          status: 'Status',
          subject: 'Subject'
        },
        remediation_email: {
          attachments: 'Attachments',
          clicked_links: 'Clicked links',
          clicked_on_links: 'Clicked on links',
          date_sent: 'Received date',
          email_flow: 'Email flow',
          email_id: 'Microsoft message ID',
          from_address: 'Sender email',
          forwarded_the_email: 'Forwarded the email',
          incident_attachment_query: 'Attachment search criteria',
          incident_body_links_query: 'Body links search criteria',
          incident_body_text_query: 'Body search criteria',
          incident_created_by: 'Incident creator',
          incident_created_by_name: 'Incident remediation method',
          incident_created_on: 'Incident created on',
          incident_search_criteria: 'Incident search criteria',
          incident_sender_display_name: 'Sender name search criteria',
          incident_sender_email: 'Sender email search criteria',
          incident_subject_query: 'Subject search criteria',
          incident_type: 'Incident type',
          incident_uuid: 'Incident ID',
          internet_message_id: 'Internet message ID',
          is_continuous_remediation: 'Used continuous remediation',
          notified: 'Alert sent',
          opened_the_email: 'Read',
          recipient_email: 'Recipient email',
          recipient_name: 'Recipient name',
          remediation_method: 'Remediation method',
          replied_to_the_email: 'Replied to the email',

          sender_domain: 'Sender domain',
          status: 'Status',
          subject: 'Subject',
          total_attachments: 'Total attachments',
          total_clicked_links: 'Total clicked links'
        },
        remediation_incident: {
          add_bcs_policy: 'Add BCS policy',
          add_domain_policy: 'Add domain policy',
          add_sender_policy: 'Add sender policy',
          attachment_query: 'Attachment search criteria',
          body_links_query: 'Body links search criteria',
          body_text_query: 'Body search criteria',
          created_by: 'Incident creator',
          created_by_name: 'Incident remediation method',
          created_on: 'Incident created date',
          cr_ever_enabled: 'Used continuous remediation',
          delete: 'Delete action',
          enable_continuous_remediation: 'Continuous remediation status',
          malicious_domains: 'Malicious domains',
          malicious_domain_count: 'Total malicious domains',
          quarantine: 'Quarantine action',
          ra_add_bcs_policy: 'New policies created in Web traffic',
          ra_notify: 'Alerts sent',
          ra_send_summary: 'Sent incident summary',
          remediation_actions: 'Remediation actions',
          sender_display_name: 'Sender name search criteria',
          sender_email: 'Sender email search criteria',
          sender_policies: 'Created sender policies',
          sender_policies_count: 'Total sender policies created at gateway',
          sender_policy_type: 'Created sender policy type',
          subject_query: 'Subject search criteria',
          type: 'Type',
          uuid: 'UUID'
        }
      },
      column_values: {
        remediation_email: {
          clicked_on_links: {
            Y: YES,
            N: NO,
            U: UNKNOWN
          },
          opened_the_email: {
            Y: YES,
            N: NO,
            U: UNKNOWN
          },
          forwarded_the_email: {
            Y: YES,
            N: NO,
            U: UNKNOWN
          },
          replied_to_the_email: {
            Y: YES,
            N: NO,
            U: UNKNOWN
          },
          email_flow: {
            I: 'Incoming',
            O: 'Outgoing',
            U: UNKNOWN
          },
          notified: {
            NO: NOTIFIED,
            ER: 'Notification Error',
            MISSING: 'Not Notified',
            null: 'Not Notified'
          },
          status: {
            FO: 'Found',
            IP: 'In Progress',
            QU: 'Quarantined',
            DE: 'Deleted',
            NO: NOTIFIED,
            NF: 'Not Found',
            ER: 'Quarantine Error'
          }
        },
        remediation_incident: {
          sender_policy_type: {
            QS: 'Quarantine Sender',
            QD: 'Quarantine Domain',
            BS: 'Block Sender',
            BD: 'Block Domain',
            null: 'No sender policy type'
          },
          type: {
            ATO: 'Account Takeover',
            FIR: 'Forensics'
          }
        },
        insights_user_submission: {
          status: {
            SUBMITTED: 'Submitted',
            REMEDIATED: 'Remediated',
            DISMISSED: 'Dismissed'
          },
          is_phishline: {
            true: YES,
            false: NO
          }
        },
        automated_workflows_event: {
          node_event_type: {
            potentialIncident: 'Potential incident detected',
            senderPolicy: 'Sender policy created in Email Gateway Defense',
            userReported: 'User-reported email submitted',
            // Potential Incident Conditions
            potentialIncidentEmailsDetectedCount: 'Number of emails detected for potential incident',
            potentialIncidentIsExternal_noInput: 'Potential incident is external',
            potentialIncidentIsInternal_noInput: 'Potential incident is internal',
            potentialIncidentIsTypePostDelivery_noInput: 'Post-delivery threat',
            potentialIncidentIsTypeRelated_noInput: 'Related threat',
            potentialIncidentMailboxesAffectedCount: 'Number of mailboxes affected by potential incident',
            potentialIncidentSenderCountry: 'Sender country of potential incident',
            potentialIncidentSenderEmail: 'Sender email of potential incident',
            potentialIncidentSubject: 'Email subject of potential incident',
            // Sender Policy Conditions
            senderPolicyBlock_noInput: 'Block sender policy',
            senderPolicyQuarantine_noInput: 'Quarantine sender policy',
            senderPolicyBy: 'Policy created by',
            senderPolicyEmailsDetectedCount: 'Number of emails detected for sender policy',
            senderPolicyMailboxesAffectedCount: 'Number of mailboxes affected by sender policy',
            senderPolicySenderCountry: 'Sender country of sender policy',
            // User Reported Conditions
            userReportedBy: 'Reported by',
            userReportedIsExternal_noInput: 'User-reported email is external',
            userReportedIsInternal_noInput: 'User-reported email is internal',
            userReportedMatchedEmailCount: 'Number of mailboxes affected by user-reported email',
            userReportedReportedEmailCount: 'Number of users reported',
            userReportedSenderCountry: 'Sender country of user-reported email',
            userReportedSenderEmail: 'Sender email of user-reported email',
            userReportedSubject: 'Email subject of user-reported email',
            // Actions
            createIncident: CREATE_INCIDENT,
            emailNotification: EMAIL_NOTIFICATION,
            slackNotification: SLACK_NOTIFICATION,
            msteamsNotification: MS_TEAMS_NOTIFICATION
          },
          node_type: {
            TRIGGER: 'Trigger',
            CONDITION: 'Condition',
            ACTION: 'Action'
          }
        }
      }
    },
    settings: {
      page_title: SETTINGS,
      tabs: {
        automatic_remediation: 'Automatic remediation',
        manual_remediation: 'Manual remediation',
        policy_options: 'Policy options',
        user_reported: 'User-reported email',
        potential_incidents: 'Potential incidents',
        automated_workflows: AUTOMATED_WORKFLOWS,
        syslog_options: 'Syslog options'
      }
    },
    login: {
      auth_code_error: AUTH_CODE_ERROR,
      barracuda_product: FIR_APP_NAME,
      connect_error: `Unable to connect ${MICROSOFT_365} account`,
      connect_o365_title: `Connect to ${MICROSOFT_365} to add ${FIR_APP_NAME} protection`,
      default_error: DEFAULT_ERROR,
      form: {
        already_using: `Already using ${FIR_APP_NAME}? <signin>${SIGN_IN}</signin>`,
        permission_dialog_title: PERMISSION_DIALOG_TITLE
      },
      product_blurb: 'Respond faster to email attacks.',
      signup_description: `${FIR_APP_NAME} is a powerful SaaS solution that let's you remediate threats quickly and efficiently, by automating investigative workflows and enabling direct removal of malicious emails.`
    },
    signin_connect: {
      app_name: `Barracuda ${FIR}`,
      title: `Connect to ${MICROSOFT_365} to add ${FIR_APP_NAME} protection`,
      permission_dialog_title: PERMISSION_DIALOG_TITLE,
      subtitle_1: `Secure your account in real-time with ${FIR_APP_NAME}. You must have an ${MICROSOFT_365} global administrator account to authorize.`,
      subtitle_2:
        'After you sign in to your Microsoft account, accept the application <perm>permissions</perm> to continue.',
      o365_button: `Connect to ${MICROSOFT_365}`,
      footer_text: 'Use another account',
      account_want_to_connect: 'Account to connect'
    },
    missing_requirements: {
      page_title: 'Missing requirements',
      entitlements: {
        subtitle: `You do not currently have permissions to access the Incident Response dashboard for this ${MICROSOFT_365} tenant. Please reach out to your Barracuda account admin to add the necessary entitlements`
      },
      learn_more: LEARN_MORE,
      o365: {
        content: 'Allows Incident Response to search for and remediate suspicious and malicious email.',
        subtitle: `Incident Response leverages ${MICROSOFT_365} to make it easy to investigate and remediate email attacks. For this reason, you must have ${MICROSOFT_365} in production in order to use Incident Response`
      },
      card_title: FIR_APP_NAME
    },
    new_incident_wizard: {
      buttons: {
        back: BACK,
        next: NEXT,
        cancel: CANCEL,
        close: CLOSE,
        apply: APPLY,
        save: SAVE,
        search_messages: 'Search messages',
        refine_search: 'Refine search',
        review_remediation_options: 'Review remediation options',
        remediate: 'Remediate',
        edit_email_alert: 'Edit email alert'
      },
      errors: {
        continuous_remediation: START_CR_ERROR,
        continuous_remediation_demo_error:
          'The operation to start continuous remediation is not permitted in a demo account',
        create_incident: 'Unable to create new incident',
        demo_error: DEMO_ERROR,
        duplicate_incident_in_progress: DUPLICATE_INCIDENT_IN_PROGRESS,
        invalid_char_length: 'Your text should contain at least 3 characters',
        ignore_text: 'Any character beyond 200 count will be ignored',
        search_emails: 'Error searching for emails',
        tag_invalid: TAG_INVALID
      },
      form: {
        sender_email: SENDER_EMAIL,
        sender_name: SENDER_NAME,
        email_subject: EMAIL_SUBJECT,
        match_exact_phrase: 'Match exact phrase',
        message_body_links: MESSAGE_BODY_LINKS,
        message_body_text: MESSAGE_BODY_TEXT,
        attachment_name: 'Attachment name',
        incident_occurred: 'The incident occurred in the',
        include_junk_emails: 'Include emails Barracuda moved to the junk folder'
      },
      labels: {
        search: CREATE_INCIDENT,
        country_search: 'Searching for emails from {country}',
        review_messages: REVIEW_MESSAGES,
        user_options: 'Incident Remediation - User options',
        policy_options: 'Incident Remediation - Policy options',
        creating_incident: 'Creating incident, this may take a few minutes...',
        follow_up: 'Incident creation - Follow-up'
      },
      policy_places: {
        quarantine: QUARANTINE,
        block: BLOCK
      },
      steps: {
        search: 'Searching for emails',
        review_messages: REVIEW_MESSAGES,
        user_options: 'User options',
        policy_options: 'Policy options',
        follow_up: 'Follow-up'
      },
      radio_buttons: {
        default: 'Default',
        custom: 'Custom'
      },
      add_dns_block_exceptions:
        'Adds DNS block exceptions to your Barracuda CloudGen Access account. Account required.',
      add_sender_policy: 'Add a sender policy to',
      add_sender_policy_help: 'Adds sender policy to your Email Gateway Defense account. Account required.',
      adds_global_policy:
        'Adds a global policy to your Email Gateway Defense account. Changes will take affect for all <b>future</b> inbound emails.',
      block_all_user_web_traffic: 'Block all user web traffic for domains contained in links',
      by_all_unique_domains: 'By all unique <b>domains</b> involved',
      by_all_unique_senders: 'By all unique <b>senders</b> involved',
      choose_the_notification: 'Choose the notification to be sent to affected recipients',
      clicking_remediate_to_create: 'Clicking "Remediate" will create a new incident.',
      country: COUNTRY,
      delete_selected_emails: "Delete {count} selected emails from users' mailboxes",
      email: EMAIL,
      email_subject: 'Email subject searches use variations of your search term(s).',
      email_summary_of: 'Email a summary of this incident for tracking purposes',
      for_example_searching:
        'For example, searching for <i>invoices</i> in the email subject will return emails including <i>invoice</i>, <i>invoiced</i>, and <i>invoicing</i> in their subjects.',
      form_error: 'No malicious emails found. Try your search again with different search criteria.',
      from: 'From',
      inbound: 'Inbound',
      incident_management: 'Incident management',
      learn_more: LEARN_MORE,
      comment: 'Comment',
      notification_preview: 'Notification preview',
      outbound: 'Outbound',
      select_options: 'Select options, then click <b>Next</b>',
      select_options_remediate: 'Select options, then click <b>Remediate</b>. Completion might take several minutes.',
      send_incident_summary: 'Send incident summary to ',
      send_warning_email: 'Send warning email alert to internal recipients',
      settings_on_the_incidents: 'settings on the Incidents page',
      subject: SUBJECT,
      tags: TAGS,
      template: 'Template',
      to_create_custom_notification: 'To create or edit a custom notification click the ',
      turn_on_continuous: 'Turn on continuous remediation for this incident',
      turn_on_to_scan:
        'Turn on to continuously scan for and delete emails that match your search criteria. Automatically turns off after 72 hours.',
      we_have_found: 'We have found {count} messages'
    },
    onboarding: {
      title: 'Onboarding',
      page_title: 'Preparing the system to protect your account',
      page_subtitle: 'Depending on the number of mailboxes in your account, it might take a few hours or up to a day'
    },
    permission_banner: {
      title:
        'Incident Response does not currently have all the permissions it needs to remediate incidents.<br>After adding permissions, allow up to 1 hour for permissions to propagate throughout the system.</br>',
      button: ADD_PERMISSION
    },
    potential_incidents: {
      cards: {
        attachment: ATTACHMENT,
        body_links: MESSAGE_BODY_LINKS,
        body_text: MESSAGE_BODY_TEXT,
        community_title: 'Post-delivery threat',
        community_tooltip: 'Potential threats detected',
        demo_error: DEMO_ERROR,
        dismiss: DISMISS,
        dismiss_error: 'Unable to dismiss potential incident. An error occurred.',
        emails_detected: 'Emails detected on',
        incident: INCIDENT,
        create_incident: CREATE_INCIDENT,
        sender_email: SENDER_EMAIL,
        sender_name: SENDER_NAME,
        similar_title: 'Related threat',
        similar_tooltip: 'Similar threats to incidents created',
        subject: SUBJECT,
        threat_details: 'Threat details',
        view_incident: 'View original incident'
      },
      labels: {
        page_error: PAGE_ERROR,
        page_title: POTENTIAL_INCIDENTS,
        page_subtitle: `Potential threats that might exist in your ${MICROSOFT_365} account`,
        no_content: 'No current threats'
      }
    },
    remediation: {
      button: {
        create_incident: CREATE_INCIDENT,
        tooltip: CREATE_INCIDENT_TOOLTIP,
        learn_more: LEARN_MORE,
        try_free_now: TRY_FREE_NOW
      },
      dashboards: {
        createdIncidents: {
          title: 'Incidents created',
          highlight: 'in the last 6 months, updated daily',
          tooltip: 'Total incidents created',
          noData: STATS_NOT_AVAILABLE
        },
        threatsRemediated: {
          title: 'Threats remediated',
          highlight: 'in the last 6 months, updated daily',
          tooltip: 'Total threats remediated',
          noData: STATS_NOT_AVAILABLE
        },
        topUsers: {
          title: 'Top 5 attacked users',
          subtitle: 'Total threat emails received in the last 6 months, updated daily',
          tooltip: 'Attacks remediated',
          noData: STATS_NOT_AVAILABLE
        }
      },
      labels: {
        page_error: PAGE_ERROR,
        page_title: INCIDENTS,
        page_subtitle: 'Summary of incidents created for your account'
      },
      incident_table: {
        continuous_remediation: 'Continuous remediation',
        created_on: CREATED_ON,
        created_by: 'Created by',
        incident: INCIDENT,
        messages_received: 'Messages received',
        pending_incident: 'Pending creation',
        tags: TAGS,
        view_incident: VIEW_INCIDENT
      },
      incident_details: {
        incidents: 'Incidents',
        labels: {
          continuous_remediation_start_error: START_CR_ERROR,
          demo_error: DEMO_ERROR,
          incidentDetails: 'Incident details',
          incidentDetailsSubtitle: 'Information about the incident',
          remediationAction: 'Actions taken for remediation',
          remediationActionSubtitle: 'Remediation actions you chose to take on the emails below',
          tagInvalid: TAG_INVALID,
          tags: TAGS,
          title: VIEW_INCIDENT
        },
        link: {
          additional_recommendations: 'Additional recommended actions to recover from the incident\n'
        },
        remediationAction: {
          values: {
            automaticRemediation: 'Automatically generated this incident',
            quarantined: "Moved email to junk folder in {count} internal users' mailboxes",
            quarantinedBreakdown: '{count} emails quarantined, {creCount} through continuous remediation',
            delete_in_progress: "Deleting email in {count} users' mailboxes",
            deleted: "Deleted email in {count} internal users' mailboxes",
            deletedBreakdown: '{count} emails deleted, {creCount} through continuous remediation',
            notified: 'Sent email alert to {count} internal recipients',
            senderPolicies: 'Added {count} policies to {action} {type} in Email Gateway Defense',
            senderPoliciesBlocked: BLOCK,
            senderPoliciesQuarantined: QUARANTINE,
            senderPoliciesSenders: 'senders',
            senderPoliciesDomains: 'domains',
            bcsPolicies: 'Added {count} policies to block domains linked in emails in Barracuda CloudGen Access',
            noActionsTaken: 'No remediation actions taken'
          }
        },
        summary: {
          reported: 'Reported',
          createdBy: 'By',
          impact: 'Impact',
          continuousRemediation: 'Continuous remediation',
          values: {
            messagesAffected:
              '{messagesCount} messages affected {internalUsersCount} internal and {externalUsersCount} external users',
            activeUntil: 'Active until {date}'
          }
        },
        searchCriteria: {
          attachment: ATTACHMENT,
          sender: SENDER,
          subject: SUBJECT,
          title: 'Search criteria'
        },
        tabs: {
          clicked_links: 'Clicked links',
          emails: 'Emails',
          threats: 'Threats',
          users: 'Users'
        }
      },
      clicked_links_by_incident: {
        labels: {
          page_title: 'Users who clicked links',
          page_subtitle: 'Users who clicked links in emails related to this incident',
          distribution_list:
            '<b>Emails sent to distribution lists</b> - Not all users might have clicked the link. Use the information below to determine affected users.',
          export_csv: EXPORT_CSV
        },
        clicked_links_table: {
          clickedIps: 'Clicked IP',
          clickedLink: 'Clicked link',
          dateReceived: RECEIVED_DATE,
          messageId: 'Message ID',
          noClicks: 'No links clicked',
          subject: SUBJECT,
          userAgents: 'Clicked User Agents',
          usersInvolved: USERS_INVOLVED
        }
      },
      email_by_incident: {
        labels: {
          page_title: 'Emails involved',
          page_subtitle: 'List of emails that are included in the incident',
          delete_existing_emails: 'Delete emails',
          export_csv: EXPORT_CSV
        },
        delete_dialog: {
          cancel: CANCEL,
          content: 'This may take a few minutes (up to an hour).',
          delete: 'Delete',
          title: 'Are you sure you want to delete {count} email(s)?'
        },
        email_table: {
          affected_mailbox: 'Affected mailbox',
          old_email: 'Unable to view emails older than 30 days',
          received_date: RECEIVED_DATE,
          sender_email: SENDER_EMAIL,
          status: STATUS,
          subject: SUBJECT,
          view_email: VIEW_EMAIL,
          tooltip: {
            creRemediated: 'Email was successfully remediated during continuous remediation',
            creNotFound: 'Email deleted by the user during continuous remediation',
            creError: 'Email could not be remediated',
            error: 'Email could not be remediated',
            found: 'No remediation actions taken for this email',
            inProgress: 'Email remediation is in progress',
            notFound: 'Email deleted by the user',
            remediated: 'Email was successfully remediated'
          }
        }
      },
      users_by_incident: {
        alert: {
          success: 'The {addressBookName} training group was created'
        },
        labels: {
          page_title: USERS_INVOLVED,
          page_subtitle: 'The following users were affected by emails in this incident',
          clicked_links_pending:
            'We are still collecting information about this incident. Details on this page will be updated',
          create_training_group: 'Create training group',
          export_csv: EXPORT_CSV
        },
        tooltips: {
          export_csv: 'Exports data from all tabs for this incident and is not limited by the selection of users.',
          external_user_not_included: " External users can't be included in a training group. ",
          clicked_links_disabled: 'This data requires an active Email Gateway Defense account',
          clicked_links_unknown:
            'At this time, we are unable to determine if some recipients clicked on any links in the emails. As a precaution, you might change the password of this recipient',
          clicked_links_off:
            'Link protection in Email Gateway Defense is turned off for a relevant domain or account and might have been off when the email was received',
          create_training_group:
            'Simplify employee training by creating an address book from the selected recipients. The new address book can be used while setting up campaigns in Barracuda Security Awareness Training.'
        },
        training_group_dialog: {
          address_book_to_be_created: 'Address book to be created',
          buttons: {
            cancel: CANCEL,
            close: CLOSE,
            create: CREATE
          },
          create_training: 'Create training',
          error: {
            must_select_instance:
              "You must choose an account. Contact your Security Awareness team if you aren't sure which one to select.",
            try_later_time:
              'There was a problem creating an address book in Barracuda Security Awareness Training. Please try again at a later time.',
            unique_address_book_name:
              'There was a problem creating an address book in Barracuda Security Awareness Training. Please try again in one minute.'
          },
          more_security_training_accounts:
            'You have more than one Security Awareness Training account. Select an account to create the training group.',
          security_awareness_training_account: 'Security Awareness Training account',
          selected_recipients: 'Selected recipients for training ({count})',
          sourceOfCreation: 'IncidentResponse', // Keep as one word for SAT
          title: 'Training group',
          train_employees_by_selecting_address_book:
            'Train employees through the Barracuda Security Awareness Training product by selecting the address book created from this group while setting up a training or simulation campaign.'
        },
        user_details_table: {
          email: EMAIL,
          clicked_links: 'Clicked on links',
          opened: 'Opened email',
          replied: 'Replied to email',
          userType: 'User type',
          forwarded: 'Forwarded email',
          values: {
            yes: YES,
            no: NO,
            unknown: 'Undetected',
            off: 'Off',
            continuous_remediation: 'Continuous remediation (N/A)'
          }
        }
      },
      threats_by_incident: {
        labels: {
          page_title: 'Threats involved',
          page_subtitle: 'The following email items were identified as malicious'
        },
        threat_details_table: {
          category: 'Category',
          extra: 'Extra',
          threat: 'Threat',
          type: TYPE
        }
      }
    },
    serial_number_dialog: {
      cancel: CANCEL,
      content:
        'If you have purchased an Incident Response license, please provide your serial number and linking code below.',
      linking_code_text: 'Incident Response linking code',
      linking_code_text_error: LINKING_CODE_INVALID,
      serial_number_text: 'Incident Response serial number',
      serial_number_text_error: SERIAL_NUMBER_INVALID,
      submit: SUBMIT,
      title: 'Enter license information'
    },
    settings_dialog: {
      labels: {
        dialog: '{source} Settings',
        demo_error: DEMO_ERROR,
        invalid_email: INVALID_EMAIL,
        save_error: 'Unable to save changes'
      },
      buttons: {
        cancel: CANCEL,
        save: SAVE
      },
      automated_workflows: {
        labels: {
          source: AUTOMATED_WORKFLOWS,
          sync_autoincident_email_address:
            'For automated workflows with the email notification action, use the same email address specified in the automatic remediation settings',
          notify_address: SECURITY_TEAM_EMAIL,
          slack_webhook: 'For automated workflows with the Slack notification action, specify the Slack webhook',
          slack_webhook_label: SLACK_WEBHOOK,
          msTeams_webhook:
            'For automated workflows with the Microsoft Teams notification action, specify the Microsoft Teams webhook',
          msTeams_webhook_label: MS_TEAMS_WEBHOOK,
          slack_webhook_error: 'Enter a valid Slack webhook URL',
          msTeams_webhook_error: 'Enter a valid Microsoft Teams webhook URL'
        }
      },
      potential_incidents: {
        labels: {
          source: POTENTIAL_INCIDENTS,
          send_email_alert: 'Send an email alert to the security team when a potential incident is detected',
          sync_incident_email_address: USE_MANUAL_REMEDIATION_EMAIL,
          notify_address: SECURITY_TEAM_EMAIL
        }
      },
      user_reported: {
        labels: {
          source: USER_REPORTED_EMAILS,
          send_email_alert: 'Send an email alert to the security team when a user reports a suspicious email',
          sync_autoincident_email_address: USE_AUTOMATIC_REMEDIATION_EMAIL,
          sync_incident_email_address: USE_MANUAL_REMEDIATION_EMAIL,
          notify_address: SECURITY_TEAM_EMAIL,
          ignore_sat_emails: IGNORE_SAT_EMAILS
        }
      },
      automatic_remediation: {
        labels: {
          activate: 'Enable automatic remediation',
          create_incident: 'Create incident action',
          delete_emails: DELETE_EMAILS,
          move_emails: "Move emails from users' mailboxes to users' junk folder",
          automatic_remediation: 'Automatic remediation',
          automatic_remediation_subtitle:
            'The system automatically creates and remediates certain incidents, when automatic remediation is enabled.'
        }
      },
      remediation: {
        labels: {
          source: 'Remediation',
          add_sender_policy: 'Add a sender policy to',
          add_content_shield_policy: 'Block all user web traffic for domains contained in links',
          automatic_remediation: 'Automatic remediation',
          content_shield_policy_subtitle:
            'Add DNS block exceptions to your Barracuda CloudGen Access account. Account required.',
          continuous_remediation: 'Enable continuous remediation for incidents',
          customize_alert: 'Customize alert',
          delete_emails: DELETE_EMAILS,
          email: EMAIL,
          ignore_phishline_campaign_emails: IGNORE_SAT_EMAILS,
          notify_end_users: 'Send an email alert to the recipient',
          notify_admin_address: 'Send an email alert to the security team',
          policy: 'Policy options',
          policy_subtitle: 'All settings below apply to both automatically and manually remediated incidents',
          sender_policy_subtitle: 'Add sender policy to your Email Gateway Defense account. Account required.',
          security_team_email: 'Security team email',
          remediation: 'Manual remediation',
          remediation_subtitle:
            "An admin on the account can create and remediate an incident manually by searching for emails in users' mailboxes",
          sender_policy_actions: {
            quarantine: QUARANTINE,
            block: BLOCK
          },
          sender_policy_types: {
            senders: 'By all unique <b>senders</b> involved',
            domains: 'By all unique <b>domains</b> involved'
          }
        }
      },
      siem: {
        labels: {
          title: 'Syslog configuration options',
          subtitle:
            'Export event data to a syslog server or a security information and events management (SIEM) system',
          activate: 'Enable syslog integration',
          host: 'IP address / hostname',
          port: 'Port',
          note: 'Note: TCP+TLS is required to connect successfully. Non-TLS is not'
        },
        buttons: {
          test: 'Test',
          save: SAVE
        },
        alerts: {
          save_success: 'Updated syslog configuration',
          save_error: 'Failed to update syslog configuration. Update your entries and save again.',
          test_success: 'Server Status: Port Reachable',
          test_error: 'Message failed to deliver to remote syslog server. Update your entries and retest.'
        }
      },
      learn_more: LEARN_MORE
    },
    navbar: {
      options: {
        apps: 'Your apps',
        profile: 'Profile',
        profile_menu: {
          manage_account: 'Manage account',
          review_licenses: 'Review licenses',
          log_out: 'Log out'
        }
      },
      automated_workflows: AUTOMATED_WORKFLOWS,
      insights_automated: INSIGHTS,
      remediation: INCIDENTS,
      user_reported: USER_REPORTED_EMAILS,
      settings: SETTINGS,
      unified_reporting_root: UNIFIED_REPORTING,
      report_list: 'Report list',
      scheduled_reports: 'Scheduled reports',
      serial_number: 'Serial number: {serialNumber}',
      expiration: 'Expiration: {date}'
    },
    start_trial: {
      page_title: 'Activate your free trial',
      activate: {
        activate_button: `Activate ${FIR}`,
        activate_header: `Activate ${FIR_APP_NAME}`,
        linking_code: 'Linking code',
        linking_code_error: LINKING_CODE_INVALID,
        serial_number: 'Serial number',
        serial_number_error: SERIAL_NUMBER_INVALID
      },
      already_purchased: `I have already purchased ${FIR_APP_NAME}`,
      barracuda: BARRACUDA_EMAIL_PROTECTION,
      product: FIR,
      subtitle: 'Respond faster to email attacks.',
      product_description: `${FIR_APP_NAME} is a powerful SaaS solution that lets you remediate threats quickly and efficiently, by automating investigative workflows and enabling direct removal of malicious emails.`,
      try_free: 'Try <b>Email Protection Premium Plus</b> trial for FREE for 14-days.',
      no_credit_card: 'No credit card is required.',
      start_trial: START_TRIAL,
      use_another_account: 'Use another account'
    },
    user_reported: {
      charts: {
        topReporters: {
          noData: 'No records available for the past 30 days',
          title: 'Top 5 reporters',
          tooltip_dismissed: 'dismissed',
          tooltip_remediated: 'remediated',
          tooltip_submitted: 'pending review'
        }
      },
      labels: {
        demo_error: DEMO_ERROR,
        dismissed_page_title: `Dismissed ${USER_REPORTED_EMAILS}`,
        page_error: PAGE_ERROR,
        page_subtitle:
          'Emails reported by users as spam/suspicious. Regardless of when they were reported, emails will no longer appear once they are older than 30 days.',
        page_title: USER_REPORTED_EMAILS,
        phishline_campaign: 'SAT campaign'
      },
      user_reported_table: {
        affected_mailboxes: 'Affected mailboxes',
        last_reported_date: 'Last reported date',
        number_of_users_reported: 'Users reported',
        recipient: 'recipient',
        recipients: 'recipients',
        sender_email: SENDER_EMAIL,
        subject: SUBJECT
      },
      button: {
        create_incident: CREATE_INCIDENT,
        tooltip: CREATE_INCIDENT_TOOLTIP,
        tooltip_sat_campaign:
          '<b>Create incident</b> is disabled for Security Awareness Training campaign emails. You may change this setting under <b>Settings > User-reported email</b>.',
        dismiss: DISMISS,
        learn_more: LEARN_MORE,
        try_free_now: TRY_FREE_NOW,
        view_dismissed: 'Show dismissed',
        view_submitted: 'Show pending review'
      },
      maliciousTooltip: {
        maliciousAttachment: 'Malicious attachment detected',
        maliciousAttachmentAndURL: 'Malicious attachment & URL detected',
        maliciousURL: 'Malicious URL detected'
      },
      phishlineCampaignTooltip: {
        phishline_email: 'This email is likely from a Security Awareness Training (SAT) email campaign'
      }
    },
    license: {
      no_permissions: NO_PERMISSIONS,
      page_title: 'Review licenses'
    }
  }
}
/* eslint-enable @typescript-eslint/naming-convention, quotes */
