import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  text: {
    margin: 'auto',
    maxWidth: '65%',
    maxHeight: '100%',
    marginTop: theme.spacing(6),
    fontSize: 16,
    lineHeight: '20px'
  }
}))
