import React, { useMemo, useCallback, useEffect, useReducer } from 'react'

import { useParams } from 'react-router-dom'

import { process, SortDescriptor, GroupDescriptor, FilterDescriptor } from '@progress/kendo-data-query'
import { GridColumnMenuFilter, GridDetailRow } from '@progress/kendo-react-grid'

import { BreadcrumbList } from 'global/components/lib/breadcrumbs/Breadcrumbs'
import EmailDetails from 'global/components/lib/dialogs/emailDetailsDialog/components/emailDetails/EmailDetails'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import * as datetime from 'global/lib/datetime'
import collectHighlights from 'global/lib/highlightText/collectHighlights'
import isoCountries, { getCountryNameFromCode2 } from 'global/lib/isoCountries'
import { useFormatMessage } from 'global/lib/localization'
import toggleInArray from 'global/lib/toggleInArray'
import { isFailed, isPending, isSuccess } from 'global/redux/toolkit/api'
import { Email } from 'global/types/api/emailsType'
import { IncidentDetailsSource } from 'global/types/api/newIncident'

import { NewIncidentDialogProps } from 'fir/components/lib/newIncidentDialog/NewIncidentDialog'
import useEmailDetailsInterface, {
  collectRecipients
} from 'fir/components/lib/dialogs/emailDetailsDialog/useEmailDetailsInterface'
import routesConfig from 'fir/lib/routes/routesConfig'
import { getEmails, getMoreEmails } from 'fir/redux/features/search/searchApiThunks'
import { resetEmails, resetEmailsFailures } from 'fir/redux/features/search/searchSlice'
import * as emailsByRegionCountryTableActions from 'fir/redux/features/dataTables/emailsByRegionCountry/emailsByRegionCountrySlice'
import { useAppDispatch, useAppSelector } from 'fir/redux/toolkit/hooks'
import { ColumnsConfig, GridColumns } from 'fir/redux/types/DataTables'

export interface EmailsByRegionCountryProps {
  emailsTotalCount?: number
  isMoreResultsLoading?: boolean
  showCountryPageError?: boolean
  breadcrumbList: BreadcrumbList[]
  columnsConfig: ColumnsConfig
  columnMenuConfig: {
    filter: null | FilterDescriptor[] | string[]
    columns: ColumnsConfig
  }
  dateRange: datetime.DateRange
  expandConfig: {
    expandField: string
    onExpandChange: (e: any) => void
  }
  filterConfig: {
    onFilterChange: (e: any) => void
  }
  GRID_COLUMNS: GridColumns
  gridData: {
    total: number
    data: Email[]
  }
  groupConfig: {
    group: any[]
    onGroupChange: (e: any) => void
  }
  highlights: any
  isColumnActive: (field: string) => boolean
  isPageInProgress: boolean
  newIncidentDialogConfig: NewIncidentDialogProps
  onClosePageAlert: () => void
  onCreateIncident: (emailId: string) => void
  pageConfig: {
    skip: number
    take: number
    total: number
    onPageChange: (e: any) => void
  }
  sortConfig: {
    sort: SortDescriptor[]
    onSortChange: (e: any) => void
  }
}

export interface UrlParams {
  country?: string
}

export interface GridRow {
  emailId: string
  created: string
  formattedCreated: string
  sender: string
  uniqueRecipients: number
  subject: string
}

export interface Sender {
  address: string
  name: string
}

const BASE_I18N_KEY = 'fir.app.insights.emails_by_region'
const EXPAND_FIELD = 'expandField'

export default function useEmailsByRegionCountryLogic(): [EmailsByRegionCountryProps] {
  const urlParams: UrlParams = useParams()
  const dispatch = useAppDispatch()
  const [emailDetailDialogConfig, emailDetailDialogActions] = useEmailDetailsInterface({})

  // Redux Toolkit stores
  const {
    accessTokenId,
    emails,
    emailsByRegion,
    emailsByRegionCountry,
    isEmailInfoFailed,
    isEmailInfoLoading,
    isEmailsFailed,
    isEmailsLoading,
    isEmailsSuccess,
    isMoreEmailsFailed,
    isMoreEmailsLoading,
    searchEmails
  } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id,
    emails: _stores.emails,
    emailsByRegion: _stores.dataTables.emailsByRegion,
    emailsByRegionCountry: _stores.dataTables.emailsByRegionCountry,
    isEmailInfoFailed: isFailed(_stores.emails.emailInfoLoadingApiStatus),
    isEmailInfoLoading: isPending(_stores.emails.emailInfoLoadingApiStatus),
    isEmailsFailed: isFailed(_stores.search.getEmailsApiStatus),
    isEmailsLoading: isPending(_stores.search.getEmailsApiStatus),
    isEmailsSuccess: isSuccess(_stores.search.getEmailsApiStatus),
    isMoreEmailsFailed: isFailed(_stores.search.getMoreEmailsApiStatus),
    isMoreEmailsLoading: isPending(_stores.search.getMoreEmailsApiStatus),
    searchEmails: _stores.search.emails
  }))

  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [state, setState] = useReducer((_state: any, newState: any) => ({ ..._state, ...newState }), {
    newIncidentEmailId: null
  })

  // Init
  useEffect(() => {
    dispatch(getEmails({ config: apiParams }))

    // unmount
    return () => {
      dispatch(resetEmails())
      dispatch(emailsByRegionCountryTableActions.reset())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // // make sure email count has loaded and then track pageview
  useEffect(() => {
    if (searchEmails.totalCount) {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.INSIGHTS_REVIEW_COUNTRY, {
        accessTokenId,
        count: searchEmails.totalCount,
        country: urlParams.country,
        page: 'insights',
        timePeriod: emailsByRegion.timeframe
      })
    }
  }, [urlParams.country, accessTokenId, emailsByRegion.timeframe, searchEmails.totalCount])

  // helpers
  const apiParams = useMemo(() => {
    const { page, sort, filter, group } = emailsByRegionCountry

    return {
      page: {
        offset: Number(page.skip),
        limit: Number(page.take)
      },
      countryId: urlParams.country || '',
      timeframe: emailsByRegion.timeframe,
      sort,
      filter,
      group
    }
  }, [emailsByRegionCountry, urlParams.country, emailsByRegion.timeframe])

  const onCreateIncident = useCallback(
    (emailId: string) => {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.INCIDENT_WIZARD_NEW_INCIDENT_STARTED, {
        accessTokenId,
        page: 'insights/geoip'
      })
      setState({ newIncidentEmailId: emailId })
    },
    [accessTokenId]
  )

  // loaders
  useEffect(() => {
    if (isEmailsSuccess) {
      dispatch(getMoreEmails({ config: apiParams, resetResult: true }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailsByRegion.timeframe, emailsByRegionCountry.sort, emailsByRegionCountry.filter, emailsByRegionCountry.group])

  useEffect(() => {
    if (isEmailsSuccess && !searchEmails.results[emailsByRegionCountry.page.skip]) {
      dispatch(getMoreEmails({ config: apiParams }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailsByRegionCountry.page.skip])

  // highlight
  const highlights = useMemo(() => {
    if (emailsByRegionCountry.filter) {
      return collectHighlights((emailsByRegionCountry.filter as any).filters, {})
    }

    return {}
  }, [emailsByRegionCountry.filter])

  // breadcrumb
  const breadcrumbList = useMemo(() => {
    return [
      {
        text: formatMessage('insights')
      },
      {
        text: formatMessage('emails_by_region'),
        onClick: () => {
          routesConfig.INSIGHTS_GEOIP.goto()
        }
      },
      {
        text: getCountryNameFromCode2(isoCountries.alpha3ToAlpha2(urlParams.country || ''))
      }
    ] as BreadcrumbList[]
  }, [formatMessage, urlParams.country])

  // grid
  const populateGroupedItems: any = useCallback(
    (items: any[]) => {
      return items
        .filter((item: any) => Object.keys(item).length)
        .map((item: any) => {
          let expandField = !emailsByRegionCountry.collapsedGroups.includes(`${item.field}/${item.value}`)
          if (item.emailId) {
            expandField = emailsByRegionCountry.expandedRows.includes(item.emailId)
          }

          return {
            ...item,
            [EXPAND_FIELD]: expandField,
            ...(item.aggregates && {
              ...(item.field === emailsByRegionCountry.GRID_COLUMNS.SENDER && {
                value: item.value
              }),
              items: populateGroupedItems(item.items)
            })
          }
        })
    },
    [
      emailsByRegionCountry.collapsedGroups,
      emailsByRegionCountry.GRID_COLUMNS.SENDER,
      emailsByRegionCountry.expandedRows
    ]
  )

  const gridData = useMemo(() => {
    const { page } = emailsByRegionCountry

    const data = process(
      (searchEmails.results as any[])
        .slice(page.skip, page.skip + page.take)
        .reduce((all: GridRow[], result: Email & { [key: string]: any[] }) => {
          if (!result) {
            return [...all, {}]
          }
          const emailInfo = emails.emailInfoList[result.emailId]
          const emailDetails = emailInfo && {
            attachments: emailInfo.attachments,
            body: emailInfo.body.content,
            bodyMimeType: 'text/html',
            date: emailInfo.created,
            from: {
              email: emailInfo.sender.address,
              displayName: emailInfo?.sender.name
            },
            headers: emailInfo['internet-message-headers'],
            subject: emailInfo.subject,
            to: {
              email: collectRecipients(emailInfo.recipients || []),
              displayName: ''
            }
          }
          return [
            ...all,
            {
              emailId: result.emailId,
              created: result.created,
              formattedCreated: datetime.formatDateWithTime(result.created),
              sender: `${result.sender.name} ${
                result.sender.name !== result.sender.address ? `<${result.sender.address}>` : ''
              }`,
              uniqueRecipients: ['bcc-recipients', 'cc-recipients', 'recipients'].reduce(
                (all2: number, recipientPropName: string) => all2 + result[recipientPropName].length,
                0
              ),
              expandedRowContent: {
                emailDetailDialogConfig: emailDetails
                  ? { ...emailDetailDialogConfig, emailDetails }
                  : emailDetailDialogConfig
              },
              subject: result.subject
            } as GridRow
          ]
        }, []),
      { group: emailsByRegionCountry.group }
    )

    return {
      total: searchEmails.totalCount || 0,
      data: populateGroupedItems(data.data)
    }
  }, [
    emailsByRegionCountry,
    emailDetailDialogConfig,
    searchEmails.results,
    searchEmails.totalCount,
    populateGroupedItems,
    emails.emailInfoList
  ])

  const isColumnActive = useCallback(
    (field: string) => {
      return GridColumnMenuFilter.active(field, emailsByRegionCountry.filter as any)
    },
    [emailsByRegionCountry.filter]
  )

  const onTableConfigChange = useCallback(
    (newConfig: any) => {
      dispatch(emailsByRegionCountryTableActions.update({ config: newConfig }))
    },
    [dispatch]
  )

  const onExpandChange = useCallback(
    (e: any) => {
      if (e.dataItem.emailId) {
        if (
          !isEmailInfoLoading &&
          (!isEmailInfoFailed || (isEmailInfoFailed && e.dataItem.emailId !== emails.lastFailedEmailId)) &&
          !emails.emailInfoList[e.dataItem.emailId] &&
          e.value &&
          e.dataItem.emailId !== emails.lastFailedEmailId
        ) {
          emailDetailDialogActions.onOpen(e.dataItem)
        }
        onTableConfigChange({
          expandedRows: toggleInArray(emailsByRegionCountry.expandedRows, e.dataItem.emailId)
        })
      } else {
        onTableConfigChange({
          collapsedGroups: toggleInArray(
            emailsByRegionCountry.collapsedGroups,
            `${e.dataItem.field}/${e.dataItem.value}`
          )
        })
      }
    },
    [
      isEmailInfoFailed,
      isEmailInfoLoading,
      emails.lastFailedEmailId,
      emails.emailInfoList,
      emailsByRegionCountry.collapsedGroups,
      emailsByRegionCountry.expandedRows,
      emailDetailDialogActions,
      onTableConfigChange
    ]
  )

  const rowRender = useCallback(
    (trElement, rowProps) => {
      if (rowProps.rowType === 'groupHeader') {
        return React.cloneElement(
          trElement,
          trElement.props,
          trElement.props.children.slice(0, emailsByRegionCountry.group.length)
        )
      }

      return trElement
    },
    [emailsByRegionCountry.group.length]
  )

  const onClosePageAlert = useCallback(() => {
    dispatch(resetEmailsFailures())
  }, [dispatch])

  const showCountryPageError = useMemo(() => {
    return isEmailsFailed || isMoreEmailsFailed
  }, [isEmailsFailed, isMoreEmailsFailed])

  return useMemo(
    () => [
      {
        breadcrumbList,
        columnsConfig: emailsByRegionCountry.columnsConfig,
        columnMenuConfig: {
          filter: emailsByRegionCountry.filter,
          columns: emailsByRegionCountry.columnsConfig
        },
        dateRange: emailsByRegion.dateRange,
        emailsTotalCount: searchEmails.totalCount,
        expandConfig: {
          detail: (cellProps: any) => {
            if (!cellProps.dataItem.emailId) {
              return null
            }

            return (
              <div>
                <GridDetailRow dataItem={{}} dataIndex={0} />
                <EmailDetails
                  data={cellProps.dataItem.expandedRowContent.emailDetailDialogConfig}
                  hideReplyTo
                  isExpandRow
                  isGroupedRecipients
                  showThreatTab
                />
              </div>
            )
          },
          expandField: EXPAND_FIELD,
          onExpandChange
        },
        filterConfig: {
          onFilterChange: (e: any) => {
            onTableConfigChange({ filter: e.filter as any })
          }
        },
        isMoreResultsLoading: isMoreEmailsLoading,
        isPageInProgress: isEmailsLoading,
        GRID_COLUMNS: emailsByRegionCountry.GRID_COLUMNS,
        gridData,
        groupConfig: {
          group: emailsByRegionCountry.group,
          onGroupChange: (e: any) => {
            onTableConfigChange({ group: e.group as GroupDescriptor[] })
          },
          rowRender
        },
        highlights,
        isColumnActive,
        newIncidentDialogConfig: {
          incidentDetailsSource: IncidentDetailsSource.region,
          isOpened: !!state.newIncidentEmailId,
          ...(state.newIncidentEmailId && {
            emailInfo: {
              ...searchEmails.results.find((email: Email) => email && email.emailId === state.newIncidentEmailId),
              timeframe: emailsByRegion.timeframe
            }
          }),
          country: urlParams.country,
          onClose: () => {
            setState({ newIncidentEmailId: null })
          }
        } as NewIncidentDialogProps,
        onClosePageAlert,
        onCreateIncident,
        pageConfig: {
          ...emailsByRegionCountry.page,
          total: searchEmails.totalCount,
          onPageChange: (e: any) => {
            onTableConfigChange({ page: e.page as { skip: number; take: number } })
          }
        },
        showCountryPageError,
        sortConfig: {
          sortable: {
            allowUnsort: false
          },
          sort: emailsByRegionCountry.sort,
          onSortChange: (e: any) => {
            onTableConfigChange({ sort: e.sort as SortDescriptor[] })
          }
        }
      }
    ],
    [
      breadcrumbList,
      emailsByRegion.dateRange,
      emailsByRegion.timeframe,
      emailsByRegionCountry.GRID_COLUMNS,
      emailsByRegionCountry.columnsConfig,
      emailsByRegionCountry.filter,
      emailsByRegionCountry.group,
      emailsByRegionCountry.page,
      emailsByRegionCountry.sort,
      gridData,
      highlights,
      isColumnActive,
      isEmailsLoading,
      isMoreEmailsLoading,
      onClosePageAlert,
      onCreateIncident,
      onExpandChange,
      onTableConfigChange,
      rowRender,
      searchEmails.results,
      searchEmails.totalCount,
      showCountryPageError,
      state.newIncidentEmailId,
      urlParams.country
    ]
  )
}
