import { useMemo, useEffect, useCallback } from 'react'

import { useAppDispatch } from 'global/redux/toolkit/hooks'

import useTabs, { BaseTabsConfig, OnTabSelected } from 'global/lib/useTabs/useTabs'

import { reset as resetSettings, resetError as resetSettingsError } from 'sen/redux/features/settings/settingsSlice'

export interface TabsConfig extends BaseTabsConfig {
  tabs: string[]
}

const TABS = ['spear_phishing', 'ato', 'allowed_senders', 'syslog_integration']

export default function useSettingsDialogLogic(defaultTab = 0): [TabsConfig] {
  const dispatch = useAppDispatch()

  const [selectedTab, onTabSelectedDefault] = useTabs(defaultTab)

  // init
  useEffect(() => {
    // unmount
    return () => {
      dispatch(resetSettings())
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onTabSelected: OnTabSelected = useCallback(
    (e, newTab) => {
      dispatch(resetSettingsError())
      onTabSelectedDefault(e, newTab)
    },
    [dispatch, onTabSelectedDefault]
  )

  return useMemo(() => {
    return [
      {
        selectedTab,
        onTabSelected,
        tabs: TABS
      }
    ]
  }, [selectedTab, onTabSelected])
}
