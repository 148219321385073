import { SortDescriptor } from '@progress/kendo-data-query'
import { createSlice } from '@reduxjs/toolkit'

import { ColumnsConfig, GridColumns } from 'fir/redux/types/DataTables'
import { config } from 'global/lib/config'

interface AutomatedWorkflowDetailsState {
  collapsedGroups: any[]
  columnsConfig: ColumnsConfig
  expandedRows: any[]
  GRID_COLUMNS: GridColumns
  page: { skip: number; take: number }
  sort: SortDescriptor[]
}

export const GRID_COLUMNS = {
  ACTIONS_TAKEN: 'anyActions',
  TIME_OF_RUN: 'createdOn',
  WORKFLOW_NAME: 'workflowName'
}

export const COLUMNS_CONFIG = {
  [GRID_COLUMNS.TIME_OF_RUN]: {
    orderIndex: 0,
    show: true,
    sortable: true
  },
  [GRID_COLUMNS.WORKFLOW_NAME]: {
    orderIndex: 1,
    show: true,
    sortable: true
  },
  [GRID_COLUMNS.ACTIONS_TAKEN]: {
    orderIndex: 2,
    show: true,
    sortable: true
  }
}

// Store
export const INITIAL_STATE: AutomatedWorkflowDetailsState = {
  collapsedGroups: [],
  columnsConfig: COLUMNS_CONFIG,
  expandedRows: [],
  GRID_COLUMNS,
  page: { skip: 0, take: config.DATA_TABLE.FORENSICS.ITEMS_PER_PAGE },
  sort: [{ field: GRID_COLUMNS.TIME_OF_RUN, dir: 'desc' }]
}

export const automatedWorkflowDetailsSlice = createSlice({
  name: 'DATATABLE/AUTOMATED_WORKFLOW_DETAILS',
  initialState: INITIAL_STATE,
  reducers: {
    update: (state, action) => {
      return {
        ...state,
        ...action.payload?.config
      }
    },
    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  }
})

export const { update, reset } = automatedWorkflowDetailsSlice.actions

export default automatedWorkflowDetailsSlice.reducer
