import React, { useMemo } from 'react'

import { v4 as uuidv4 } from 'uuid'

import { Typography, Grid } from '@barracuda-internal/bds-core'

import { useFormatMessage } from 'global/lib/localization'

import IMAGES from 'sen/configs/theme/images.config'
import styles from 'sen/components/pages/dashboard/scanInProgress/scanInProgressStyles'

const BASE_I18N_SCAN_IN_PROGRESS_KEY = 'sen.app.dashboard.scan_in_progress'

export interface ScanInProgressProps {
  userEmail: string
}

const ScanInProgress: React.FC<ScanInProgressProps> = ({ userEmail }) => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_SCAN_IN_PROGRESS_KEY)

  return useMemo(
    () => (
      <Grid
        container
        className={classes.container}
        alignItems="center"
        justifyContent="center"
        direction="column"
        data-field="root-grid"
      >
        <img className={classes.image} alt="scan in progress" src={IMAGES.emailScanning} data-field="image" />
        <Typography className={classes.title} data-field="title">
          {formatMessage('title')}
        </Typography>
        <Typography align="center" className={classes.subtitle} data-field="subtitle">
          {formatMessage('subtitle', {
            br: (txt: string) => (
              <data key={uuidv4()}>
                <br />
                {txt}
              </data>
            ),
            bold: (txt: string) => <b key={txt}>{txt}</b>,
            email: userEmail
          })}
        </Typography>
      </Grid>
    ),
    [userEmail, classes, formatMessage]
  )
}

export default ScanInProgress
