import { useEffect, useMemo, useCallback, useRef, useContext } from 'react'

import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { isSuccess } from 'global/redux/toolkit/api'

import { useAppSelector, useAppDispatch } from 'sen/redux/toolkit/hooks'
import { NewIncidentDialogWizardContext } from 'sen/components/lib/dialogs/newIncidentDialog/NewIncidentDialogWizard'
import { getRecipients, resetSearchForIncidentEmails } from 'sen/redux/features/remediation/remediationSlice'

export interface MultiStepConfig {
  activeStep: number
  gotoConfirmIncident: boolean
  onNextStep: (activeStep: number, currentPage?: string, options?: string) => void
  onPrevStep: (activeStep: number, currentPage?: string, options?: string) => void
}

export interface ButtonStatus {
  disabled?: boolean | false
  disableNext?: boolean | false
  cancel?: string | false
  onPrev?: string | false
  onNext?: string | false
  onNextStep: (activeStep: number, options?: string) => void
  onPrevStep: (activeStep: number, options?: string) => void
}

export const recipientLimitInEmailsTable = 2
export const recipientLimitInEmailPreview = 2

export default function useNewIncidentDialogWizardLogic(): [MultiStepConfig] {
  const {
    accessTokenId,
    totalIncidentEmails,
    searchForIncidentEmailsIsLoaded,
    createIncidentSuccess,
    createIncident,
    getRecipientsSuccess
  } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken.accessToken?.id,
    totalIncidentEmails: _stores.remediation?.incidentsEmails?.report?.totalCount,
    searchForIncidentEmailsIsLoaded: isSuccess(_stores.remediation?.searchForIncidentEmailsApiStatus),
    createIncidentSuccess: isSuccess(_stores.remediation?.searchForIncidentEmailsApiStatus),
    createIncident: _stores.remediation?.currentIncident,
    getRecipientsSuccess: isSuccess(_stores.remediation.getRecipientsApiStatus)
  }))
  const newIncidentDialogWizardContext = useContext(NewIncidentDialogWizardContext)
  const dialogActions = useRef<HTMLDivElement>(null)
  const dispatch = useAppDispatch()

  // listen for successful email incident search and transition to incident confirmation table
  useEffect(() => {
    if (
      searchForIncidentEmailsIsLoaded &&
      totalIncidentEmails &&
      !newIncidentDialogWizardContext.confirmIncidentState.gotoConfirmIncident
    ) {
      newIncidentDialogWizardContext.setConfirmIncidentState({ gotoConfirmIncident: true })
    }
  }, [searchForIncidentEmailsIsLoaded, totalIncidentEmails, newIncidentDialogWizardContext])

  // action buttons
  const onNextStep = useCallback(
    (currentStep: number, currentPage?: string, options?: string) => {
      switch (currentStep) {
        case 1: {
          analyticsLib.trackAppEvent(analyticsLib.EVENTS.ACCOUNT_COMPROMISE_INCIDENT_WIZARD_NEXT, {
            accessTokenId,
            url: window.location.href,
            currentStep,
            currentPage,
            options
          })

          console.log('options', options, currentStep, currentPage)
          // Skip quarantine and notify and go straight to inbox rules
          if (options === 'gotoInboxRules') {
            newIncidentDialogWizardContext.setStepState({
              activeStep: newIncidentDialogWizardContext.stepState.activeStep + 3
            })
          }
          if (options === 'gotoQuarantine') {
            if (createIncidentSuccess && !!createIncident?.id && getRecipientsSuccess) {
              newIncidentDialogWizardContext.setStepState({
                activeStep: newIncidentDialogWizardContext.stepState.activeStep + 1
              })
            }
          }
          break
        }
        case 2: {
          analyticsLib.trackAppEvent(analyticsLib.EVENTS.ACCOUNT_COMPROMISE_INCIDENT_WIZARD_NEXT, {
            accessTokenId,
            url: window.location.href,
            currentStep,
            currentPage,
            incidentId: createIncident?.id
          })

          dispatch(getRecipients({ incidentId: createIncident?.id || '', distinctRecipient: true, wizardTable: true }))

          if (getRecipientsSuccess) {
            newIncidentDialogWizardContext.setStepState({
              activeStep: newIncidentDialogWizardContext.stepState.activeStep + 1
            })
          }
          break
        }

        default: {
          analyticsLib.trackAppEvent(analyticsLib.EVENTS.ACCOUNT_COMPROMISE_INCIDENT_WIZARD_NEXT, {
            accessTokenId,
            url: window.location.href,
            currentStep,
            currentPage,
            incidentId: createIncident?.id
          })
          newIncidentDialogWizardContext.setStepState({
            activeStep: newIncidentDialogWizardContext.stepState.activeStep + 1
          })
          break
        }
      }
    },
    [
      accessTokenId,
      newIncidentDialogWizardContext,
      createIncident,
      createIncidentSuccess,
      getRecipientsSuccess,
      dispatch
    ]
  )

  const onPrevStep = useCallback(
    (currentStep: number, currentPage?: string) => {
      switch (currentStep) {
        case 1:
          analyticsLib.trackAppEvent(analyticsLib.EVENTS.ACCOUNT_COMPROMISE_WIZARD_BACK, {
            accessTokenId,
            url: window.location.href,
            currentStep,
            currentPage
          })
          if (newIncidentDialogWizardContext.confirmIncidentState.gotoConfirmIncident) {
            // reset the new incident form so we don't collide with the useEffect above
            dispatch(resetSearchForIncidentEmails())

            newIncidentDialogWizardContext.setConfirmIncidentState({
              gotoConfirmIncident: false
            })
            break
          } else {
            newIncidentDialogWizardContext.setStepState({
              activeStep: newIncidentDialogWizardContext.stepState.activeStep - 1
            })
            break
          }
        default:
          analyticsLib.trackAppEvent(analyticsLib.EVENTS.ACCOUNT_COMPROMISE_WIZARD_BACK, {
            accessTokenId,
            url: window.location.href,
            currentStep,
            currentPage
          })
          newIncidentDialogWizardContext.setStepState({
            activeStep: newIncidentDialogWizardContext.stepState.activeStep - 1
          })
          break
      }
    },
    [newIncidentDialogWizardContext, dispatch, accessTokenId]
  )

  return useMemo(() => {
    return [
      {
        activeStep: newIncidentDialogWizardContext.stepState.activeStep,
        gotoConfirmIncident: newIncidentDialogWizardContext.confirmIncidentState.gotoConfirmIncident,
        dialogActions,
        onNextStep,
        onPrevStep
      }
    ]
  }, [newIncidentDialogWizardContext, dialogActions, onNextStep, onPrevStep])
}
