import { useMemo, useEffect, useCallback } from 'react'

import { extendProductTrial, resetExtendProductTrial } from 'global/redux/features/account/accountSlice'
import { Products } from 'global/types/productsType'
import { ProductAssigment, ProductLicense } from 'global/types/api/product'
import { isFailed, isPending } from 'global/redux/toolkit/api'

import { useAppDispatch, useAppSelector } from 'admin/redux/toolkit/hooks'
import {
  getProductAssignments,
  getProductLicenses,
  resetGetProductAssiments,
  resetGetProductLicenses
} from 'admin/redux/features/admin/adminSlice'
import { ListAccessToken } from 'admin/redux/types/scans'

export type TokenDialogLogic = {
  error: boolean
  isLoading: boolean
  onExtendProductTrial: () => void
  productAssigment: ProductAssigment
  productLicense: ProductLicense
}

export default function useTokenDialogLogic(accessToken: ListAccessToken, product: Products): [TokenDialogLogic] {
  const {
    extendProductFailed,
    extendProductInProgress,
    getProductAssignmentFailed,
    getProductAssigmentInProgress,
    getProductLicenseFailed,
    getProductLicenseInProgress,
    productAssigment,
    productLicense
  } = useAppSelector(_stores => ({
    extendProductFailed: isFailed(_stores.account.extendProductTrialApiStatus),
    extendProductInProgress: isPending(_stores.account.extendProductTrialApiStatus),
    getProductAssignmentFailed: isFailed(_stores.admin.getProductAssignmentsApiStatus),
    getProductAssigmentInProgress: isPending(_stores.admin.getProductAssignmentsApiStatus),
    getProductLicenseFailed: isFailed(_stores.admin.getProductLicensesApiStatus),
    getProductLicenseInProgress: isPending(_stores.admin.getProductLicensesApiStatus),
    productAssigment: _stores.admin.productAssigment,
    productLicense: _stores.admin.productLicense
  }))

  const dispatch = useAppDispatch()
  // Init
  useEffect(() => {
    dispatch(
      getProductAssignments({
        accessTokenId: accessToken.id,
        product
      })
    )
    dispatch(
      getProductLicenses({
        accountId: accessToken.accountId,
        product
      })
    )
    // unmount
    return () => {
      dispatch(resetGetProductAssiments())
      dispatch(resetGetProductLicenses())
      dispatch(resetExtendProductTrial())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onExtendProductTrial = useCallback(() => {
    dispatch(
      extendProductTrial({
        accountId: accessToken.accountUuid,
        accessTokenId: accessToken.accessTokenId,
        product
      })
    )
  }, [dispatch, accessToken, product])

  return useMemo(() => {
    const isLoading = getProductAssigmentInProgress || getProductLicenseInProgress || extendProductInProgress
    const error = getProductAssignmentFailed || getProductLicenseFailed || extendProductFailed
    return [
      {
        error,
        isLoading,
        onExtendProductTrial,
        productAssigment,
        productLicense
      }
    ]
  }, [
    extendProductFailed,
    extendProductInProgress,
    getProductAssigmentInProgress,
    getProductAssignmentFailed,
    getProductLicenseFailed,
    getProductLicenseInProgress,
    onExtendProductTrial,
    productAssigment,
    productLicense
  ])
}
