import { useMemo, useEffect } from 'react'

import { process } from '@progress/kendo-data-query'

import { BDSGridPagerConfig, BDSGridSortConfig } from 'global/types/dataTables/dataTables'
import { formatDateWithTime, isBeforeDate, luxonDate } from 'global/lib/datetime'
import { isPending } from 'global/redux/toolkit/api'

import {
  getThreatsByUsernameReport,
  resetThreatsByUsernameReport
} from 'ets/redux/features/reports/threatsByUsername/threatsByUsernameSlice'
import { update as updateUserEmailsTable } from 'ets/redux/features/dataTables/userEmails/userEmailsSlice'
import { useAppDispatch, useAppSelector } from 'ets/redux/toolkit/hooks'
import { Threat } from 'ets/types/threatTypes'

export interface UserEmailsDialogLogicProps {
  onSeeDetails: (threatId: string) => void
}

export interface ModifiedThreat extends Threat {
  formattedDate: string
  seeDetails: UserEmailsDialogLogicProps['onSeeDetails']
}

export interface UserEmailsDialogLogic {
  tableData: {
    total: number
    data: ModifiedThreat[]
  }
  inProgress: boolean
  pageConfig: BDSGridPagerConfig
  sortConfig: BDSGridSortConfig
  isViewEmailsDisabled: boolean
}

export default function useUserEmailsDialogLogic({
  onSeeDetails
}: UserEmailsDialogLogicProps): [UserEmailsDialogLogic] {
  const dispatch = useAppDispatch()
  const { finishedOn, inProgress, isReportLoaded, loadedOffsets, reportData, userEmailsTable } = useAppSelector(
    _stores => ({
      finishedOn: _stores.scan.stats.finishedOn,
      userEmailsTable: _stores.dataTables.userEmails,
      reportData: _stores.reports.threatsByUsername.data,
      isReportLoaded: !!_stores.reports.threatsByUsername?.data?.accessTokenId,
      inProgress: isPending(_stores.reports.threatsByUsername.apiStatus),
      loadedOffsets: _stores.reports.threatsByUsername.loadedOffsets
    })
  )

  const MAX_SCAN_RETENTION = 90

  const isViewEmailsDisabled = useMemo(() => {
    const maxRetention = luxonDate()
      .minus({ days: MAX_SCAN_RETENTION })
      .toISO()
    return isBeforeDate({ initialDate: finishedOn, subtractedDate: maxRetention })
  }, [finishedOn])

  const tableData = useMemo(() => {
    const { skip, take } = userEmailsTable

    const processedData = process(
      (reportData?.report.data || []).map((report: Threat) => ({
        ...(report && {
          ...report,
          formattedDate: formatDateWithTime(report.date),
          seeDetails: onSeeDetails
        })
      })),
      { skip, take }
    )

    return {
      ...processedData,
      data: processedData.data.filter((report: ModifiedThreat) => report.id)
    }
  }, [reportData, onSeeDetails, userEmailsTable])

  const pageConfig: BDSGridPagerConfig = useMemo(() => {
    const { skip, take }: { skip: number; take: number } = userEmailsTable

    return {
      pageable: !!reportData?.report.totalCount && userEmailsTable.take < reportData.report.totalCount,
      skip,
      take,
      total: reportData?.report.totalCount || 0,
      onPageChange: e => {
        dispatch(updateUserEmailsTable(e.page))

        if (!loadedOffsets?.includes(e.page.skip)) {
          dispatch(getThreatsByUsernameReport())
        }
      }
    }
  }, [userEmailsTable, dispatch, reportData, loadedOffsets])

  const sortConfig: BDSGridSortConfig = useMemo(() => {
    return {
      sortable: {
        allowUnsort: false,
        mode: 'single'
      },
      sort: userEmailsTable.sort,
      onSortChange: e => {
        dispatch(updateUserEmailsTable({ sort: e.sort }))
      }
    }
  }, [userEmailsTable, dispatch])

  // on sortChange
  useEffect(() => {
    if (isReportLoaded) {
      dispatch(
        updateUserEmailsTable({
          skip: 0
        })
      )
      dispatch(resetThreatsByUsernameReport())
      dispatch(getThreatsByUsernameReport())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEmailsTable.sort, dispatch])

  // set the table height for the amount of records
  useEffect(() => {
    if (!inProgress) {
      const elem = (document.getElementsByClassName('user-emails-table') as HTMLCollectionOf<HTMLElement>)[0]
      if (elem) {
        elem.style.height = `${elem.clientHeight}px`
      }
    }
  }, [inProgress])

  return useMemo(
    () => [
      {
        inProgress,
        isViewEmailsDisabled,
        pageConfig,
        sortConfig,
        tableData
      }
    ],
    [isViewEmailsDisabled, tableData, inProgress, pageConfig, sortConfig]
  )
}
