/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import {
  Typography,
  Button,
  Alert,
  Dialog,
  DialogContent,
  DialogActions,
  Zoom,
  Grid
} from '@barracuda-internal/bds-core'

import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import DEFAULTS from 'global/configs/theme/defaults'
import { useErrorFormatMessage, useFormatMessage } from 'global/lib/localization'
import { config } from 'global/lib/config'
import { Products } from 'global/types/productsType'
import { formatDate } from 'global/lib/datetime'

import styles from 'admin/components/lib/dialogs/tokenDialog/tokenDialogStyles'
import { ListAccessToken } from 'admin/redux/types/scans'
import useTokenDialogLogic from 'admin/components/lib/dialogs/tokenDialog/useTokenDialogLogic'

export interface TokenDialogProps {
  accessToken: ListAccessToken
  product: Products
  onClose: () => void
}

const BASE_I18N_KEY = 'admin.app.dialogs.token_dialog'

export const TokenDialog: React.FC<TokenDialogProps> = ({ accessToken, product, onClose }) => {
  const [{ error, isLoading, onExtendProductTrial, productAssigment, productLicense }] = useTokenDialogLogic(
    accessToken,
    product
  )
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatErrorMessage = useErrorFormatMessage()
  const classes = styles()

  return useMemo(
    () => (
      <Dialog
        style={{ zIndex: DEFAULTS.SECONDARY_DIALOGS_ZINDEX }}
        maxWidth="md"
        fullWidth
        open
        TransitionComponent={Zoom}
        data-field="dialog"
      >
        {error && (
          <Alert data-field="error" variant="filled" severity="error">
            {formatErrorMessage('default')}
          </Alert>
        )}
        {isLoading && <LinearProgress data-field="loader" />}
        {!isLoading && (
          <DialogContent data-field="dialog-content">
            <Typography className={classes.title} data-field="dialog-title">
              {formatMessage('title')}
            </Typography>
            <Grid className={classes.contentRow}>
              <Typography className={classes.label} data-field="dialog-product-enable-label">
                {formatMessage('product_enable_date')}:{' '}
              </Typography>
              <Typography className={classes.value} data-field="dialog-product-enable-value">
                {formatDate(productAssigment.updatedOn, config.DATETIME.DATE_WITH_TIME_FORMAT_WITHOUT_AT)}
              </Typography>
            </Grid>
            <Grid className={classes.contentRow}>
              <Typography className={classes.label} data-field="dialog-serial-label">
                {formatMessage('serial_number')}:{' '}
              </Typography>
              <Typography className={classes.valueIcon} data-field="dialog-serial-value">
                {productLicense?.serialNumber
                  ? productLicense.serialNumber
                  : productLicense?.substate === config.PRODUCTS.SUBSTATES.TRIAL
                  ? formatMessage('trial')
                  : formatMessage('add_serial_number')}
              </Typography>
            </Grid>
            <Grid className={classes.contentRow}>
              <Typography className={classes.label} data-field="dialog-expire-label">
                {formatMessage('serial_expire')}:{' '}
              </Typography>
              <Typography className={classes.value} data-field="dialog-expire-value">
                {productLicense?.expiry
                  ? formatDate(productLicense.expiry, config.DATETIME.DATE_WITH_TIME_FORMAT_WITHOUT_AT)
                  : 'N/A'}
                {productLicense?.substate === config.PRODUCTS.SUBSTATES.TRIAL ? (
                  <span
                    className={classes.link}
                    role="presentation"
                    data-field="dialog-expire-action"
                    onClick={onExtendProductTrial}
                  >
                    {' '}
                    {formatMessage('extend_trial')}
                  </span>
                ) : null}
              </Typography>
            </Grid>
            <Grid className={classes.contentRow}>
              <Typography className={classes.label} data-field="dialog-office-name-label">
                {formatMessage('office_365_name')}:{' '}
              </Typography>
              <Typography className={classes.value} data-field="dialog-office-name-value">
                {accessToken.accessTokenName}
              </Typography>
            </Grid>
            <Grid className={classes.contentRow}>
              <Typography className={classes.label} data-field="dialog-office-app-label">
                {formatMessage('office_365_app')}:{' '}
              </Typography>
              <Typography className={classes.value} data-field="dialog-office-app-value">
                {accessToken?.providerApp}
              </Typography>
            </Grid>
            <Grid className={classes.contentRow}>
              <Typography className={classes.label} data-field="dialog-account-label">
                {formatMessage('account_id')}:{' '}
              </Typography>
              <Typography className={classes.value} data-field="dialog-account-value">
                {accessToken.accountUuid}
              </Typography>
            </Grid>
            <Grid className={classes.contentRow}>
              <Typography className={classes.label} data-field="dialog-token-label">
                {formatMessage('access_token_id')}:{' '}
              </Typography>
              <Typography className={classes.value} data-field="dialog-token-value">
                {accessToken.accessTokenId}
              </Typography>
            </Grid>
            <Grid className={classes.contentRow}>
              <Typography className={classes.label} data-field="dialog-bcc-id-label">
                {formatMessage('bcc_account_id')}:{' '}
              </Typography>
              <Typography className={classes.value} data-field="dialog-bcc-id-value">
                {accessToken.bccId}
              </Typography>
            </Grid>
            <Grid className={classes.contentRow}>
              <Typography className={classes.label} data-field="dialog-bcc-name-label">
                {formatMessage('bcc_account_name')}:{' '}
              </Typography>
              <Typography className={classes.value} data-field="dialog-bcc-name-value">
                {accessToken.bccName}
              </Typography>
            </Grid>
          </DialogContent>
        )}
        <DialogActions className={classes.actions}>
          <Button color="primary" variant="contained" size="large" onClick={onClose} data-field="dialog-dismiss-button">
            &nbsp;&nbsp;{formatMessage('dismiss')}&nbsp;&nbsp;
          </Button>
        </DialogActions>
      </Dialog>
    ),
    [
      accessToken,
      classes,
      error,
      formatErrorMessage,
      formatMessage,
      isLoading,
      onClose,
      onExtendProductTrial,
      productAssigment,
      productLicense
    ]
  )
}

TokenDialog.propTypes = {
  accessToken: PropTypes.any.isRequired,
  product: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired
}

export default TokenDialog
