import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Dialog as MuiDialog } from '@barracuda-internal/bds-core'

import DEFAULTS from 'global/configs/theme/defaults'
import dialogStyles from 'global/components/lib/dialogs/dialogStyles'

const Dialog = withStyles(() => ({
  paper: {}
}))(MuiDialog)

export { Dialog }

export default makeStyles(theme => {
  const defaultDialogStyles = dialogStyles(theme)

  return {
    ...defaultDialogStyles,
    loadingIndicator: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0
    },
    error: {
      marginBottom: theme.spacing(2)
    },
    subtitle: {
      margin: theme.spacing(1, 0),
      lineHeight: '18px'
    },
    formControl: {
      width: '100%'
    },
    helpLabel: {
      fontSize: 12
    },
    inputLabel: {
      fontSize: 12,
      color: theme.palette.text.secondary
    },
    popper: {
      zIndex: DEFAULTS.SECONDARY_DIALOGS_ZINDEX
    },
    senderSelector: {
      marginBottom: theme.spacing(2)
    },
    filterAttacksButton: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    subject: {
      marginTop: theme.spacing(4),
      width: '100%'
    },
    subjectTitle: {
      color: theme.palette.text.secondary
    },
    body: {
      width: '100%',
      marginTop: theme.spacing(1)
    },
    frameContent: {
      overflow: 'scroll',
      padding: theme.spacing(1),
      width: '100%',
      border: `1px solid ${theme.palette.text.secondary}`,
      height: 200,
      whiteSpace: 'pre-wrap'
    }
  }
})
