import React from 'react'

import { Typography } from '@barracuda-internal/bds-core'

import { useFormatMessage } from 'global/lib/localization'
import styles from 'sen/components/lib/noPermission/noPermissionStyles'

const BASE_I18N_KEY = 'sen.app.no_permission'
type NoPermissionsProps = { product: string }

const NoPermission: React.FC<NoPermissionsProps> = ({ product = 'sen' }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()

  return (
    <Typography className={classes.text} data-field="text" align="center">
      {formatMessage(`${product}_text`)}
    </Typography>
  )
}

export default NoPermission
