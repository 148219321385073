import globalMessages, {
  ADD_PERMISSION,
  BACK,
  BARRACUDA_EMAIL_PROTECTION,
  CANCEL,
  CLOSE,
  CONTINUE,
  DATE,
  DEFAULT_ERROR,
  DFP_APP_NAME,
  EMAIL_ERROR,
  FROM,
  LINKING_CODE_INVALID,
  MICROSOFT_365,
  MORE_DETAILS,
  NAME,
  NEXT,
  NO,
  NO_PERMISSIONS,
  RECIPIENT,
  REPORT_FALSE_POSITIVE,
  SAVE,
  SEARCH,
  SEN_APP_NAME,
  SERIAL_NUMBER_INVALID,
  SIGN_IN,
  SKIP,
  START_TRIAL,
  SUBJECT,
  SUBMIT,
  TEST,
  UNKNOWN,
  YES
} from 'global/lib/i18n/en'

const DISMISS = 'Dismiss'
const NO_ATTACKS_FOUND = 'No attacks found'
const SELECT_RECIPIENT = 'Select recipient'

// inbox rules
const SEQUENCE = 'Sequence'
const ACTIONS = 'Actions'
const CONDITIONS = 'Conditions'
const EXCEPTIONS = 'Exceptions'
const ENABLED = 'Enabled'
const NO_INBOX_RULES = 'No inbox rules found'

export const PERMISSION_DIALOG_TITLE = `Why are so many permissions needed to run ${SEN_APP_NAME}?`
export const NO_EMPLOYEES_MATCHING = 'No employees matching "{email}"'
export const AUTOCOMPLETE_PLACEHOLDER = 'Name or email of account'
export const CHECK_MY_SPF = 'Check my SPF'
export const COPIED = 'Copied'
export const CHECK_DMARC_AGAIN = 'Check DMARC again'
export const LAST_30_DAYS = 'Last 30 days'

/* eslint-disable @typescript-eslint/naming-convention, quotes */
export default {
  ...globalMessages,
  sen: {
    http: {
      default: DEFAULT_ERROR
    },
    navbar: {
      manage_users_dialog: {
        title: `Manage users who have access to ${SEN_APP_NAME}`,
        subtitle: `To manage users who have access to your ${SEN_APP_NAME} account use Barracuda Cloud Control:`,
        step_1: 'Go to <bccLink></bccLink> and sign in.',
        step_2: 'Click <b>Home > Admin > Users.</b>',
        step_3: 'Click <b>Add users</b> or select an existing user and click <b>Remove user.</b>',
        step_4: `Add ${SEN_APP_NAME} entitlements to all users that need access to your dashboard. <entitlementsLink>Learn more</entitlementsLink>.`,
        footer: 'When adding users from another Barracuda account, you might need to contact Barracuda support.',
        here: 'here',
        dismiss: DISMISS
      }
    },
    side_menu: {
      dashboard: 'Spear phishing protection',
      account_takeover: 'Account takeover protection',
      domain_fraud: 'Domain fraud protection',
      forensics_and_menu_response: 'Incident Response',
      unified_reporting_root: 'Reports',
      unified_reporting: 'Report list',
      scheduled_reports: 'Scheduled reports'
    },
    login: {
      barracuda_product: SEN_APP_NAME,
      product_blurb: 'Get AI-based protection from phishing and account takeover.',
      signup_description: `${SEN_APP_NAME} detects threats that traditional email security systems can't. It integrates directly with Microsoft Office 365 APIs to detect attacks coming from both internal and external sources. It uses Artificial Intelligence to detect signs of malicious intent and deception within every email with virtually no IT administration required.`,
      form: {
        already_using: `Already using ${SEN_APP_NAME}? <signin>${SIGN_IN}</signin>`,
        permission_dialog_title: PERMISSION_DIALOG_TITLE
      }
    },
    domain_fraud_signup: {
      title: 'Set up DMARC reporting to prevent fraud and protect your brand from domain spoofing',
      form_title: 'Create a Barracuda account',
      complete_form_title: 'Complete your Barracuda account',
      button: 'Sign up',
      profile_button: CONTINUE,
      have_account: 'I already have a Barracuda account',
      privacy_policy: 'By clicking <b>Sign up</b>, you agree to our <policy>Privacy policy</policy>.',
      placeholders: {
        name: 'First and last name',
        company: 'Company',
        country: 'Country',
        email: 'Your email',
        password: 'Choose a password',
        phone: 'Phone number',
        zip: 'Zip code'
      },
      form_errors: {
        weak_password:
          'Your password needs to have: <li>- 8 characters</li><li>- 1 capital letter</li><li>- 1 digit</li>',
        email_error: EMAIL_ERROR
      },
      footer_text: 'Use another account'
    },
    signup_remediates: {
      title: `Choose how ${SEN_APP_NAME} remediates spear phishing attacks and other fraud`,
      continue: CONTINUE,
      app_name: SEN_APP_NAME
    },
    signin_connect: {
      app_name: SEN_APP_NAME,
      title: `Connect to ${MICROSOFT_365} to add ${SEN_APP_NAME}`,
      permission_dialog_title: PERMISSION_DIALOG_TITLE,
      subtitle_1: `Secure your account in real-time with ${SEN_APP_NAME}. You must have an ${MICROSOFT_365} global administrator account to authorize.`,
      subtitle_2:
        'After you sign in to your Microsoft account, accept the application <perm>permissions</perm> to continue.',
      o365_button: `Connect to ${MICROSOFT_365}`,
      footer_text: 'Use another account',
      account_want_to_connect: 'Account to connect'
    },
    dashboard: {
      scan_in_progress: {
        title: 'Preparing to protect your account from spear phishing and fraud',
        subtitle:
          "Our artificial intelligence is now learning your communication patterns so it can detect fraud. You will receive an email to <bold>{email}</bold> when we're ready to protect your company.<br>Depending on the number of emails in your account, it may take a few hours or up to a day.</br>"
      },
      header: {
        report_missed_attack: 'Report missed attack',
        report_missed_attack_dialog: {
          title: 'Report a missed attack',
          content:
            'If you received a suspicious email, please forward it as an EML file to <email></email>, following <instructions>these instructions.</instructions>',
          sub_content: 'We will send you a confirmation when we receive your report.',
          button: CLOSE
        },
        test_ai: 'Test AI'
      },
      tabs: {
        recent_attacks: 'Recent attacks',
        reports: 'Reports'
      },
      reports: {
        title: 'Trends',
        subtitle: `Attacks detected by ${SEN_APP_NAME} over time`,
        interval: 'Reports updated every 20 minutes',
        menuItem: 'Last {days} days',
        oops: 'Unable to load this chart.',
        top_recipients: 'Top recipients of fraudulent emails',
        impersonated_senders: 'Impersonated senders',
        top_fraud: 'Top fraud sending domains',
        services_impersonated: 'Impersonated services',
        popular_subjects: 'Popular subjects used in fraud',
        types_of_fraud: 'Types of fraud',
        no_data: 'No current data',
        tooltips: {
          attacks_per_day: 'Attacks:',
          impersonations: 'Impersonations:',
          count: 'Count:'
        },
        service_impersonation: 'Service impersonation',
        employee_impersonation: 'Employee impersonation'
      },
      recent_attacks: {
        title: 'Protection status for {accountName}',
        card_1: {
          title: 'Email threats',
          subtitle: 'Last 6 months, updated daily',
          count_0_title: '{count, plural, =0 {Threats} one {Threat} other {Threats}} protected',
          count_1_title: 'False positives',
          count_2_title: 'Total emails scanned',
          date_title: 'Last email processed:',
          link_title: 'View real-time phishing emails'
        },
        card_2: {
          title: 'Account takeover',
          subtitle: 'Last {timeframe} days',
          count_title: 'Outstanding {count, plural, =0 {alerts} one {alert} other {alerts}}',
          date_title: 'Latest alert:',
          link_title: 'View account takeover alerts'
        },
        card_3: {
          title: 'Domains',
          count_0_title: 'Total',
          count_1_title: 'Reporting mode',
          count_2_title: 'Enforcement mode',
          link_title: 'View DMARC reporting'
        },
        card_4: {
          title: 'Licensing information',
          count_title: `Licensed ${MICROSOFT_365} {count, plural, =0 {Mailboxes} one {Mailbox} other {Mailboxes}}`,
          date_title: 'Serial number:',
          link_title: 'View mailboxes'
        },
        sp_title: 'Spear phishing attacks',
        sp_subtitle: 'Emails in your account that have been identified as fraud attempts',
        search_field: {
          placeholder: SEARCH
        },
        more_details: MORE_DETAILS,
        report_false_positive: REPORT_FALSE_POSITIVE,
        button: CLOSE,
        subject: SUBJECT
      }
    },
    account_takeover: {
      permission_banner: {
        title: `${SEN_APP_NAME} does not currently have all the permissions it needs to detect account takeover incidents.`,
        subtitle: 'After adding permissions, allow up to 1 hour for permissions to propagate throughout the system.',
        button: ADD_PERMISSION
      },
      overview: {
        card_1_text: 'outstanding {count, plural, =0 {alerts} one {alert} other {alerts}} (in the last 30 days)',
        card_2_text: '{count, plural, =0 {incidents} one {incident} other {incidents}} created',
        card_3_text: 'latest alert',
        new_incident_button: 'New incident'
      },
      tabs: {
        alerts: 'Alerts',
        incidents: 'Incidents',
        inbox_rules: 'Inbox rules',
        sign_ins: 'Sign ins'
      },
      alerts: {
        title: 'Alerts',
        subtitle: "Account takeover incidents detected by Impersonation Protection's AI"
      },
      incidents: {
        title: 'Incidents',
        subtitle: 'Account takeover incidents previously reported'
      },
      incident: {
        title: '<link>Incidents</link> > <b>{name} on {date}</b>',
        reported: 'Reported',
        by: 'By',
        impact: 'Impact',
        compromised: 'Compromised',
        subject: 'Subject',
        search_timeframe: 'Search timeframe',
        impact_text:
          '{internal} internal {internal, plural, =0 {attacks} one {attack} other {attacks}}, {external} external {external, plural, =0 {attacks} one {attack} other {attacks}}',
        search_in_progress: 'Search for malicious emails in progress...',
        tabs: {
          internal_attacks: 'Internal attacks',
          external_recipients: 'External recipients'
        }
      },
      inbox_rules: {
        title: 'Investigate mailbox',
        subtitle: 'View the rules currently set on a particular mailbox',
        view_inbox_rules: 'View inbox rules',
        feed_title: 'Inbox rules change feed',
        feed_subtitle: 'View recent changes of rules to spot unusual activity, updated every 60 minutes',
        missed_permission: `Inbox rules are often used by attackers to cover their tracks or take advantage of the accounts they take over.<br/></br/>To allow ${SEN_APP_NAME} to access inbox rules, click 'Add permissions' and follow the instructions.`,
        add_permission: ADD_PERMISSION
      },
      signins: {
        map_title: 'Sign ins map',
        number_of_signins: 'Number of sign ins',
        subtitle: 'View sign ins to a particular account for past 30 days',
        table_title: 'Sign ins by country',
        title: 'Investigate account',
        view_signins: 'View sign ins'
      }
    },
    domain_fraud: {
      title: 'Domains',
      subtitle: 'Gather intelligence and protect your domain identity with DMARC.',
      ready_to_enforce: {
        title: 'Next step: enforcing DMARC',
        subtitle_1:
          'Based on your efforts, the following domains meet the criteria for DMARC enforcement (no unknown high volume senders and minimal DMARC failures):',
        subtitle_2: 'For each domain, click Enforce DMARC to take the next step in the protection process.'
      },
      add_domain_button: 'Add domain for DMARC reporting'
    },
    domain_fraud_dmarc_review: {
      back_to_domains: 'Back to domains',
      sources: {
        title: 'Sources identified by DMARC',
        subtitle: LAST_30_DAYS,
        tabs: {
          unknown: UNKNOWN,
          unknown_subtitle: 'Unidentified sources that have sent email impersonating your domain',
          approved: 'Approved',
          approved_subtitle: 'Identified sources allowed to send email impersonating your domain',
          rejected: 'Not approved',
          rejected_subtitle: 'Sources identified as not allowed to impersonate your domain'
        },
        tab_contents: {
          unknown: {
            information_title: 'Identify sources',
            information_subtitle:
              'In the last 30 days, DMARC reporting detected the following sources to have sent emails impersonating your domain. Some of these sources might be legitimate services your organization is using and others might be malicious spoof attempts. Go through this list and approve sources that are legitimate and mark those sources that are not.',
            search_placeholder: 'Search for a sender',
            high_volume_sources: 'High volume sources ({count})',
            low_volume_sources: 'Low volume sources ({count})',
            mark_all_as_rejected: 'Mark all {count} as not approved',
            investigate: 'Investigate',
            mark_as_rejected: 'Mark as not approved',
            mark_as_approved: 'Mark as approved',
            undo: 'Undo',
            visit_source: 'Visit source',
            marked_as: 'marked as {status}',
            progress_status_approved: 'an approved source',
            progress_status_rejected: 'not approved'
          },
          approved: {
            information_title: 'Configure SPF and DKIM on approved sources',
            information_subtitle_1:
              'You have identified the sources below as allowed to send email that impersonates your domain. If SPF and DKIM are failing authentication (marked with red), make sure to investigate the failures and use the setup instructions. If there are no setup instructions available, reach out to the administrator or support team of the email source.',
            information_subtitle_2:
              'After you set up SPF and/or DKIM for a source, monitor the data for a period of time to make sure the changes were done correctly and that your SPF and/or DKIM failures are becoming less frequent. In general, you only need either SPF or DKIM to pass to have DMARC pass, but you will have fewer DMARC failures if you are able to configure both SPF and DKIM and have the majority of emails both pass and align.',
            search_placeholder: 'Search for a sender',
            investigate: 'Investigate',
            mark_as_rejected: 'Mark as not approved',
            passed: 'passed',
            failed: 'failed'
          },
          rejected: {
            search_placeholder: 'Search for a sender',
            mark_as_approved: 'Mark as approved'
          }
        },
        low_volume: 'Low volume',
        high_volume: 'High volume',
        sources_failing: 'Sources failing DMARC'
      },
      top_countries: {
        title: 'Top countries sending email',
        subtitle: LAST_30_DAYS,
        table: {
          country: 'Country',
          reports: 'Reports',
          empty_table: 'No countries'
        },
        view_map: 'View map',
        load_more: '{count} more {count, plural, one {country} other {countries}}'
      },
      your_dns: {
        title: 'Your DNS configuration',
        enforce_dmarc_button: 'Enforce DMARC',
        change_to_reporting_button: 'Change to DMARC reporting only',
        domain_name_is_required: 'Domain name is required',
        blocks: {
          api_fetch_in_progress: 'Fetching the record is in progress',
          api_fetch_error: 'An error occurred while fetching the record',
          no_record: 'No record',
          not_configured: 'Not configured',
          dmarc: {
            title: 'DMARC'
          },
          spf: {
            title: 'SPF'
          },
          dkim: {
            title: 'DKIM',
            header: 'Configured on these high volume senders:',
            not_configured: 'Not configured for any of your high volume senders'
          }
        }
      }
    },
    start_trial: {
      activate: {
        activate_button: `Activate ${SEN_APP_NAME}`,
        activate_header: `Activate ${SEN_APP_NAME}`,
        linking_code: 'Linking code',
        linking_code_error: LINKING_CODE_INVALID,
        serial_number: 'Serial number',
        serial_number_error: SERIAL_NUMBER_INVALID
      },
      already_purchased: `I have already purchased ${SEN_APP_NAME}`,
      barracuda: BARRACUDA_EMAIL_PROTECTION,
      product: SEN_APP_NAME,
      subtitle: 'Get AI-based protection from phishing and account takeover.',
      product_description: `${SEN_APP_NAME} detects threats that traditional email
          security systems can't. It integrates directly with Microsoft Office
          365 APIs to detect attacks coming from both internal and external
          sources. It uses artificial intelligence to detect signs of malicious
          intent and deception within every email with virtually no IT
          administration required.`,
      try_free: 'Try <b>Email Protection Premium Plus</b> trial for FREE for 14-days.',
      no_credit_card: 'No credit card is required.',
      start_trial: START_TRIAL,
      use_another_account: 'Use another account'
    },
    no_permission: {
      sen_text: `You do not currently have permissions to access the ${SEN_APP_NAME} dashboard for this ${MICROSOFT_365} tenant. Please reach out to your Barracuda account admin to add the necessary entitlements.  These permissions are available in Barracuda Cloud Control under Home / Admin / Users / Product Entitlements/ ${SEN_APP_NAME}`,
      dfp_text: `You do not currently have permissions to access the ${DFP_APP_NAME} dashboard for this ${MICROSOFT_365} tenant. Please reach out to your Barracuda account admin to add the necessary entitlements.  These permissions are available in Barracuda Cloud Control under Home / Admin / Users / Product Entitlements/ ${DFP_APP_NAME}`
    },
    new_incident_dialog: {
      buttons: {
        cancel: CANCEL,
        close: CLOSE,
        next: NEXT,
        back: BACK,
        skip: SKIP,
        continue: CONTINUE,
        skip_step: 'Skip this step',
        no_malicious_emails: 'No malicious emails',
        yes_malicious_emails: 'Yes, all are malicious',
        clean_up: 'Clean up',
        send_notifications: 'Send notifications',
        reset_password: 'Reset password',
        add_permission: ADD_PERMISSION
      },
      wizard_title: 'New account takeover incident',
      step_block_account: {
        title: "Manually block attacker's access",
        subtitle: `Sign into ${MICROSOFT_365} and complete the following actions to prevent an attacker from accessing the compromised account:`,
        list_disable: 'Disable sign-in access for this account',
        list_disable_description:
          'Prevent access to the account while the additional steps are performed. <a>Learn more...</a>',
        existing_sessions: 'Kill any existing sessions',
        existing_sessions_description: 'Kick out any attacker currently signed into the account. <a>Learn more...</a>',
        reset_password: 'Reset the account password',
        reset_password_description:
          'Change the password to prevent the attacker from accessing the account in the future. <a>Learn more...</a>',
        recommended: '(recommended)',
        critical: '(critical)',
        sign_in: `Sign into ${MICROSOFT_365}`,
        close: CLOSE,
        clean_up: 'Clean up messages'
      },
      step_init: {
        title: "Here's what we are going to do",
        description_1:
          "<b>Internal clean up:</b> remove malicious emails from your users' mailboxes to prevent further takeovers.",
        description_2:
          '<b>External notification:</b> mitigate reputation and brand risk by letting external parties know they received a malicious email from you.'
      },
      step_new_incident_form: {
        title: 'Please provide information about the incident',
        placeholder_account: 'Compromised account',
        placeholder_subject: 'Subject of malicious email sent from compromised account',
        error_subject: 'A subject is required',
        error_subject_length: 'Subject cannot be longer than 200 characters',
        no_subject_checkbox: "I don't have a sample of a malicious email sent from this account",
        dropdown_title: 'This attack happened in the last',
        search_no_results:
          'No malicious emails found - please try again. Consider a longer period of time or a shorter subject'
      },
      step_confirm_incident: {
        title_no_subject: 'Please confirm all emails are malicious',
        title: 'Please select malicious emails',
        limit_search_results: 'Showing first {results} results.',
        additional_recipients: 'and {count} other {count, plural, one {recipient} other {recipients}}',
        footer: 'Clicking "Yes" will not make changes to your tenant.',
        footer_no_subject:
          'The list above includes all emails sent from {emailAddress}. Please click {icon} to remediate all malicious emails with the same subject. Otherwise, click “No malicious emails” to proceed.',
        close: CLOSE,
        email_details_dialog_title: 'Email from {displayName} on {dateSent}'
      },
      step_quarantine: {
        display_name: NAME,
        email: 'Email',
        title:
          'Should we clean up the attack from {count} {count, plural, one {internal recipient} other {internal recipients}} accounts?',
        title_no_recipient:
          "Good news! The attack was not sent to internal recipients so there's no risk of additional infections in your organization. Please click continue to proceed.",
        radio_title: 'Clicking "Clean up" will',
        radio_select_1: "Permanently delete the attack from recipients' inbox",
        radio_select_2: "Move the attack to recipients' junk email folders"
      },
      step_notify: {
        display_name: NAME,
        email: 'Email',
        title: 'Notify {count} {count, plural, one {external recipient} other {external recipients}} about the attack?',
        title_no_recipient: `No external recipients detected. Please note that there might be external recipients that were not detected by ${SEN_APP_NAME}. We recommend notifying all known external recipients about the attack to mitigate its impact.`,
        select_account: 'Please select an account from which to send notifications',
        edit_email: 'Edit email notification',
        footer: 'Notifications will be sent over the next 10 minutes.'
      },
      notification_email: {
        title: 'The following notification will be sent to all affected recipients',
        cancel: CANCEL,
        apply: 'Apply',
        subject: 'Subject: ',
        from: 'From: ',
        email: '{displayName} <{email}>'
      },
      step_inbox_rules: {
        title: 'Please review inbox rules on {email}',
        description:
          'Inbox rules are often used by attackers to cover their tracks or take advantage of the accounts they take over. Please review rules that may move, delete or forward emails automatically.',
        title_no_permission: `${SEN_APP_NAME} could not check Inbox rules for {email}`,
        description_no_permission: `Inbox rules are often used by attackers to cover their tracks or take advantage of the accounts they take over.<br/><br/>To allow ${SEN_APP_NAME} to access inbox rules, click 'Add permissions' and follow the instructions.<br/>`,
        title_no_rules_no_error: 'No Inbox rules found on {email}',
        description_no_rules_no_error: `Inbox rules are often used by attackers to cover their tracks or take advantage of the accounts they take over. ${SEN_APP_NAME} confirmed that no rules were configured on {email}, so no clean up action is required.`,
        title_no_rules_final_error: `We could not pull Inbox rule data from ${MICROSOFT_365} for {email}`,
        description_no_rules_final_error:
          'Inbox rules are often used by attackers to cover their tracks or take advantage of the accounts they take over. If this is an alias, please consider checking for suspicious Inbox rules on the primary email for this account through the Inbox Rules tab.',
        delete_rule: 'Delete rule'
      },
      step_done: {
        title: 'You are all set',
        description:
          'Everything is back to normal. We recommend enabling multi-factor authentication to prevent future incidents.'
      },
      step_reset_password: {
        title: 'Change account password',
        description: `We will attempt to change the password used to sign in to <b>{displayName}'s</b> account. You must have an ${MICROSOFT_365} global administrator account to authorize. After you sign in to your Microsoft account accept the application permissions to reset the password.`
      },
      step_reset_password_done: {
        title: 'Note the new password for <b>{displayName}</b>',
        description_password:
          'Provide the user with the following password so they can continue to access their account.',
        description_copy: `If you do not copy this password now, you will have to manually log into ${MICROSOFT_365} to reset it again.`,
        placeholder: 'New password',
        copied: COPIED
      }
    },
    alert_dialog: {
      title: 'Suspicious activity on <b>{email}</b>',
      create_incident: 'Create incident',
      mark_as_fp: 'Mark as false positive',
      close: CLOSE,
      tabs: {
        emails_sent: 'Emails sent ({count})',
        signins: 'Sign ins ({count})',
        inbox_rules: 'Inbox rules ({count})'
      }
    },
    add_domain_dialog: {
      init: {
        title: 'New domain',
        input_tooltip: 'Domain name, e.g. acme.com',
        input_error: 'A valid domain name is required',
        subtitle_1: 'Enter the domain you would like to add',
        subtitle_2:
          'You will need to verify ownership of the domain by adding a TXT record on your domain host service.'
      },
      verification_instructions: {
        subtitle_1: 'Please verify you own the domain',
        subtitle_2:
          '<b>Please note:</b> adding this entry will only verify that you own this domain. It is not a DMARC record for it.'
      },
      verification_failure: {
        subtitle_1: "We couldn't find the verification record",
        subtitle_2: '<b>Troubleshooting</b>',
        subtitle_3: 'Have you saved your configuration on your domain host service?',
        subtitle_4: 'DNS changes may require 1-2 minutes to take effect, please try again shortly.'
      },
      verification_success: {
        subtitle_1: 'You are all set!',
        subtitle_2: `Your domain is verified and you can now deploy DMARC on it using ${SEN_APP_NAME}.`,
        subtitle_3: "Please click 'Setup DMARC' to start monitoring your domain."
      },
      domain_values: {
        title: 'Create a new record on the domain',
        domain_name: 'Domain name',
        type: 'Type',
        value: 'Value',
        copied: 'Copied to clipboard'
      },
      cancel: CANCEL,
      next: NEXT,
      setup_dmarc: 'Setup DMARC'
    },
    delete_domain_dialog: {
      title: `Delete {domainName}`,
      init_subtitle_1: `Deleting this domain removes your ability to manage its DMARC through ${SEN_APP_NAME}. This action will not, however, remove associated DNS entries from your provider.`,
      init_subtitle_2: `For complete disengagement, after you remove the domain from ${SEN_APP_NAME}, go to your DNS provider and remove related entries.`,
      deleted_subtitle_1: `Deleted {domainName} domain.`,
      deleted_subtitle_2: `Visit your DNS provider to remove related entries.`,
      cancel: CANCEL,
      dismiss: DISMISS,
      delete_this_domain: 'Delete this domain'
    },
    dmarc_countries_dialog: {
      map_title: `Countries sending mail impersonating your domain`,
      map_subtitle: 'Last 30 days',
      table_title: `Email samples for {countryName}`,
      close: 'Close',
      back: 'Back',
      dmarc_countries_map: {
        emails_from_blacklist: 'Emails from blacklisted IPs',
        total_emails: 'Total emails'
      },
      dmarc_fraud_table: {
        date: 'Date',
        subject: 'Subject',
        origin: 'Origin country',
        emails: 'Number of emails',
        empty_table: 'No data available',
        view_action: 'view'
      },
      dmarc_fraud_sample: {
        to: 'To',
        from: 'From',
        reply: 'Reply to',
        date: 'Date',
        subject: 'Subject'
      }
    },
    enforce_dmarc_dialog: {
      enforcement_title: 'Enable DMARC enforcement',
      enforcement_subtitle_1:
        'Copy this DNS record and use with your DNS provider to instruct email servers to reject all emails from your domain that fail DMARC. Some DNS providers require records be added in a particular way. Refer to your provider for the exact directions.',
      enforcement_subtitle_2:
        'NOTE: Instructions in DNS records will only be enforced by email servers that are observing DMARC.',
      reporting_title: 'Enable DMARC reporting only',
      reporting_subtitle_1:
        'Use this record with your DNS provider to begin collecting data from DMARC reporting. Some DNS providers require records be added in a particular way. Refer to your provider for the exact directions.',
      reporting_subtitle_2:
        'NOTE: Instructions in DNS records will only be enforced by email servers that are observing DMARC.',
      copy_dns_record: 'Copy DNS record',
      dmarc_check_title: 'Your DMARC record',
      dmarc_check_subtitle_1: 'Checking DMARC record...',
      dmarc_check_subtitle_2: 'This process could take up to a minute.',
      result_title: 'Your DMARC record',
      success_enforcement_title: 'Enforcement enabled',
      success_enforcement_subtitle_1:
        'All emails that fail DMARC will be rejected by organizations that are choosing to observe DMARC.',
      success_enforcement_subtitle_2:
        'You should regularly monitor your DMARC reports to make sure you are aware of unknown sources and make sure that your approved sources are still passing DMARC.',
      success_reporting_title: 'Reporting only enabled',
      success_reporting_subtitle_1:
        'DMARC for you domain is set to Reporting Only. Sources sending email as your domain will be allowed to go through.',
      success_reporting_subtitle_2:
        'Reporting is used to gather intelligence around what sources are sending email as your domain.',
      failed_enforcement_title: 'DMARC enforcement record not found',
      failed_enforcement_subtitle_1:
        'We cannot detect a valid enforcement record for your domain. This could be due to an incorrect DNS entry.',
      failed_enforcement_subtitle_2: 'Please double check your DNS entry and guidelines with your provider.',
      failed_reporting_title: 'DMARC reporting record not found',
      failed_reporting_subtitle_1:
        'We cannot detect a valid reporting record for your domain. This could be due to an incorrect DNS entry.',
      failed_reporting_subtitle_2: 'Please double check your DNS entry and guidelines with your provider.',
      check_dmarc_record: 'Check DMARC record',
      back: BACK,
      close: CLOSE,
      cancel: CANCEL
    },
    domain_wizard_dialog: {
      configure_content: {
        spf_subtitle_1: 'We were unable to find an SPF record on your domain. To set one up:',
        dmarc_subtitle_1: "Here's how to create it:",
        step_1:
          'Sign in to your domain host service (e.g. GoDaddy). Not sure which service you use? Check the Registrar section <link>here</link>',
        spf_step_2: 'Create a new record with this value:',
        dmarc_step_2: 'Create a new record for the <b>_dmarc</b> subdomain:',
        name: 'Name',
        type: 'Type',
        value: 'Value',
        subtitle_2: `<b>Important:</b> if you use a hybrid on-prem/online deployment of ${MICROSOFT_365}, please refer to <link>this Microsoft article</link> to configure your SPF records correctly.`
      },
      init: {
        title: "Let's start by checking your SPF record",
        subtitle_1:
          'SPF (Sender Policy Framework) allows email recipients to verify that emails from your domain are received from authorized email servers only. SPF is implemented as a DNS record of type TXT on {domainName}.',
        subtitle_2: 'We will now verify that your SPF record is valid.',
        cancel: CANCEL,
        check_my_spf: CHECK_MY_SPF
      },
      spf_check: {
        title: 'Checking your SPF record... This may take up to one minute',
        cancel: CANCEL
      },
      spf_check_failure: {
        title: 'Please configure your SPF record',
        cancel: CANCEL,
        check_my_spf: CHECK_MY_SPF
      },
      spf_check_success: {
        title: 'Your SPF is configured! You can now configure DMARC',
        subtitle_1:
          'DMARC will tell email recipients to send back a report whenever an email from your domain fails authentication. We will automatically process these reports to detect fraud attempts and/or issues with your email authentication configuration.',
        subtitle_2: 'We will now prepare our system to receive reports on your behalf.',
        spf_record: 'Your SPF record',
        copied: COPIED,
        cancel: CANCEL,
        configure_dmarc: 'Configure DMARC'
      },
      dmarc_prep: {
        title: 'Preparing to receive reports...',
        cancel: CANCEL
      },
      dmarc_setup: {
        title: 'Please configure your DMARC record',
        cancel: CANCEL,
        check_my_dmarc: 'Check my DMARC'
      },
      dmarc_check: {
        title: 'Checking your DMARC record... This may take up to one minute',
        cancel: CANCEL
      },
      dmarc_invalid: {
        title: 'Your DMARC record is invalid',
        subtitle_1: 'Please use this exact record:',
        subtitle_2: 'Your current record:',
        cancel: CANCEL,
        check_dmarc_again: CHECK_DMARC_AGAIN
      },
      dmarc_not_found: {
        title: "We couldn't find a DMARC record on your domain",
        troubleshooting_title: '<b>Troubleshooting:</b>',
        troubleshooting_subtitle_1: 'Have you added the entry on {dmarcRecord} (as opposed to {domainName})?',
        troubleshooting_subtitle_2: 'Have you saved your configuration on your domain host service?',
        troubleshooting_subtitle_3:
          'Please note that in certain cases, DNS changes take up to 48 hours to propagate throughout the Internet. Please revisit this wizard later and check again.',
        troubleshooting_subtitle_4: 'DNS changes may require 1-2 minutes to take effect, please try again shortly.',
        cancel: CANCEL,
        check_dmarc_again: CHECK_DMARC_AGAIN
      },
      dmarc_check_success: {
        title: 'You are all set!',
        subtitle_1:
          'We will collect DMARC reports on your behalf and get back to you with insights shortly. Please feel free to chat with us at any time if you have questions.',
        subtitle_2:
          "Once we verify that your domain's authentication is configured correctly, we will help you turn on DMARC enforcement, preventing hackers from sending fraudulent emails on your behalf.",
        finish: 'Finish'
      }
    },
    inbox_rules_dialog: {
      title: 'Inbox rules for <b>{email}</b>',
      button: CLOSE
    },
    investigate_dmarc_dialog: {
      title: '{count} DMARC reports for {host}',
      view_failure_samples: 'View failure samples',
      no_failure_samples: 'No failure samples',
      view_by_sending_ips: 'View by sending IPs',
      view_by_sending_domain: 'View by sending domain',
      close: CLOSE,
      back: BACK,
      view: 'View',
      fail: 'Fail',
      pass: 'Pass'
    },
    range_inbox_rules_dialog: {
      title: 'New inbox rules for <b>{email}</b> on <b>{date}</b>',
      title_2: 'Previous inbox rules for <b>{email}</b>',
      button: CLOSE
    },
    mark_alert_as_fp_dialog: {
      title: 'Are you sure?',
      text:
        "Please confirm that this account was not taken over and that the alert was a false positive. Your feedback will help make Impersonation Protection's AI better.",
      success_title: 'Thanks for the feedback!',
      success_text:
        'We noted that the alert you received was a false positive. Our team will investigate your report so we can improve Impersonation Protection’s AI. We appreciate your feedback.',
      back: BACK,
      cancel: CANCEL,
      on_mark: 'Yes, report false positive'
    },
    test_ai_dialog: {
      title: 'Send sample spear phishing attack',
      select_recipient: SELECT_RECIPIENT,
      cancel: CANCEL,
      close: CLOSE,
      send_now: 'Send now',
      subtitle:
        '<send>We will send a simulated spear phishing attack to <b>{recipient}</b>. </send>This simulation attack is based on real spear phishing attacks experienced by our customers.',
      select_impersonate_sender: 'Select impersonate sender',
      sender_error: 'Email address must be a valid employee address',
      sender_help: 'Choose a sender who has been with the company for at least two years.',
      filter_attacks: 'Filter attacks',
      attack_types: {
        blackmailing: 'Extortion',
        employee_impersonation: 'Employee impersonation',
        service_impersonation: 'Service impersonation'
      },
      select_attack: 'Select attack',
      subject: `<subject>${SUBJECT}:</subject> {subjectText}`,
      success_alert:
        'Sample attack email sent to <b>{recipientName}</b> successfully. The email will be received shortly.'
    },
    serial_number_dialog: {
      cancel: CANCEL,
      content: `If you have purchased a ${SEN_APP_NAME} license, please provide your serial number and linking code below.`,
      linking_code_text: `${SEN_APP_NAME} linking code`,
      linking_code_text_error: 'A valid linking code is required',
      serial_number_text: `${SEN_APP_NAME} serial number`,
      serial_number_text_error: 'A valid serial number is required',
      submit: SUBMIT,
      title: 'Enter license information'
    },
    mailboxes_dialog: {
      license_title: 'Licenses used',
      license_purchased: 'Licenses purchased',
      title: `Mailboxes protected by ${SEN_APP_NAME}`,
      button: CLOSE,
      search_field: {
        placeholder: SEARCH
      }
    },
    report_false_positive_dialog: {
      title: 'Report false positive',
      subtitle_1: `Confirm that this email was not a phishing attack, but rather a legitimate message. If the email was previously moved to the user's junk folder, it will be returned to its original location. Your feedback will help improve ${SEN_APP_NAME}'s AI.`,
      select_title: 'If you trust the sender of this message, consider adding it to allowed senders',
      select_0: 'Do not add this sender to my allowed senders (recommended)',
      select_1: 'Add the domain <b>{domain}</b> to my allowed senders',
      select_2: 'Add the address <b>{email}</b> to my allowed senders',
      cancel: CANCEL,
      submit: 'Yes, report false positive',
      clean_up: "Emails marked as false positive won't be deleted."
    },
    settings_dialog: {
      tabs: {
        spear_phishing: 'Spear phishing',
        ato: 'Account takeover',
        allowed_senders: 'Allowed senders',
        syslog_integration: 'Syslog integration',
        reports: 'Reports'
      },
      spear_phishing_tab: {
        save: SAVE,
        cancel: CLOSE,
        email_error: EMAIL_ERROR,
        section_quarantine_mode: {
          title: 'When a new spear phishing attack is detected:',
          select_1: "Move the email to the recipient's Junk Email folder (recommended)",
          select_2: "Permanently delete the email from the recipient's account",
          select_3: "Leave the email in the recipient's inbox"
        },
        section_enduser_mode: {
          select_1: 'Send an email alert to the recipient <link>Customize alert</link>',
          select_2: 'Do not alert the recipient'
        },
        section_admin_mode: {
          select_1: 'Send an email alert to the security team',
          select_2: 'Do not alert the security team'
        }
      },
      ato_tab: {
        title: 'When a new account takeover attack is detected:',
        section_admin_mode: {
          select_1: 'Send an email alert to',
          select_2: 'Do not send an email alert'
        },
        email_error: EMAIL_ERROR,
        save: SAVE,
        cancel: CLOSE
      },
      reports_tab: {
        send_reports_by_email: 'Send reports by email',
        time_range: 'Time range',
        time_range_menu_item: 'Last {range} Days',
        use_security_team_email: 'Use security team email',
        send_to: 'Send to',
        email_error: EMAIL_ERROR,
        start_date: 'Start date',
        frequency: 'Frequency',
        send_weekly_report: 'Send <b>{frequency}</b> email report <to>to <b>{email}</b></to> starting <b>{date}</b>',
        save: SAVE,
        cancel: CLOSE,
        frequencies: {
          d: 'Daily',
          w: 'Weekly',
          m: 'Monthly'
        }
      },
      allowed_senders_tab: {
        add_allowed_sender: 'Add allowed sender',
        empty_table: 'No allowed senders found',
        table_columns: {
          sender_email_or_domain: 'Sender email or domain',
          comment: 'Comment'
        },
        caution: `<b>Use with caution.</b> Attacks from allowed senders will not be remediated by ${SEN_APP_NAME}.`,
        add_new_allowed_sender: 'Add a new allowed sender',
        edit_allowed_sender: 'Edit allowed sender',
        delete_allowed_sender: 'Delete allowed sender',
        sender_email_or_domain: 'Sender email or domain',
        comment: 'Comment',
        save: SAVE,
        cancel: CLOSE,
        back: BACK
      },
      syslog_integration_tab: {
        active: 'Active',
        ip_address_hostname: 'IP address / hostname',
        port: 'Port',
        note: 'Note: TCP+TLS is required to connect successfully. Non-TLS is not supported.',
        test_success: 'Server status: port reachable',
        test_failed: 'Message failed to deliver to remote syslog server',
        test: TEST,
        save: SAVE,
        cancel: CLOSE
      }
    },
    customize_alert_dialog: {
      save: SAVE,
      back: BACK,
      from: FROM,
      subject: SUBJECT,
      title: 'Email alert to recipient',
      reset_to_default: 'Reset to default',
      from_display_name: 'Email from display name',
      introduction: 'Introduction',
      signature: 'Signature',
      alert_preview: 'Alert preview'
    },
    attack_table_dialog: {
      fraudulent_emails: 'Fraudulent emails',
      title_name: 'Fraud received by {filteredOnItem}',
      title_domain: 'Fraud from {filteredOnItem}',
      title_subject: `Fraud with subject "{filteredOnItem}"`,
      close: CLOSE,
      see_details: 'View email',
      empty_table: NO_ATTACKS_FOUND
    },
    signins_dialog: {
      alert_title: 'Related sign ins for <b>{email}</b>',
      user_title: 'Sign ins for <b>{email}</b> for last {timeframe} days',
      country_title: 'Sign ins from <b>{country}</b>',
      alert_subtitle:
        'Sign ins that occurred approximately near the time frame of the suspicious sign in<br>Highlighted rows triggered an account takeover alert</br>',
      user_subtitle: 'Highlighted rows triggered an account takeover alert',
      country_subtitle: 'Highlighted rows triggered an account takeover alert',
      back: BACK,
      close: CLOSE,
      success: 'Success',
      failure: 'Failed'
    },
    data_tables: {
      confirm_incident: {
        sent: 'Sent',
        recipients: 'Recipients',
        subject: SUBJECT,
        more_details: MORE_DETAILS,
        remediate: 'Remediate emails with this subject'
      },
      alerts: {
        empty_table: 'No alerts found',
        date: DATE,
        account_taken_over: 'Account taken over',
        activity: 'Activity',
        status: 'Status',
        details: 'Details',
        review: 'Review',
        activities: {
          sample_subject: 'Suspicious email sent from account',
          sample_login_id: 'Suspicious sign in',
          sample_rule_id: 'Suspicious inbox rule added to account'
        }
      },
      alert_threats: {
        empty_table: 'No threats found',
        date: DATE,
        recipient: RECIPIENT,
        subject: SUBJECT,
        additional_recipients: 'and {count} other {count, plural, one {recipient} other {recipients}}',
        view_message: 'View message',
        close: CLOSE
      },
      alert_signins: {
        empty_table: 'No sign ins found',
        date: DATE,
        account: 'Account',
        ip: 'IP',
        user_agent: 'User agent',
        location: 'Location',
        issue: 'Issue',
        status: 'Status',
        older_than_30_days: 'Sign in data unavailable. Alert more than 30 days old.',
        data_collected_since: 'Data collected since {date}.',
        view_related_signins: 'View related sign ins',
        detection_types: {
          un: 'Unusual location and application',
          ip: 'Suspicious IP'
        }
      },
      alert_inbox_rules: {
        empty_table: NO_INBOX_RULES,
        date: DATE,
        sequence: SEQUENCE,
        name: NAME,
        actions: ACTIONS,
        conditions: CONDITIONS,
        exceptions: EXCEPTIONS,
        enabled: ENABLED,
        yes: YES,
        no: NO
      },
      domains: {
        empty_table: 'No domains found',
        domain: 'Domain',
        status: 'Status',
        unknown_sources: `${UNKNOWN} sources`,
        unknown_sources_tooltip: `${UNKNOWN} sources sending high volume of emails impersonating your domain`,
        failures: 'Failures',
        failures_tooltip: 'Emails that failed DMARC from approved sources',
        enforce_dmarc: 'Enforce DMARC',
        delete_tooltip: `Domain must be removed through ${MICROSOFT_365}`,
        actions: {
          setup_dmarc_reporting: 'Set up DMARC reporting',
          verify_domain: 'Verify domain',
          waiting_for_sufficient_report: 'Waiting for sufficient reports',
          review_high_vol_sources: 'Review high volume sources',
          review_failures: 'Review failures',
          view_report: 'View DMARC reporting data'
        }
      },
      dmarc_sources: {
        empty_table: 'No sources found',
        host: 'SOURCE',
        email_count: 'EMAILS REPORTED<br>Last 30 days</br>',
        dmarc: 'DMARC',
        source: 'SOURCE'
      },
      inbox_rules: {
        empty_table: NO_INBOX_RULES,
        sequence: SEQUENCE,
        name: NAME,
        actions: ACTIONS,
        conditions: CONDITIONS,
        exceptions: EXCEPTIONS,
        enabled: ENABLED,
        yes: YES,
        no: NO
      },
      inbox_rule_feed_table: {
        empty_table: 'No inbox rule feed found',
        user: 'User',
        actions: 'Actions',
        investigate: 'Investigate',
        added_rule: 'Added rule',
        modified_rule: 'Modified rule',
        deleted_rule: 'Deleted rule'
      },
      incidents: {
        empty_table: 'No incidents found',
        reported: 'Reported',
        account_taken_over: 'Account taken over',
        impact: 'Impact',
        internal_attacks:
          '{count, plural, =0 {No} other {#}} internal {count, plural, =0 {attacks} one {attack} other {attacks}}',
        external_attacks:
          '{count, plural, =0 {No} other {#}} external {count, plural, =0 {attacks} one {attack} other {attacks}}',
        view_incident: 'View incident'
      },
      internal_attacks: {
        empty_table: 'No internal attacks',
        recipient: RECIPIENT,
        read: 'Read',
        status: 'Status',
        statuses: {
          y: YES,
          n: NO,
          u: UNKNOWN
        }
      },
      mailboxes: {
        empty_table: 'No mailboxes found',
        display_name: 'Display name',
        email: 'Email',
        title: 'Title',
        department: 'Department',
        licensed: 'Licensed',
        protected: 'Protected'
      },
      sp_attacks: {
        empty_table: NO_ATTACKS_FOUND,
        date: DATE,
        employee: 'Employee',
        email: 'Email',
        attack_type: 'Attack type',
        confidence: 'Confidence',
        status: 'Status'
      },
      reports: {
        empty_identity_table: 'No recipients found',
        employee: 'Employee',
        fraudulent_emails_received: 'Fraudulent emails received',
        empty_domain_table: 'No senders',
        domain: 'Domain',
        fraudulent_emails_sent: 'Fraudulent emails sent',
        empty_subject_table: 'No subjects',
        subject: 'Subject',
        fraudulent_emails: 'Fraudulent emails'
      },
      ruf_samples_table: {
        empty_table: 'No samples found',
        date: DATE,
        subject: SUBJECT,
        origin_country: 'Origin country',
        number_of_emails: 'Number of emails'
      },
      attack_table: {
        empty_table: NO_ATTACKS_FOUND,
        date: DATE,
        employee: 'Employee',
        email: 'Email',
        action: 'Action'
      },
      signins_by_country_table: {
        empty_table: 'No signins found',
        country: 'Country',
        total_signins: 'Total sign ins',
        failed_signins: 'Failed sign ins',
        view: 'View'
      },
      view_by_domain_table: {
        empty_table: 'No domains found',
        failed_reports: 'Failed reports',
        passed_reports: 'Passed reports',
        spf_status: 'SPF status',
        spf_domain: 'SPF domain',
        dkim_status: 'DKIM status',
        dkim_domain: 'DKIM domain',
        percentage_of_passes: '{count}% of passes',
        percentage_of_failures: '{count}% of failures',
        aligned: 'Aligned',
        misaligned: 'Misaligned',
        passed_spf: 'Passed SPF',
        failed_spf: 'Failed SPF',
        passed_dkim: 'Passed DKIM',
        failed_dkim: 'Failed DKIM',
        passed: 'Passed',
        failed: 'Failed',
        spf_and_dkim_setup: 'SPF and DKIM setup',
        dkim_setup: 'DKIM setup',
        spf_setup: 'SPF setup',
        see_recommendation: 'See recommendation'
      },
      view_by_ips_table: {
        empty_table: 'No IPs found',
        ip_address: 'IP address',
        country: 'Country',
        failed_reports: 'Failed reports',
        passed_reports: 'Passed reports',
        number_of_failures: 'Number of failures (Misalignment %)<br>Last 30 days</br>'
      }
    },
    autocompletes: {
      recipients: {
        placeholder: AUTOCOMPLETE_PLACEHOLDER,
        no_employees_matching: NO_EMPLOYEES_MATCHING,
        error: 'A valid email address is required'
      },
      compromised_account: {
        placeholder: 'Compromised account *',
        no_employees_matching: NO_EMPLOYEES_MATCHING,
        error: 'A valid email address is required'
      },
      notify_external: {
        placeholder: 'Notifications will be sent from *',
        no_employees_matching: NO_EMPLOYEES_MATCHING,
        error: 'A valid email address is required'
      },
      senders: {
        placeholder: AUTOCOMPLETE_PLACEHOLDER,
        no_employees_matching: NO_EMPLOYEES_MATCHING,
        error: 'Email address must be a valid employee address'
      }
    },
    cleanup: {
      title: 'Attacks in the last 6 months',
      empty_table: 'No attacks remaining in your account',
      subject: SUBJECT,
      more_details: MORE_DETAILS,
      report_false_positive: REPORT_FALSE_POSITIVE,
      close: CLOSE,
      delete: 'Delete all attacks',
      cleanup_dialog: {
        title: 'Are you sure?',
        subtitle: "Emails marked as false positive won't be deleted.",
        delete_emails: 'Delete emails',
        cleanup_false_positive: "Emails marked as false positive won't be deleted.",
        cancel: CANCEL
      }
    },
    license: {
      no_permissions: NO_PERMISSIONS
    },
    unified_reporting: {
      report_header: {
        download_options: {
          data_csv: 'CSV data sheet',
          summary_pdf: 'PDF report'
        },
        report_options: {
          delete_report: 'Delete report',
          rename: 'Rename',
          save_as_copy: 'Save as copy',
          schedule: 'Schedule'
        }
      },
      column_headers: {
        pipeline_sp_threat: {
          confidence: 'Confidence score',
          correspondent_sender_display_name: 'Sender name',
          correspondent_sender_domain: 'Sender domain',
          correspondent_sender_email: 'Sender email',
          created_on: 'Date processed',
          date_received: 'Date received',
          date_sent: 'Date sent',
          email_id: 'Email ID',
          has_attachments: 'Attachments',
          identity_department: 'Recipient department',
          identity_display_name: 'Recipient name',
          identity_email: 'Recipient email',
          identity_job_title: 'Recipient job title',
          internet_message_id: 'Internet message ID',
          is_read: 'Read',
          is_whitelisted: 'Whitelisted',
          parent_folder_name: 'Parent folder name',
          sender_ip_address: 'Sender IP address',
          sender_domain_registration_date: 'Sender domain registration date',
          sender_ip_location: 'Country',
          sender_ip_reputation: 'Sender IP reputation',
          service_phishing_domains: 'Service phishing domains',
          service_phishing_keywords: 'Impersonated services',
          severity: 'Severity',
          status: 'Status',
          subject: SUBJECT,
          taxonomy: 'Attack type',
          threat_detects_count: 'Total threats detected'
        }
      },
      column_values: {
        pipeline_sp_threat: {
          confidence: {
            high: 'High',
            moderate: 'Moderate',
            veryHigh: 'Very high'
          },
          status: {
            markedAsFp: 'Marked as false positive',
            deleted: 'Message deleted',
            quarantined: 'Moved to junk folder',
            notifiedRecipient: 'Recipient alerted',
            notifiedAdmin: 'System admin alerted',
            notifiedSender: 'Impersonated sender alerted',
            Oops: UNKNOWN,
            null: 'No actions taken'
          },
          taxonomy: {
            spam: 'Scamming',
            becSpoofing: 'Impersonation',
            conversationHijacking: 'Conversation hijacking',
            blackmail: 'Extortion',
            phishing: 'Phishing'
          }
        }
      }
    }
  }
}
/* eslint-enable @typescript-eslint/naming-convention, quotes */
