import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { isEqual } from 'lodash'
import { DateTime } from 'luxon'

import { AlertProps } from 'global/components/lib/alerts/Alert'
import { config } from 'global/lib/config'
import { useFormatMessage } from 'global/lib/localization'
import useProductLib from 'global/lib/product/useProduct'
import { isFailed, isIdle, isPending, isSuccess } from 'global/redux/toolkit/api'
import { setErrorMessage as setScheduledReportError } from 'global/redux/features/scheduledReports/scheduledReportsSlice'
import {
  getUnifiedReportingReports,
  getUnifiedReportingReportData,
  resetCreateUpdateDeleteReportStatus,
  resetGetUnifiedReportingReportsApiStatus,
  setErrorMessage as setUnifiedReportingError,
  update as updateUnifiedReporting
} from 'global/redux/features/unifiedReporting/unifiedReportingSlice'
import { UnifiedReportingReportProduct } from 'global/types/api/unifiedReporting'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import routesConfig from 'sen/lib/routes/routesConfig'

import { getColumnConfig } from 'sen/redux/features/dataTables/unifiedReporting/columnConfigs'
import { update as updateUnifiedReportingDataTable } from 'sen/redux/features/dataTables/unifiedReporting/unifiedReportingSlice'

export interface UseUnifiedReportingLogicProps {
  alertConfig: AlertProps
  isLoading: boolean
}

const ERROR_I18N_KEY = 'app.error'

export default function useUnifiedReportingLogic(): [UseUnifiedReportingLogicProps] {
  const params = useParams()
  const dispatch = useAppDispatch()
  const [productLib] = useProductLib()
  const formatErrorMessage = useFormatMessage(ERROR_I18N_KEY)

  const {
    absoluteDateRangeEnd,
    absoluteDateRangeStart,
    accessTokenId,
    dataTableFilters,
    dataTableGroupBy,
    dataTablePage,
    dataTableSearchQuery,
    dataTableSorting,
    errorMessage,
    isCreateReportSuccess,
    isDeleteReportSuccess,
    isGetReportsDataPending,
    isLoadingReports,
    isUpdateReportSuccess,
    pinnedReportUuid,
    relativeDateRange,
    report,
    reports,
    topDisplayCount
  } = useAppSelector(_stores => ({
    absoluteDateRangeEnd: _stores.dataTables.unifiedReporting.absoluteDateRangeEnd,
    absoluteDateRangeStart: _stores.dataTables.unifiedReporting.absoluteDateRangeStart,
    accessTokenId: _stores.accessToken?.accessToken?.id || '',
    dataTableFilters: _stores.dataTables.unifiedReporting.filters,
    dataTableGroupBy: _stores.dataTables.unifiedReporting.groupBy,
    dataTablePage: _stores.dataTables.unifiedReporting.page,
    dataTableSearchQuery: _stores.dataTables.unifiedReporting.searchQuery,
    dataTableSorting: _stores.dataTables.unifiedReporting.sortBy,
    errorMessage: _stores.unifiedReporting.errorMessage,
    isCreateReportSuccess: isSuccess(_stores.unifiedReporting.createUnifiedReportingReportApiStatus),
    isDeleteReportSuccess: isSuccess(_stores.unifiedReporting.deleteUnifiedReportingReportApiStatus),
    isGetReportsDataPending: isPending(_stores.unifiedReporting.getUnifiedReportingReportDataApiStatus),
    isGetReportsIdle: isIdle(_stores.unifiedReporting.getUnifiedReportingReportsApiStatus),
    isGetReportsSuccess: isSuccess(_stores.unifiedReporting.getUnifiedReportingReportsApiStatus),
    isGetReportsFailed: isFailed(_stores.unifiedReporting.getUnifiedReportingReportsApiStatus),
    isLoadingReports: isPending(_stores.unifiedReporting.getUnifiedReportingReportsApiStatus),
    isUpdateReportSuccess: isSuccess(_stores.unifiedReporting.updateUnifiedReportingReportApiStatus),
    pinnedReportUuid: _stores.unifiedReporting.pinnedReportUuid,
    relativeDateRange: _stores.dataTables.unifiedReporting.relativeDateRange,
    report: _stores.unifiedReporting.report,
    reports: _stores.unifiedReporting.reports,
    topDisplayCount: _stores.dataTables.unifiedReporting.topDisplayCount
  }))

  // Prevent access to the page if the user does not have bundle 2
  const sentinelBundleVersion: string = useMemo(() => productLib.getSentinelSerialBundleForAccessToken(accessTokenId), [
    productLib,
    accessTokenId
  ])

  useEffect(() => {
    if (sentinelBundleVersion === config.BUNDLES.DP) {
      routesConfig.EMPTY_REPORT.goto()
    }
  }, [sentinelBundleVersion])

  useEffect(() => {
    dispatch(getUnifiedReportingReports({ product: UnifiedReportingReportProduct.IP }))
      .unwrap()
      .then(result => {
        const currentReport = result.reports.find((r: { uuid: string }) => r.uuid === params.urReportId)
        if (currentReport) {
          dispatch(
            updateUnifiedReporting({
              report: currentReport
            })
          )
          dispatch(
            updateUnifiedReportingDataTable({
              absoluteDateRangeEnd: currentReport.config.absoluteDateRangeEnd,
              absoluteDateRangeStart: currentReport.config.absoluteDateRangeStart,
              columns: getColumnConfig(currentReport.baseDataType),
              filters: currentReport.config.filters,
              groupBy: currentReport.config.groupBy,
              hiddenColumns: currentReport.config.hiddenColumns,
              relativeDateRange: currentReport.config.relativeDateRange,
              searchQuery: currentReport.config.searchQuery,
              sortBy: currentReport.config.sortBy,
              topDisplayCount: currentReport.config.topDisplayCount
            })
          )
        }
      })
      .finally(() => {
        dispatch(resetGetUnifiedReportingReportsApiStatus())
      })

    return () => {
      dispatch(setUnifiedReportingError(''))
      dispatch(setScheduledReportError(''))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isDeleteReportSuccess || isCreateReportSuccess || isUpdateReportSuccess) {
      dispatch(getUnifiedReportingReports({ product: UnifiedReportingReportProduct.IP }))
      dispatch(resetCreateUpdateDeleteReportStatus())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateReportSuccess, isDeleteReportSuccess, isUpdateReportSuccess])

  // Redirect to pinned report or default report if no reportId in params
  useEffect(() => {
    if (!params.urReportId || isDeleteReportSuccess) {
      if (pinnedReportUuid) {
        routesConfig.UNIFIED_REPORTING.goto({
          reportId: accessTokenId,
          urReportId: pinnedReportUuid
        })
      } else if (reports.length) {
        routesConfig.UNIFIED_REPORTING.goto({
          reportId: accessTokenId,
          urReportId: reports[0].uuid
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleteReportSuccess, params.report, pinnedReportUuid])

  // Get the report data from BE when filters, groupBy, sortBy, topDisplayCount, chartPeriod or date range changes
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isEqual(report.uuid, params.urReportId)) {
      dispatch(
        getUnifiedReportingReportData({
          absoluteDateRangeEnd,
          absoluteDateRangeStart,
          chartPeriod: report.config.chartPeriod,
          chartType: report.config.chartType,
          filters: dataTableFilters,
          groupBy: dataTableGroupBy,
          hiddenColumns: report.config.hiddenColumns,
          page: dataTablePage.skip / dataTablePage.take,
          relativeDateRange,
          searchQuery: dataTableSearchQuery,
          size: dataTablePage.take,
          sortBy: dataTableSorting,
          topDisplayCount,
          uuid: report.uuid,
          zoneId: DateTime.local().zoneName
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    absoluteDateRangeStart,
    absoluteDateRangeEnd,
    report.config.chartPeriod,
    dataTableFilters,
    dataTableGroupBy,
    dataTablePage,
    dataTableSorting,
    dataTableSearchQuery,
    relativeDateRange,
    topDisplayCount
  ])

  const alertConfig = useMemo(() => {
    return {
      closeAction: () => {
        dispatch(setUnifiedReportingError(''))
        dispatch(setScheduledReportError(''))
      },
      pageAlert: errorMessage.length > 0,
      showClose: true,
      alertContent: errorMessage ? formatErrorMessage(`${errorMessage}`) : ''
    }
  }, [dispatch, errorMessage, formatErrorMessage])

  return useMemo(
    () => [
      {
        alertConfig,
        isLoading: isLoadingReports && isGetReportsDataPending
      }
    ],
    [alertConfig, isGetReportsDataPending, isLoadingReports]
  )
}
