import { useMemo, useState, useCallback, useEffect } from 'react'

import validator from 'validator'
import { isEqual } from 'lodash'

import useRadioButtons from 'global/lib/useRadioButtons/useRadioButtons'
import { updateCurrentAccessToken } from 'global/redux/features/accessToken/accessTokenSlice'
import { AccessToken } from 'global/types/api/accessTokenType'
import usePrevious from 'global/lib/usePrevious'

import { updateAccessToken } from 'sen/redux/features/settings/settingsSlice'
import { AdminModeProps } from 'sen/components/lib/dialogs/settings/shared/AdminMode'
import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import { isPending, isSuccess } from 'global/redux/toolkit/api'

const ACTIVE_SETTING_STATE = '1'
const INACTIVE_SETTING_STATE = '0'
const ADMIN_VALUES = [1, 0]

export type OnSetAdminEmail = (e: any) => void
export type OnSaveChanges = () => void

export type AdminModeSelectionConfig = Omit<AdminModeProps, 'isTabInProgress' | 'BASE_I18N_KEY' | 'withTitle'>
export type Error = string | undefined

export interface TabConfig {
  isTabInProgress: boolean
  onSaveChanges: OnSaveChanges
  errorMsg: string | undefined
  isSaveButtonDisabled: boolean
}

export default function useAtoTabLogic(): [TabConfig, AdminModeSelectionConfig] {
  const dispatch = useAppDispatch()

  const {
    isUpdateAccessToken,
    updatedAccessTokenSettings,
    isUpdateAccessTokenSuccess,
    settingsError,
    accessToken,
    atoAttackAlertAdmin,
    atoNotifyAddress,
    notificationEmail,
    isGetNotificationEmail
  } = useAppSelector(_stores => ({
    isUpdateAccessToken: isPending(_stores.settings.updateAccessTokenApiStatus),
    isUpdateAccessTokenSuccess: isSuccess(_stores.settings.updateAccessTokenApiStatus),
    updatedAccessTokenSettings: _stores.settings.accessTokenSettings,
    settingsError: _stores.settings.errorMsg,
    accessToken: _stores.accessToken.accessToken,
    atoAttackAlertAdmin: _stores.accessToken.accessToken?.settings.atoAttackAlertAdmin || INACTIVE_SETTING_STATE,
    atoNotifyAddress: _stores.accessToken.accessToken?.settings.atoNotifyAddress,
    notificationEmail: _stores.settings.notificationEmail,
    isGetNotificationEmail: isPending(_stores.settings.getNotificationEmailApiStatus)
  }))
  const [selectedAdminMode, setSelectedAdminMode] = useRadioButtons(atoAttackAlertAdmin)
  const [adminEmail, setAdminEmail] = useState<string>(atoNotifyAddress || notificationEmail || '')
  const [defaultAdminEmail] = useState<string>(adminEmail)
  const prevSelectedAdminMode = usePrevious(selectedAdminMode)

  // successfully updated accessToken's settings
  useEffect(() => {
    if (isUpdateAccessTokenSuccess && !isEqual(accessToken?.settings, updatedAccessTokenSettings)) {
      dispatch(
        updateCurrentAccessToken({
          ...accessToken,
          settings: updatedAccessTokenSettings
        } as AccessToken)
      )
    }
  }, [isUpdateAccessTokenSuccess, accessToken, dispatch, updatedAccessTokenSettings])

  // focus on the email field
  useEffect(() => {
    if (selectedAdminMode === ACTIVE_SETTING_STATE) {
      // eslint-disable-next-line no-unused-expressions
      document.getElementById('admin-email')?.focus()
    }
  }, [selectedAdminMode])

  // fill the admin email input if no value is set and the admin mode is changed
  useEffect(() => {
    if (prevSelectedAdminMode !== selectedAdminMode && !adminEmail.length && notificationEmail) {
      setAdminEmail(defaultAdminEmail)
    }
  }, [selectedAdminMode, prevSelectedAdminMode, adminEmail, notificationEmail, defaultAdminEmail])

  const isDirtyForm: boolean = useMemo(() => {
    return selectedAdminMode !== atoAttackAlertAdmin || adminEmail !== defaultAdminEmail
  }, [selectedAdminMode, atoAttackAlertAdmin, adminEmail, defaultAdminEmail])

  const isTabInProgress: boolean = useMemo(() => {
    return isUpdateAccessToken || isGetNotificationEmail
  }, [isUpdateAccessToken, isGetNotificationEmail])

  const onSetAdminEmail: OnSetAdminEmail = useCallback(e => {
    setAdminEmail(e.target?.value?.trim())
  }, [])

  const onSaveChanges: OnSaveChanges = useCallback(() => {
    const settings = {
      atoAttackAlertAdmin: selectedAdminMode,
      ...(selectedAdminMode === ACTIVE_SETTING_STATE && { atoNotifyAddress: adminEmail })
    }
    dispatch(updateAccessToken({ settings }))
  }, [dispatch, selectedAdminMode, adminEmail])

  const isSaveButtonDisabled = useMemo(() => {
    return isTabInProgress || !isDirtyForm
  }, [isTabInProgress, isDirtyForm])

  return useMemo(() => {
    return [
      {
        isTabInProgress,
        onSaveChanges,
        errorMsg: settingsError,
        isSaveButtonDisabled
      },
      {
        ADMIN_VALUES,
        isDisabled: isTabInProgress,
        radioButtonsConfig: {
          selectedRadioButton: selectedAdminMode,
          onSelectRadioButton: setSelectedAdminMode
        },
        adminEmail,
        setAdminEmail: onSetAdminEmail,
        isValidAdminEmail: validator.isEmail(adminEmail)
      }
    ]
  }, [
    isTabInProgress,
    selectedAdminMode,
    setSelectedAdminMode,
    adminEmail,
    onSetAdminEmail,
    onSaveChanges,
    settingsError,
    isSaveButtonDisabled
  ])
}
