import { createAsyncThunk } from '@reduxjs/toolkit'

import { config } from 'global/lib/config'
import restClient, { validateApiError, ApiRejectResponse, ApiSuccessReportWithOffset } from 'global/lib/api/restClient'
import { buildReportQueryFor } from 'global/redux/features/dataTables/buildQueryForTable'
import {
  AttacksPerDayReport,
  AttacksBySenderReport,
  AttacksByServiceReport,
  AttacksByTaxonomyReport,
  AttacksByDomainReport,
  AttacksByIdentityReport,
  AttacksBySubjectReport,
  CompanyDomainsReport,
  SpAttacksReport,
  ListFeedbackReport,
  MonitoredIdentitiesReport,
  CanSendReplyToAttack,
  EmployeeDefaults
} from 'global/types/api/sp'

import apiRoutes from 'sen/lib/api/apiRoutes'
import { RootState } from 'sen/redux/toolkit/store'
import * as T from 'sen/redux/features/sp/spTypes'

function generateDoGetAttacks(customQuery: T.ReportQuery = {}) {
  return async function doGetAttacks(_: any, { rejectWithValue, getState }: any) {
    try {
      const query = buildReportQueryFor((getState() as RootState).dataTables.spAttacks, true)

      const resp = await restClient(apiRoutes.GET_ATTACKS, {
        data: { query: { ...query, ...customQuery } }
      })

      return { report: resp.data, offset: query.offset }
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
}

export const getAttacksPerDay = createAsyncThunk<AttacksPerDayReport, T.GetAttacksPerDayPayload, ApiRejectResponse>(
  'SP/getAttacksPerDay',
  async function doGetAttacksPerDay(payload, { rejectWithValue }) {
    try {
      const resp = await restClient(apiRoutes.ATTACKS_PER_DAY, {
        data: {
          query: {
            limit: T.CHART_LIMIT,
            ...T.REPORT_QUERY,
            ...(payload || {})
          }
        }
      })

      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getAttacksBySender = createAsyncThunk<AttacksBySenderReport, T.GetAttacksPerDayPayload, ApiRejectResponse>(
  'SP/getAttacksBySender',
  async function doGetAttacksBySender(payload, { rejectWithValue }) {
    try {
      const resp = await restClient(apiRoutes.ATTACKS_BY_SENDER, {
        data: {
          query: {
            limit: T.CHART_LIMIT,
            ...T.REPORT_QUERY,
            ...(payload || {})
          }
        }
      })

      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getAttacksByService = createAsyncThunk<
  AttacksByServiceReport,
  T.GetAttacksBySercvicePayload,
  ApiRejectResponse
>('SP/getAttacksByService', async function doGetAttacksByService(payload, { rejectWithValue }) {
  try {
    const resp = await restClient(apiRoutes.ATTACKS_BY_SERVICE, {
      data: {
        query: {
          limit: T.CHART_LIMIT,
          ...T.REPORT_QUERY,
          ...(payload || {})
        }
      }
    })

    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const getAttacksByTaxonomy = createAsyncThunk<
  AttacksByTaxonomyReport,
  T.GetAttacksByTaxonomyPayload,
  ApiRejectResponse
>('SP/getAttacksByTaxonomy', async function doGetAttacksByTaxonomy(payload, { rejectWithValue }) {
  try {
    const resp = await restClient(apiRoutes.ATTACKS_BY_TAXONOMY_PERIODIC, {
      data: {
        query: {
          limit: T.CHART_LIMIT,
          ...T.REPORT_QUERY,
          ...(payload || {})
        }
      }
    })

    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const getAttacksByIdentity = createAsyncThunk<
  ApiSuccessReportWithOffset<AttacksByIdentityReport>,
  T.GetAttacksByDomainPayload,
  ApiRejectResponse
>('SP/getAttacksByIdentity', async function doGetAttacksByIdentity(payload, { rejectWithValue, getState }) {
  try {
    const query = buildReportQueryFor((getState() as RootState).dataTables.attacksByIdentity, true)

    const resp = await restClient(apiRoutes.ATTACKS_BY_IDENTITY, {
      data: { query: { ...query, ...(payload || {}) } }
    })

    return { report: resp.data, offset: query.offset }
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const getAttacksByDomain = createAsyncThunk<
  ApiSuccessReportWithOffset<AttacksByDomainReport>,
  T.GetAttacksByIdentityPayload,
  ApiRejectResponse
>('SP/getAttacksByDomain', async function doGetAttacksByDomain(payload, { rejectWithValue, getState }) {
  try {
    const query = buildReportQueryFor((getState() as RootState).dataTables.attacksByDomain, true)

    const resp = await restClient(apiRoutes.ATTACKS_BY_DOMAIN, {
      data: { query: { ...query, ...(payload || {}) } }
    })

    return { report: resp.data, offset: query.offset }
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const getAttacksBySubject = createAsyncThunk<
  ApiSuccessReportWithOffset<AttacksBySubjectReport>,
  T.GetAttacksBySubjectPayload,
  ApiRejectResponse
>('SP/getAttacksBySubject', async function doGetAttacksBySubject(payload, { rejectWithValue, getState }) {
  try {
    const query = buildReportQueryFor((getState() as RootState).dataTables.attacksBySubject, true)

    const resp = await restClient(apiRoutes.ATTACKS_BY_SUBJECT, {
      data: { query: { ...query, ...(payload || {}) } }
    })

    return { report: resp.data, offset: query.offset }
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const getCompanyDomains = createAsyncThunk<CompanyDomainsReport, undefined, ApiRejectResponse>(
  'SP/getCompanyDomains',
  async function doGetCompanyDomains(_, { rejectWithValue }) {
    try {
      const resp = await restClient(apiRoutes.COMPANY_DOMAINS, {
        data: {
          query: { ...T.REPORT_QUERY }
        }
      })

      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getAttacks = createAsyncThunk<ApiSuccessReportWithOffset<SpAttacksReport>, undefined, ApiRejectResponse>(
  'SP/getAttacks',
  generateDoGetAttacks()
)

export const getBootstrappedAttacks = createAsyncThunk<
  ApiSuccessReportWithOffset<SpAttacksReport>,
  undefined,
  ApiRejectResponse
>('SP/getBootstrappedAttacks', generateDoGetAttacks({ filter: T.CLEANUP_FILTER }))

export const getAttacksToRemediate = createAsyncThunk<
  ApiSuccessReportWithOffset<SpAttacksReport>,
  undefined,
  ApiRejectResponse
>('SP/getAttacksToRemediate', generateDoGetAttacks({ filter: T.REMEDIATED_FILTER }))

export const getFilterAttacks = createAsyncThunk<
  ApiSuccessReportWithOffset<SpAttacksReport>,
  undefined,
  ApiRejectResponse
>('SP/getFilterAttacks', async function doGetFilterAttacks(_, { rejectWithValue, getState }) {
  try {
    const query = buildReportQueryFor((getState() as RootState).dataTables.filterAttacks, true)

    const resp = await restClient(apiRoutes.GET_ATTACKS, {
      data: { query: { ...query } }
    })

    return { report: resp.data, offset: query.offset }
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const getListFeedback = createAsyncThunk<ListFeedbackReport, T.GetListFeedbackPayload, ApiRejectResponse>(
  'SP/listFeedback',
  async function doGetListFeedback(payload, { rejectWithValue }) {
    try {
      const resp = await restClient(apiRoutes.GET_LIST_FEEDBACK, {
        data: { spThreatIds: payload }
      })

      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getMonitoredIdentities = createAsyncThunk<
  ApiSuccessReportWithOffset<MonitoredIdentitiesReport>,
  undefined,
  ApiRejectResponse
>('SP/getMonitoredIdentities', async function doGetMonitoredIdentities(_, { rejectWithValue, getState }) {
  try {
    const query = buildReportQueryFor((getState() as RootState).dataTables.mailboxes, true)

    const resp = await restClient(apiRoutes.GET_MONITORED_IDENTITIES, {
      data: { query: { ...query } }
    })

    return { report: resp.data, offset: query.offset }
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const canSendReplyToAttack = createAsyncThunk<
  CanSendReplyToAttack,
  T.CanSendReplyToAttackActionPayload | undefined,
  ApiRejectResponse
>('SP/canSendReplyToAttack', async function doCanSendReplyToAttack(payload, { rejectWithValue }) {
  try {
    const resp = await restClient(apiRoutes.CAN_SEND_REPLY_TO, {
      urlParams: { provider: payload?.provider || config.CLOUD_PROVIDERS.office365.id },
      data: {}
    })

    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const getEmployeeDefaults = createAsyncThunk<
  EmployeeDefaults,
  T.GetEmployeeDefaultsPayload | undefined,
  ApiRejectResponse
>('SP/getEmployeeDefaults', async function doGetEmployeeDefaults(payload, { rejectWithValue }) {
  try {
    const resp = await restClient(apiRoutes.GET_EMPLOYEE_DEFAULTS, {
      data: { query: { ...T.EMPLOYEE_PAGINATION, ...(payload || {}) } }
    })

    return resp.data.results
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const testAttack = createAsyncThunk<undefined, T.TestAttackPayload, ApiRejectResponse>(
  'SP/testAttack',
  async function doTestAttack(payload, { rejectWithValue }) {
    try {
      const resp = await restClient(apiRoutes.TEST_ATTACK, {
        data: { ...payload }
      })

      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)
