import React from 'react'
import PropTypes from 'prop-types'

import { range } from 'lodash'
import { v4 as uuidv4 } from 'uuid'

import {
  Link,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Zoom
} from '@barracuda-internal/bds-core'

import DEFAULTS from 'global/configs/theme/defaults'
import * as configurationLib from 'global/lib/configuration/configuration'
import { config } from 'global/lib/config'

import styles from 'sen/components/lib/dialogs/manageUsersDialog/manageUsersDialogstyles'
import { useFormatMessage } from 'global/lib/localization'

export interface ManageUsersDialogProps {
  open: boolean
  onClose: () => void
}

const BASE_I18N_KEY = 'sen.app.navbar.manage_users_dialog'

export const ManageUsersDialog: React.FC<ManageUsersDialogProps> = ({ open, onClose }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()

  return (
    <Dialog
      style={{ zIndex: DEFAULTS.SECONDARY_DIALOGS_ZINDEX }}
      maxWidth="md"
      fullWidth
      open={open}
      TransitionComponent={Zoom}
    >
      <DialogTitle>
        <Typography className={classes.dialogTitle}>{formatMessage('title')}</Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography className={classes.subtitle}>{formatMessage('subtitle')}</Typography>
        {range(1, 5).map((idx: number) => (
          <Typography key={idx}>
            {idx}.&nbsp;
            {formatMessage(`step_${idx}`, {
              b: (txt: any) => <b key={uuidv4()}>{txt}</b>,
              bccLink: () => (
                <Link key="bccLink" target="_blank" href={configurationLib.getBCCDomain()}>
                  {configurationLib.getBCCDomain()}
                </Link>
              ),
              entitlementsLink: (txt: string) => (
                <Link key="bccLink" target="_blank" href={config.LINKS.CUDA_ENTITLEMENTS}>
                  {txt}
                </Link>
              )
            })}
          </Typography>
        ))}
        <Typography className={classes.footer}>{formatMessage('footer')}</Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" size="large" onClick={onClose}>
          &nbsp;&nbsp;{formatMessage('dismiss')}&nbsp;&nbsp;
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ManageUsersDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default ManageUsersDialog
