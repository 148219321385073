import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { DialogContent, Zoom, Tab, Tabs } from '@barracuda-internal/bds-core'

import DEFAULTS from 'global/configs/theme/defaults'
import TabPanel from 'global/components/lib/tabPanel/TabPanel'

import SpearPhishingTab from 'sen/components/lib/dialogs/settings/spearPhishingTab/SpearPhishingTab'
import AtoTab from 'sen/components/lib/dialogs/settings/atoTab/AtoTab'
import AllowedSendersTab from 'sen/components/lib/dialogs/settings/allowedSendersTab/AllowedSendersTab'
import SyslogIntegrationTab from 'sen/components/lib/dialogs/settings/syslogIntegrationTab/SyslogIntegrationTab'
import useSettingsDialogLogic from 'sen/components/lib/dialogs/settings/useSettingsDialogLogic'
import styles, { Dialog } from 'sen/components/lib/dialogs/settings/settingsDialogStyles'
import { useFormatMessage } from 'global/lib/localization'

export interface SettingsDialogProps {
  onClose: () => void
}

const BASE_I18N_KEY = 'sen.app.settings_dialog'

export const SettingsDialog: React.FC<SettingsDialogProps> = ({ onClose }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()
  const [tabsConfig] = useSettingsDialogLogic()

  return useMemo(
    () => (
      <Dialog style={{ zIndex: DEFAULTS.DIALOGS_ZINDEX }} fullWidth open TransitionComponent={Zoom}>
        <DialogContent>
          <Zoom in>
            <Tabs
              className={classes.tabs}
              textColor="primary"
              value={tabsConfig.selectedTab as number}
              onChange={tabsConfig.onTabSelected}
              classes={{
                indicator: classes.indicator
              }}
            >
              {tabsConfig.tabs.map((tab: string) => (
                <Tab classes={{ textColorPrimary: classes.tab }} label={formatMessage(`tabs.${tab}`)} key={tab} />
              ))}
            </Tabs>
          </Zoom>

          {[SpearPhishingTab, AtoTab, AllowedSendersTab, SyslogIntegrationTab].map((TabComponent: any, index) => (
            <TabPanel
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              index={index}
              value={tabsConfig.selectedTab as number}
            >
              <TabComponent onClose={onClose} />
            </TabPanel>
          ))}
        </DialogContent>
      </Dialog>
    ),
    [formatMessage, classes, onClose, tabsConfig]
  )
}

SettingsDialog.propTypes = {
  onClose: PropTypes.func.isRequired
}

export default SettingsDialog
