import React from 'react'

import ContentLayout from 'sen/components/lib/layout/ContentLayout'
import DashboardHeader from 'sen/components/pages/dashboard/header/DashboardHeader'
import DashboardContent from 'sen/components/pages/dashboard/content/DashboardContent'
import ScanInProgress from 'sen/components/pages/dashboard/scanInProgress/ScanInProgress'

import sentintelContentLoader from 'sen/components/lib/useSentinelContentCondition/SentinelContentLoader'

export default sentintelContentLoader({
  scanInProgress: (_, userEmail) => (
    <ContentLayout data-field="content-layout">
      <DashboardHeader data-field="dashboard-header" scanInProgress />
      <ScanInProgress userEmail={userEmail} />
    </ContentLayout>
  ),
  default: () => (
    <>
      <DashboardHeader data-field="dashboard-header" />
      <DashboardContent data-field="dashboard-content" />
    </>
  )
})
