import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import reducerHandlers from 'global/redux/features/dataTables/reducerHandlers'
import { UpdateDataTable } from 'global/types/dataTables/dataTables'

import { DataTableState, ColumnsConfig, SortFields, GridColumns } from 'sen/redux/types/dataTables'

export const GRID_COLUMNS: GridColumns = {
  DATE: 'date',
  EMPLOYEE: 'employee',
  EMAIL: 'email',
  ACTION: 'action'
}

export const FILTER_FIELD = {
  DOMAIN: 'senderDomain',
  SUBJECT: 'subject',
  EMPLOYEE: 'displayName'
}

export const SORT_FIELDS: SortFields = {
  [GRID_COLUMNS.RECEIVED]: 'date',
  [GRID_COLUMNS.RECIPIENT]: 'displayName',
  [GRID_COLUMNS.EMAIL]: 'subject'
}

export const COLUMNS_CONFIG: ColumnsConfig = {
  [GRID_COLUMNS.RECEIVED]: {
    sortable: true,
    orderIndex: 0,
    show: true
  },
  [GRID_COLUMNS.RECIPIENT]: {
    sortable: true,
    orderIndex: 1,
    show: true
  },
  [GRID_COLUMNS.EMAIL]: {
    sortable: true,
    orderIndex: 2,
    show: true
  },
  [GRID_COLUMNS.ACTION]: {
    sortable: false,
    orderIndex: 3,
    show: true
  }
}

const ITEMS_PER_PAGE = 5

// Store
export const INITIAL_STATE: DataTableState = {
  GRID_COLUMNS,
  SORT_FIELDS,
  ITEMS_PER_PAGE,
  FILTER_FIELD,
  columnsConfig: COLUMNS_CONFIG,
  skip: 0, // offset
  take: ITEMS_PER_PAGE, // limit
  filter: null,
  search: '',
  sort: [{ field: GRID_COLUMNS.DATE, dir: 'desc' }]
}

export const filterAttacksSlice = createSlice({
  name: 'DATA_TABLE/FILTER_ATTACKS',
  initialState: INITIAL_STATE,
  reducers: {
    update: {
      reducer: (state: DataTableState, action: PayloadAction<{ config: UpdateDataTable }>) => {
        return reducerHandlers({ COLUMNS_CONFIG }).update({ state, payload: action.payload })
      },
      prepare: (config: UpdateDataTable) => ({
        payload: { config }
      })
    },
    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  }
})

export const { update, reset } = filterAttacksSlice.actions

export default filterAttacksSlice.reducer
