import { createSlice, isAnyOf } from '@reduxjs/toolkit'

import * as automatedWorkflows from 'fir/redux/features/dataTables/automatedWorkflows/automatedWorkflowsSlice'
import * as automatedWorkflowDetails from 'fir/redux/features/dataTables/automatedWorkflowDetails/automatedWorkflowDetailsSlice'
import * as clickedLinksByIncident from 'fir/redux/features/dataTables/clickedLinksByIncident/clickedLinksByIncidentSlice'
import * as emailsByIncident from 'fir/redux/features/dataTables/emailsByIncident/emailsByIncidentSlice'
import * as emailsByRegion from 'fir/redux/features/dataTables/emailsByRegion/emailsByRegionSlice'
import * as emailsByRegionCountry from 'fir/redux/features/dataTables/emailsByRegionCountry/emailsByRegionCountrySlice'
import * as incidents from 'fir/redux/features/dataTables/incidents/incidentsSlice'
import * as newIncident from 'fir/redux/features/dataTables/newIncident/newIncidentSlice'
import * as threatDetailsByIncident from 'fir/redux/features/dataTables/threatDetailsByIncident/threatDetailsByIncidentSlice'
import * as unifiedReporting from 'fir/redux/features/dataTables/unifiedReporting/unifiedReportingSlice'
import * as userDetailsByIncident from 'fir/redux/features/dataTables/userDetailsByIncident/userDetailsByIncidentSlice'
import * as userReported from 'fir/redux/features/dataTables/userReported/userReportedSlice'

import { reset as resetDataTables } from 'global/redux/features/dataTables/dataTablesSlice'

// initialState
export const INITIAL_STATE = {
  automatedWorkflows: automatedWorkflows.INITIAL_STATE,
  automatedWorkflowDetails: automatedWorkflowDetails.INITIAL_STATE,
  clickedLinksByIncident: clickedLinksByIncident.INITIAL_STATE,
  emailsByIncident: emailsByIncident.INITIAL_STATE,
  emailsByRegion: emailsByRegion.INITIAL_STATE,
  emailsByRegionCountry: emailsByRegionCountry.INITIAL_STATE,
  incidents: incidents.INITIAL_STATE,
  newIncident: newIncident.INITIAL_STATE,
  threatDetailsByIncident: threatDetailsByIncident.INITIAL_STATE,
  unifiedReporting: unifiedReporting.INITIAL_STATE,
  userDetailsByIncident: userDetailsByIncident.INITIAL_STATE,
  userReported: userReported.INITIAL_STATE
}

/* eslint-disable no-param-reassign */
export const dataTablesSlice = createSlice({
  name: 'DATA_TABLES',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(resetDataTables.toString(), () => {
        return {
          ...INITIAL_STATE
        }
      })
      .addMatcher(isAnyOf(automatedWorkflows.update, automatedWorkflows.reset), (state, action) => {
        state.automatedWorkflows = { ...automatedWorkflows.default(state.automatedWorkflows, action) }
      })
      .addMatcher(isAnyOf(automatedWorkflowDetails.update, automatedWorkflowDetails.reset), (state, action) => {
        state.automatedWorkflowDetails = { ...automatedWorkflowDetails.default(state.automatedWorkflowDetails, action) }
      })
      .addMatcher(isAnyOf(clickedLinksByIncident.update, clickedLinksByIncident.reset), (state, action) => {
        state.clickedLinksByIncident = { ...clickedLinksByIncident.default(state.clickedLinksByIncident, action) }
      })
      .addMatcher(isAnyOf(emailsByIncident.update, emailsByIncident.reset), (state, action) => {
        state.emailsByIncident = { ...emailsByIncident.default(state.emailsByIncident, action) }
      })
      .addMatcher(isAnyOf(emailsByRegion.update, emailsByRegion.reset), (state, action) => {
        state.emailsByRegion = { ...emailsByRegion.default(state.emailsByRegion, action) }
      })
      .addMatcher(isAnyOf(emailsByRegionCountry.update, emailsByRegionCountry.reset), (state, action) => {
        state.emailsByRegionCountry = { ...emailsByRegionCountry.default(state.emailsByRegionCountry, action) }
      })
      .addMatcher(isAnyOf(incidents.update, incidents.reset), (state, action) => {
        state.incidents = { ...incidents.default(state.incidents, action) }
      })
      .addMatcher(isAnyOf(newIncident.update, newIncident.reset), (state, action) => {
        state.newIncident = { ...newIncident.default(state.newIncident, action) }
      })
      .addMatcher(isAnyOf(threatDetailsByIncident.update, threatDetailsByIncident.reset), (state, action) => {
        state.threatDetailsByIncident = { ...threatDetailsByIncident.default(state.threatDetailsByIncident, action) }
      })
      .addMatcher(isAnyOf(unifiedReporting.update, unifiedReporting.reset), (state, action) => {
        state.unifiedReporting = { ...unifiedReporting.default(state.unifiedReporting, action) }
      })
      .addMatcher(isAnyOf(userDetailsByIncident.update, userDetailsByIncident.reset), (state, action) => {
        state.userDetailsByIncident = { ...userDetailsByIncident.default(state.userDetailsByIncident, action) }
      })
      .addMatcher(isAnyOf(userReported.update, userReported.reset), (state, action) => {
        state.userReported = { ...userReported.default(state.userReported, action) }
      })
  }
})
/* eslint-enable no-param-reassign */

export default dataTablesSlice.reducer
