/* eslint-disable no-unused-expressions */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { union } from 'lodash'

import {
  AttacksPerDayReport,
  AttacksBySenderReport,
  AttacksByServiceReport,
  AttacksByTaxonomyReport,
  AttacksByIdentityReport,
  AttacksByDomainReport,
  AttacksBySubjectReport,
  CompanyDomainsReport,
  SpAttacksReport,
  ListFeedbackReport,
  MonitoredIdentitiesReport,
  CanSendReplyToAttack,
  EmployeeDefaults
} from 'global/types/api/sp'
import { inIdle, inProgress, successResponse, failedResponse, ApiStatus } from 'global/redux/toolkit/api'
import insertToArray from 'global/lib/insertToArray'
import { resetReport, HardResetReport } from 'global/redux/features/reports/reducerHandlers'

import { RootState } from 'sen/redux/toolkit/store'
import * as T from 'sen/redux/features/sp/spTypes'
import {
  getAttacksPerDay,
  getAttacksBySender,
  getAttacksByService,
  getAttacksByTaxonomy,
  getAttacksByIdentity,
  getAttacksByDomain,
  getAttacksBySubject,
  getCompanyDomains,
  getAttacks,
  getBootstrappedAttacks,
  getAttacksToRemediate,
  getFilterAttacks,
  getListFeedback,
  getMonitoredIdentities,
  canSendReplyToAttack,
  getEmployeeDefaults,
  testAttack
} from 'sen/redux/features/sp/spApiThunks'
import {
  update as updateSpAttacksTable,
  INITIAL_STATE as spAttacksTableInitialState
} from 'sen/redux/features/dataTables/spAttacks/spAttacksSlice'
import {
  update as updateFilterAttacksTable,
  INITIAL_STATE as filterAttacksTableInitialState
} from 'sen/redux/features/dataTables/filterAttacks/filterAttacksSlice'
import {
  update as updateMailboxesTable,
  INITIAL_STATE as mailboxesTableInitialState
} from 'sen/redux/features/dataTables/mailboxes/mailboxesSlice'
import {
  update as updateAttacksByDomainTable,
  INITIAL_STATE as attacksByDomainTableInitialState
} from 'sen/redux/features/dataTables/attacksByDomain/attacksByDomainSlice'
import {
  update as updateAttacksByIdentityTable,
  INITIAL_STATE as attacksByIdentityTableInitialState
} from 'sen/redux/features/dataTables/attacksByIdentity/attacksByIdentitySlice'
import {
  update as updateAttacksBySubjectTable,
  INITIAL_STATE as attacksBySubjectTableInitialState
} from 'sen/redux/features/dataTables/attacksBySubject/attacksBySubjectSlice'

export interface SpState {
  attacksPerDay: AttacksPerDayReport | undefined
  getAttacksPerDayApiStatus: ApiStatus
  attacksBySender: AttacksBySenderReport | undefined
  getAttacksBySenderApiStatus: ApiStatus
  attacksByService: AttacksByServiceReport | undefined
  getAttacksByServiceApiStatus: ApiStatus
  attacksByTaxonomy: AttacksByTaxonomyReport | undefined
  getAttacksByTaxonomyApiStatus: ApiStatus
  attacksByDomain: AttacksByDomainReport | undefined
  getAttacksByDomainApiStatus: ApiStatus
  attacksByDomainLoadedOffsets: number[]
  attacksByIdentity: AttacksByIdentityReport | undefined
  getAttacksByIdentityApiStatus: ApiStatus
  attacksByIdentityLoadedOffsets: number[]
  attacksBySubject: AttacksBySubjectReport | undefined
  getAttacksBySubjectApiStatus: ApiStatus
  attacksBySubjectLoadedOffsets: number[]
  companyDomains: CompanyDomainsReport | undefined
  getCompanyDomainsApiStatus: ApiStatus
  attacks: SpAttacksReport | undefined
  getAttacksApiStatus: ApiStatus
  attacksLoadedOffsets: number[]
  bootstrappedAttacks: SpAttacksReport | undefined
  getBootstrappedAttacksApiStatus: ApiStatus
  attacksToRemediate: SpAttacksReport | undefined
  getAttacksToRemediateApiStatus: ApiStatus
  filterAttacks: SpAttacksReport | undefined
  getFilterAttacksApiStatus: ApiStatus
  filterAttacksLoadedOffsets: number[]
  listFeedback: ListFeedbackReport | undefined
  getListFeedbackApiStatus: ApiStatus
  monitoredIdentities: MonitoredIdentitiesReport | undefined
  getMonitoredIdentitiesApiStatus: ApiStatus
  monitoredIdentitiesLoadedOffsets: number[]
  canSendReplyToAttack: CanSendReplyToAttack | undefined
  canSendReplyToAttackApiStatus: ApiStatus
  employeeDefaults: EmployeeDefaults | undefined
  getEmployeeDefaultsApiStatus: ApiStatus
  testAttackApiStatus: ApiStatus
}

// initialState
export const INITIAL_STATE: SpState = {
  attacksPerDay: undefined,
  getAttacksPerDayApiStatus: inIdle,
  attacksBySender: undefined,
  getAttacksBySenderApiStatus: inIdle,
  attacksByService: undefined,
  getAttacksByServiceApiStatus: inIdle,
  attacksByTaxonomy: undefined,
  getAttacksByTaxonomyApiStatus: inIdle,
  attacksByDomain: undefined,
  getAttacksByDomainApiStatus: inIdle,
  attacksByDomainLoadedOffsets: [],
  attacksByIdentity: undefined,
  getAttacksByIdentityApiStatus: inIdle,
  attacksByIdentityLoadedOffsets: [],
  attacksBySubject: undefined,
  getAttacksBySubjectApiStatus: inIdle,
  attacksBySubjectLoadedOffsets: [],
  companyDomains: undefined,
  getCompanyDomainsApiStatus: inIdle,
  attacks: undefined,
  getAttacksApiStatus: inIdle,
  attacksLoadedOffsets: [],
  bootstrappedAttacks: undefined,
  getBootstrappedAttacksApiStatus: inIdle,
  attacksToRemediate: undefined,
  getAttacksToRemediateApiStatus: inIdle,
  filterAttacks: undefined,
  getFilterAttacksApiStatus: inIdle,
  filterAttacksLoadedOffsets: [],
  listFeedback: undefined,
  getListFeedbackApiStatus: inIdle,
  monitoredIdentities: undefined,
  getMonitoredIdentitiesApiStatus: inIdle,
  monitoredIdentitiesLoadedOffsets: [],
  canSendReplyToAttack: undefined,
  canSendReplyToAttackApiStatus: inIdle,
  employeeDefaults: undefined,
  getEmployeeDefaultsApiStatus: inIdle,
  testAttackApiStatus: inIdle
}

const resetAttacksByIdentity = createAsyncThunk<number, HardResetReport>(
  'SP/resetAttacksByIdentity',
  async (_, { getState }) => {
    return (getState() as RootState).dataTables.attacksByIdentity.skip
  }
)

const resetAttacksByDomain = createAsyncThunk<number, HardResetReport>(
  'SP/resetAttacksByDomain',
  async (_, { getState }) => {
    return (getState() as RootState).dataTables.attacksByDomain.skip
  }
)

const resetAttacksBySubject = createAsyncThunk<number, HardResetReport>(
  'SP/resetAttacksBySubject',
  async (_, { getState }) => {
    return (getState() as RootState).dataTables.attacksBySubject.skip
  }
)

const resetFilterAttacks = createAsyncThunk<number, HardResetReport>(
  'SP/resetFilterAttacks',
  async (_, { getState }) => {
    return (getState() as RootState).dataTables.filterAttacks.skip
  }
)

const resetAttacks = createAsyncThunk<number, HardResetReport>('SP/resetAttacks', async (_, { getState }) => {
  return (getState() as RootState).dataTables.spAttacks.skip
})

/* eslint-disable no-param-reassign */
export const spSlice = createSlice({
  name: 'SP',
  initialState: INITIAL_STATE,
  reducers: {
    resetAttacksPerDay: state => {
      state.attacksPerDay = INITIAL_STATE.attacksPerDay
      state.getAttacksPerDayApiStatus = INITIAL_STATE.getAttacksPerDayApiStatus
    },

    resetAttacksBySender: state => {
      state.attacksBySender = INITIAL_STATE.attacksBySender
      state.getAttacksBySenderApiStatus = INITIAL_STATE.getAttacksBySenderApiStatus
    },

    resetAttacksByService: state => {
      state.attacksByService = INITIAL_STATE.attacksByService
      state.getAttacksByServiceApiStatus = INITIAL_STATE.getAttacksByServiceApiStatus
    },

    resetAttacksByTaxonomy: state => {
      state.attacksByTaxonomy = INITIAL_STATE.attacksByTaxonomy
      state.getAttacksByTaxonomyApiStatus = INITIAL_STATE.getAttacksByTaxonomyApiStatus
    },

    resetCompanyDomains: state => {
      state.companyDomains = INITIAL_STATE.companyDomains
      state.getCompanyDomainsApiStatus = INITIAL_STATE.getCompanyDomainsApiStatus
    },

    resetBootstrappedAttacks: state => {
      state.bootstrappedAttacks = INITIAL_STATE.bootstrappedAttacks
      state.getBootstrappedAttacksApiStatus = INITIAL_STATE.getBootstrappedAttacksApiStatus
      state.attacksLoadedOffsets = INITIAL_STATE.attacksLoadedOffsets
    },

    resetAttacksToRemediate: state => {
      state.attacksToRemediate = INITIAL_STATE.attacksToRemediate
      state.getAttacksToRemediateApiStatus = INITIAL_STATE.getAttacksToRemediateApiStatus
      state.attacksLoadedOffsets = INITIAL_STATE.attacksLoadedOffsets
    },

    resetFilterAttacks: state => {
      state.filterAttacks = INITIAL_STATE.filterAttacks
      state.getFilterAttacksApiStatus = INITIAL_STATE.getFilterAttacksApiStatus
      state.filterAttacksLoadedOffsets = INITIAL_STATE.filterAttacksLoadedOffsets
    },

    resetListFeedback: state => {
      state.listFeedback = INITIAL_STATE.listFeedback
      state.getListFeedbackApiStatus = INITIAL_STATE.getListFeedbackApiStatus
    },

    resetMonitoredIdentities: (state, action: PayloadAction<T.ResetMonitoredIdentitesPayload>) => {
      if (!!action.payload || !state.monitoredIdentities) {
        state.monitoredIdentities = INITIAL_STATE.monitoredIdentities
      } else {
        state.monitoredIdentities.report.data = []
      }

      state.getMonitoredIdentitiesApiStatus = INITIAL_STATE.getMonitoredIdentitiesApiStatus
      state.monitoredIdentitiesLoadedOffsets = INITIAL_STATE.monitoredIdentitiesLoadedOffsets
    },

    resetCanSendReplyToAttack: state => {
      state.canSendReplyToAttack = INITIAL_STATE.canSendReplyToAttack
      state.canSendReplyToAttackApiStatus = INITIAL_STATE.canSendReplyToAttackApiStatus
    },

    resetEmployeeDefaults: state => {
      state.employeeDefaults = INITIAL_STATE.employeeDefaults
      state.getEmployeeDefaultsApiStatus = INITIAL_STATE.getEmployeeDefaultsApiStatus
    },

    resetTestAttack: state => {
      state.testAttackApiStatus = INITIAL_STATE.testAttackApiStatus
    },

    // resetDmarc
    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  },
  extraReducers: builder => {
    builder
      // resetAttacks
      .addCase(resetAttacks.fulfilled, (state, action) => {
        const doHardReset = action.meta.arg

        state.attacks = resetReport(state.attacks, doHardReset) || INITIAL_STATE.attacks
        state.attacksLoadedOffsets = doHardReset ? INITIAL_STATE.attacksLoadedOffsets : [action.payload]
        state.getAttacksApiStatus = doHardReset ? INITIAL_STATE.getAttacksApiStatus : state.getAttacksApiStatus
      })

      // resetAttacksByIdentity
      .addCase(resetAttacksByIdentity.fulfilled, (state, action) => {
        const doHardReset = action.meta.arg

        state.attacksByIdentity = resetReport(state.attacksByIdentity, doHardReset) || INITIAL_STATE.attacksByIdentity
        state.attacksByIdentityLoadedOffsets = doHardReset
          ? INITIAL_STATE.attacksByIdentityLoadedOffsets
          : [action.payload]
        state.getAttacksByIdentityApiStatus = doHardReset
          ? INITIAL_STATE.getAttacksByIdentityApiStatus
          : state.getAttacksByIdentityApiStatus
      })

      // resetAttacksByDomain
      .addCase(resetAttacksByDomain.fulfilled, (state, action) => {
        const doHardReset = action.meta.arg

        state.attacksByDomain = resetReport(state.attacksByDomain, doHardReset) || INITIAL_STATE.attacksByDomain
        state.attacksByDomainLoadedOffsets = doHardReset ? INITIAL_STATE.attacksByDomainLoadedOffsets : [action.payload]
        state.getAttacksByDomainApiStatus = doHardReset
          ? INITIAL_STATE.getAttacksByDomainApiStatus
          : state.getAttacksByDomainApiStatus
      })

      // resetAttacksBySubject
      .addCase(resetAttacksBySubject.fulfilled, (state, action) => {
        const doHardReset = action.meta.arg

        state.attacksBySubject = resetReport(state.attacksBySubject, doHardReset) || INITIAL_STATE.attacksBySubject
        state.attacksBySubjectLoadedOffsets = doHardReset
          ? INITIAL_STATE.attacksBySubjectLoadedOffsets
          : [action.payload]
        state.getAttacksBySubjectApiStatus = doHardReset
          ? INITIAL_STATE.getAttacksBySubjectApiStatus
          : state.getAttacksBySubjectApiStatus
      })

      // resetFilterAttacks
      .addCase(resetFilterAttacks.fulfilled, (state, action) => {
        const doHardReset = action.meta.arg

        state.filterAttacks = resetReport(state.filterAttacks, doHardReset) || INITIAL_STATE.filterAttacks
        state.getFilterAttacksApiStatus = doHardReset
          ? INITIAL_STATE.getFilterAttacksApiStatus
          : state.getFilterAttacksApiStatus
        state.filterAttacksLoadedOffsets = doHardReset ? INITIAL_STATE.filterAttacksLoadedOffsets : [action.payload]
      })

      // getAttacksPerDay
      .addCase(getAttacksPerDay.pending, state => {
        state.getAttacksPerDayApiStatus = inProgress
      })
      .addCase(getAttacksPerDay.fulfilled, (state, action) => {
        state.getAttacksPerDayApiStatus = successResponse
        state.attacksPerDay = action.payload
      })
      .addCase(getAttacksPerDay.rejected, (state, action) => {
        state.getAttacksPerDayApiStatus = failedResponse(action.payload)
      })

      // getAttacksBySender
      .addCase(getAttacksBySender.pending, state => {
        state.getAttacksBySenderApiStatus = inProgress
      })
      .addCase(getAttacksBySender.fulfilled, (state, action) => {
        state.getAttacksBySenderApiStatus = successResponse
        state.attacksBySender = action.payload
      })
      .addCase(getAttacksBySender.rejected, (state, action) => {
        state.getAttacksBySenderApiStatus = failedResponse(action.payload)
      })

      // getAttacksByService
      .addCase(getAttacksByService.pending, state => {
        state.getAttacksByServiceApiStatus = inProgress
      })
      .addCase(getAttacksByService.fulfilled, (state, action) => {
        state.getAttacksByServiceApiStatus = successResponse
        state.attacksByService = action.payload
      })
      .addCase(getAttacksByService.rejected, (state, action) => {
        state.getAttacksByServiceApiStatus = failedResponse(action.payload)
      })

      // getAttacksByTaxonomy
      .addCase(getAttacksByTaxonomy.pending, state => {
        state.getAttacksByTaxonomyApiStatus = inProgress
      })
      .addCase(getAttacksByTaxonomy.fulfilled, (state, action) => {
        state.getAttacksByTaxonomyApiStatus = successResponse
        state.attacksByTaxonomy = action.payload
      })
      .addCase(getAttacksByTaxonomy.rejected, (state, action) => {
        state.getAttacksByTaxonomyApiStatus = failedResponse(action.payload)
      })

      // getAttacksByDomain
      .addCase(updateAttacksByDomainTable, (state, action) => {
        if (action.payload.config?.skip !== undefined) {
          state.attacksByDomainLoadedOffsets = union(state.attacksByDomainLoadedOffsets, [action.payload.config.skip])
        }
      })
      .addCase(getAttacksByDomain.pending, state => {
        state.getAttacksByDomainApiStatus = inProgress
        if (!state.attacksByDomainLoadedOffsets.length) {
          state.attacksByDomainLoadedOffsets = [attacksByDomainTableInitialState.skip]
        }
      })
      .addCase(getAttacksByDomain.fulfilled, (state, action) => {
        const { report, offset } = action.payload

        state.attacksByDomain = {
          ...report,
          report: {
            ...report.report,
            data: insertToArray(state.attacksByDomain?.report?.data || [], report?.report?.data, offset)
          }
        }
        state.getAttacksByDomainApiStatus = successResponse
      })
      .addCase(getAttacksByDomain.rejected, (state, action) => {
        state.getAttacksByDomainApiStatus = failedResponse(action.payload as string)
      })

      // getAttacksByIdentity
      .addCase(updateAttacksByIdentityTable, (state, action) => {
        if (action.payload.config?.skip !== undefined) {
          state.attacksByIdentityLoadedOffsets = union(state.attacksByIdentityLoadedOffsets, [
            action.payload.config.skip
          ])
        }
      })
      .addCase(getAttacksByIdentity.pending, state => {
        state.getAttacksByIdentityApiStatus = inProgress
        if (!state.attacksByIdentityLoadedOffsets.length) {
          state.attacksByIdentityLoadedOffsets = [attacksByIdentityTableInitialState.skip]
        }
      })
      .addCase(getAttacksByIdentity.fulfilled, (state, action) => {
        const { report, offset } = action.payload

        state.attacksByIdentity = {
          ...report,
          report: {
            ...report.report,
            data: insertToArray(state.attacksByIdentity?.report?.data || [], report?.report?.data, offset)
          }
        }
        state.getAttacksByIdentityApiStatus = successResponse
      })
      .addCase(getAttacksByIdentity.rejected, (state, action) => {
        state.getAttacksByIdentityApiStatus = failedResponse(action.payload as string)
      })

      // getAttacksBySubject
      .addCase(updateAttacksBySubjectTable, (state, action) => {
        if (action.payload.config?.skip !== undefined) {
          state.attacksBySubjectLoadedOffsets = union(state.attacksBySubjectLoadedOffsets, [action.payload.config.skip])
        }
      })
      .addCase(getAttacksBySubject.pending, state => {
        state.getAttacksBySubjectApiStatus = inProgress
        if (!state.getAttacksBySubjectApiStatus.length) {
          state.attacksBySubjectLoadedOffsets = [attacksBySubjectTableInitialState.skip]
        }
      })
      .addCase(getAttacksBySubject.fulfilled, (state, action) => {
        const { report, offset } = action.payload

        state.attacksBySubject = {
          ...report,
          report: {
            ...report.report,
            data: insertToArray(state.attacksBySubject?.report?.data || [], report?.report?.data, offset)
          }
        }
        state.getAttacksBySubjectApiStatus = successResponse
      })
      .addCase(getAttacksBySubject.rejected, (state, action) => {
        state.getAttacksBySubjectApiStatus = failedResponse(action.payload as string)
      })

      // getCompanyDomains
      .addCase(getCompanyDomains.pending, state => {
        state.getCompanyDomainsApiStatus = inProgress
      })
      .addCase(getCompanyDomains.fulfilled, (state, action) => {
        state.getCompanyDomainsApiStatus = successResponse
        state.companyDomains = action.payload
      })
      .addCase(getCompanyDomains.rejected, (state, action) => {
        state.getCompanyDomainsApiStatus = failedResponse(action.payload)
      })

      // getAttacks
      .addCase(updateSpAttacksTable, (state, action) => {
        if (action.payload.config?.skip !== undefined) {
          state.attacksLoadedOffsets = union(state.attacksLoadedOffsets, [action.payload.config.skip])
        }
      })
      .addCase(getAttacks.pending, state => {
        state.getAttacksApiStatus = inProgress
        if (!state.attacksLoadedOffsets.length) {
          state.attacksLoadedOffsets = [spAttacksTableInitialState.skip]
        }
      })
      .addCase(getAttacks.fulfilled, (state, action) => {
        const { report, offset } = action.payload

        state.attacks = {
          ...report,
          report: {
            ...report.report,
            data: insertToArray(state.attacks?.report?.data || [], report?.report?.data, offset)
          }
        }
        state.getAttacksApiStatus = successResponse
      })
      .addCase(getAttacks.rejected, (state, action) => {
        state.getAttacksApiStatus = failedResponse(action.payload as string)
      })

      // getBootstrappedAttacks
      .addCase(getBootstrappedAttacks.pending, state => {
        state.getBootstrappedAttacksApiStatus = inProgress
        if (!state.attacksLoadedOffsets.length) {
          state.attacksLoadedOffsets = [spAttacksTableInitialState.skip]
        }
      })
      .addCase(getBootstrappedAttacks.fulfilled, (state, action) => {
        const { report, offset } = action.payload

        state.bootstrappedAttacks = {
          ...report,
          report: {
            ...report.report,
            data: insertToArray(state.bootstrappedAttacks?.report?.data || [], report?.report?.data, offset)
          }
        }
        state.getBootstrappedAttacksApiStatus = successResponse
      })
      .addCase(getBootstrappedAttacks.rejected, (state, action) => {
        state.getBootstrappedAttacksApiStatus = failedResponse(action.payload as string)
      })

      // getAttacksToRemediate
      .addCase(getAttacksToRemediate.pending, state => {
        state.getAttacksToRemediateApiStatus = inProgress
        if (!state.attacksLoadedOffsets.length) {
          state.attacksLoadedOffsets = [spAttacksTableInitialState.skip]
        }
      })
      .addCase(getAttacksToRemediate.fulfilled, (state, action) => {
        const { report, offset } = action.payload

        state.attacksToRemediate = {
          ...report,
          report: {
            ...report.report,
            data: insertToArray(state.attacksToRemediate?.report?.data || [], report?.report?.data, offset)
          }
        }
        state.getAttacksToRemediateApiStatus = successResponse
      })
      .addCase(getAttacksToRemediate.rejected, (state, action) => {
        state.getAttacksToRemediateApiStatus = failedResponse(action.payload as string)
      })

      // getFilterAttacks
      .addCase(updateFilterAttacksTable, (state, action) => {
        if (action.payload.config?.skip !== undefined) {
          state.filterAttacksLoadedOffsets = union(state.filterAttacksLoadedOffsets, [action.payload.config.skip])
        }
      })
      .addCase(getFilterAttacks.pending, state => {
        state.getFilterAttacksApiStatus = inProgress
        if (!state.filterAttacksLoadedOffsets.length) {
          state.filterAttacksLoadedOffsets = [filterAttacksTableInitialState.skip]
        }
      })
      .addCase(getFilterAttacks.fulfilled, (state, action) => {
        const { report, offset } = action.payload

        state.filterAttacks = {
          ...report,
          report: {
            ...report.report,
            data: insertToArray(state.filterAttacks?.report?.data || [], report?.report?.data, offset)
          }
        }
        state.getFilterAttacksApiStatus = successResponse
      })
      .addCase(getFilterAttacks.rejected, (state, action) => {
        state.getFilterAttacksApiStatus = failedResponse(action.payload as string)
      })

      // getListFeedback
      .addCase(getListFeedback.pending, state => {
        state.getListFeedbackApiStatus = inProgress
      })
      .addCase(getListFeedback.fulfilled, (state, action) => {
        state.getListFeedbackApiStatus = successResponse
        state.listFeedback = action.payload
      })
      .addCase(getListFeedback.rejected, (state, action) => {
        state.getListFeedbackApiStatus = failedResponse(action.payload)
      })

      // monitoredIdentities
      .addCase(updateMailboxesTable, (state, action) => {
        if (action.payload.config?.skip !== undefined) {
          state.monitoredIdentitiesLoadedOffsets = union(state.monitoredIdentitiesLoadedOffsets, [
            action.payload.config.skip
          ])
        }
      })
      .addCase(getMonitoredIdentities.pending, state => {
        state.getMonitoredIdentitiesApiStatus = inProgress
        if (!state.monitoredIdentitiesLoadedOffsets.length) {
          state.monitoredIdentitiesLoadedOffsets = [mailboxesTableInitialState.skip]
        }
      })
      .addCase(getMonitoredIdentities.fulfilled, (state, action) => {
        const { report, offset } = action.payload

        state.monitoredIdentities = {
          ...report,
          report: {
            ...report.report,
            data: insertToArray(state.monitoredIdentities?.report?.data || [], report?.report?.data, offset)
          }
        }
        state.getMonitoredIdentitiesApiStatus = successResponse
      })
      .addCase(getMonitoredIdentities.rejected, (state, action) => {
        state.getMonitoredIdentitiesApiStatus = failedResponse(action.payload as string)
      })

      // canSendReplyToAttack
      .addCase(canSendReplyToAttack.pending, state => {
        state.canSendReplyToAttackApiStatus = inProgress
      })
      .addCase(canSendReplyToAttack.fulfilled, (state, action) => {
        state.canSendReplyToAttackApiStatus = successResponse
        state.canSendReplyToAttack = action.payload
      })
      .addCase(canSendReplyToAttack.rejected, (state, action) => {
        state.canSendReplyToAttackApiStatus = failedResponse(action.payload)
      })

      // getEmployeeDefaults
      .addCase(getEmployeeDefaults.pending, state => {
        state.getEmployeeDefaultsApiStatus = inProgress
      })
      .addCase(getEmployeeDefaults.fulfilled, (state, action) => {
        state.getEmployeeDefaultsApiStatus = successResponse
        state.employeeDefaults = action.payload
      })
      .addCase(getEmployeeDefaults.rejected, (state, action) => {
        state.getEmployeeDefaultsApiStatus = failedResponse(action.payload)
      })

      // testAttack
      .addCase(testAttack.pending, state => {
        state.testAttackApiStatus = inProgress
      })
      .addCase(testAttack.fulfilled, state => {
        state.testAttackApiStatus = successResponse
      })
      .addCase(testAttack.rejected, (state, action) => {
        state.testAttackApiStatus = failedResponse(action.payload)
      })
  }
})
/* eslint-enable no-param-reassign */

export const {
  resetAttacksPerDay,
  resetAttacksBySender,
  resetAttacksByService,
  resetAttacksByTaxonomy,
  resetCompanyDomains,
  resetBootstrappedAttacks,
  resetAttacksToRemediate,
  resetListFeedback,
  resetMonitoredIdentities,
  resetCanSendReplyToAttack,
  resetEmployeeDefaults,
  resetTestAttack,
  reset
} = spSlice.actions

export {
  resetAttacks,
  resetAttacksByIdentity,
  resetAttacksByDomain,
  resetAttacksBySubject,
  resetFilterAttacks,
  getAttacksPerDay,
  getAttacksBySender,
  getAttacksByService,
  getAttacksByTaxonomy,
  getAttacksByDomain,
  getAttacksByIdentity,
  getAttacksBySubject,
  getCompanyDomains,
  getAttacks,
  getBootstrappedAttacks,
  getAttacksToRemediate,
  getFilterAttacks,
  getListFeedback,
  getMonitoredIdentities,
  canSendReplyToAttack,
  getEmployeeDefaults,
  testAttack
}

export default spSlice.reducer
