import { useEffect, useMemo, useCallback, useState, BaseSyntheticEvent } from 'react'

import { snakeCase } from 'lodash'

import { isSuccess } from 'global/redux/toolkit/api'
import { CompanyDomain, DomainTotals } from 'global/types/api/dmarc'
import * as analyticsLib from 'global/lib/analytics/analyticsService'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import { getDomainTotals, resetDomainTotals } from 'sen/redux/features/dmarc/dmarcSlice'
import { DmarcReviewTabs } from 'sen/components/pages/domainFraudDmarcReview/domainFraudDmarcReviewTypes'
import routesConfig from 'sen/lib/routes/routesConfig'

export interface UseDmarcReviewSourcesLogicProps {
  activeTab: DmarcReviewTabs
  domain: CompanyDomain
}

export interface LogicConfig {
  selectedTab: DmarcReviewTabs
  domainTotals: DomainTotals | undefined
  isDomainTotalsLoaded: boolean
}

export interface EventHandlers {
  onTabChange: (e: BaseSyntheticEvent, newTab: DmarcReviewTabs) => void
}

export default function useDmarcReviewSourcesLogic({
  activeTab,
  domain
}: UseDmarcReviewSourcesLogicProps): [LogicConfig, EventHandlers] {
  const { accessTokenId, activePath, domainTotals, isDomainTotalsLoaded } = useAppSelector(_stores => ({
    accessTokenId: _stores.accessToken?.accessToken?.id || '',
    activePath: _stores.app.activePath,
    domainTotals: _stores.dmarc.domainTotals,
    isDomainTotalsLoaded: isSuccess(_stores.dmarc.getDomainTotalsApiStatus)
  }))
  const [selectedTab, setSelectedTab] = useState<DmarcReviewTabs>(activeTab)

  const dispatch = useAppDispatch()

  // init
  useEffect(() => {
    dispatch(getDomainTotals(activePath.params.domain))

    return () => {
      dispatch(resetDomainTotals())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onTabChange: EventHandlers['onTabChange'] = useCallback(
    (_e, newTab) => {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.DF_DOMAINS_DMARC_REVIEW_SELECT_TAB, { tab: newTab, domain })
      setSelectedTab(newTab)
      routesConfig[`DOMAIN_FRAUD_DMARC_REVIEW_WITH_${snakeCase(newTab)}_TAB`].softGoto({
        reportId: accessTokenId,
        domain: domain.name
      })
    },
    [accessTokenId, domain]
  )

  return useMemo(() => {
    return [
      {
        selectedTab,
        domainTotals,
        isDomainTotalsLoaded
      },
      {
        onTabChange
      }
    ]
  }, [selectedTab, onTabChange, domainTotals, isDomainTotalsLoaded])
}
