import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import reducerHandlers from 'global/redux/features/dataTables/reducerHandlers'
import { UpdateDataTable } from 'global/types/dataTables/dataTables'

import { DataTableState, ColumnsConfig, SortFields, GridColumns } from 'sen/redux/types/dataTables'

export const GRID_COLUMNS: GridColumns = {
  EMPLOYEE: 'employee',
  FRAUDULENT_EMAILS_RECEIVED: 'fraudulent_emails_received',
  DETAILS: 'details'
}

export const SORT_FIELDS: SortFields = {
  [GRID_COLUMNS.FRAUDULENT_EMAILS_RECEIVED]: 'spFraudCount'
}

export const COLUMNS_CONFIG: ColumnsConfig = {
  [GRID_COLUMNS.EMPLOYEE]: {
    sortable: false,
    orderIndex: 0,
    show: true
  },
  [GRID_COLUMNS.FRAUDULENT_EMAILS_RECEIVED]: {
    sortable: true,
    orderIndex: 1,
    show: true
  },
  [GRID_COLUMNS.DETAILS]: {
    sortable: false,
    orderIndex: 2,
    show: true
  }
}

const ITEMS_PER_PAGE = 5

// Store
export const INITIAL_STATE: DataTableState = {
  SORT_FIELDS,
  GRID_COLUMNS,
  ITEMS_PER_PAGE,
  columnsConfig: COLUMNS_CONFIG,
  skip: 0, // offset
  take: ITEMS_PER_PAGE, // limit
  filter: null,
  search: '',
  sort: [{ field: GRID_COLUMNS.FRAUDULENT_EMAILS_RECEIVED, dir: 'desc' }]
}

export const attacksByIdentitySlice = createSlice({
  name: 'DATA_TABLE/ATTACKS_BY_IDENTITY',
  initialState: INITIAL_STATE,
  reducers: {
    update: {
      reducer: (state: DataTableState, action: PayloadAction<{ config: UpdateDataTable }>) => {
        return reducerHandlers({ COLUMNS_CONFIG }).update({ state, payload: action.payload })
      },
      prepare: (config: UpdateDataTable) => ({
        payload: { config }
      })
    },
    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  }
})

export const { update, reset } = attacksByIdentitySlice.actions

export default attacksByIdentitySlice.reducer
