import { makeStyles } from '@material-ui/core/styles'

import { COLORS } from 'global/configs/theme/defaults'

import tableStyles, { TABLE_HEADER_HEIGHT, TABLE_FOOTER_HEIGHT } from 'global/components/lib/grid/tableStyles'

const CARD_HEIGHT = 228
export const CELL_HEIGHT = 65
const TABLE_MIN_HEIGHT = CELL_HEIGHT * 10 + TABLE_HEADER_HEIGHT + TABLE_FOOTER_HEIGHT

// TODO: remove text style overrides in favor of BDS variant styles
export default makeStyles(theme => {
  const defaultTableStyles = tableStyles(theme, CELL_HEIGHT)

  const cardCount = { fontWeight: 500, fontSize: '32px', lineHeight: '125%' }
  return {
    title: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    card: {
      position: 'relative',
      height: CARD_HEIGHT,
      padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`
    },
    circularProgress: { marginTop: '45px' },
    cardTitle: { fontWeight: 600, fontSize: '14px', lineHeight: '130%', color: COLORS.SCALE_GRAY_600 },
    cardSubtitle: { fontWeight: 400, fontSize: '14px', lineHeight: '130%', color: COLORS.SCALE_GRAY_600 },
    cardContent: { height: '100%' },
    cardCountTitle: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '130%',
      color: COLORS.SCALE_GRAY_600
    },
    cardCountInfo: {
      ...cardCount,
      color: COLORS.SCALE_GRAY_600
    },
    cardCountWarning: {
      ...cardCount,
      color: COLORS.RED
    },
    cardCountSuccess: {
      ...cardCount,
      color: COLORS.GREEN
    },
    cardDateWrapper: {
      marginBottom: theme.spacing(3)
    },
    cardDate: {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '125%',
      textTransform: 'capitalize',
      color: COLORS.SCALE_GRAY_600,
      letterSpacing: 'normal'
    },
    linkButtonWrapper: {
      fontWeight: 300,
      position: 'absolute',
      bottom: theme.spacing(1),
      right: theme.spacing(1)
    },
    spearPhishingAttacks: {
      marginTop: theme.spacing(5)
    },
    subtitle: {
      marginBottom: theme.spacing(2),
      lineHeight: '10px',
      color: COLORS.GRAY
    },
    tableCard: {
      position: 'relative',
      minHeight: CARD_HEIGHT,
      padding: 0
    },
    tableCircularProgress: {
      paddingTop: theme.spacing(20)
    },
    tableWrapper: {
      padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`
    },
    exportButton: {
      marginBottom: theme.spacing(2)
    },
    pickList: {
      height: 40,
      backgroundColor: COLORS.INPUT_BACKGROUNDS,
      marginLeft: 10,
      marginTop: 0
    },
    disabledPicklist: {
      opacity: 0.1
    },
    pickListIcon: {
      verticalAlign: -2
    },
    pickListText: {
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: '24px',
      display: 'inline-block',
      verticalAlign: 4,
      paddingLeft: 5
    },
    selectItem: {
      width: '100%'
    },
    ...defaultTableStyles,
    tableArea: {
      ...defaultTableStyles.tableArea,
      marginTop: theme.spacing(2),
      minHeight: TABLE_MIN_HEIGHT
    },
    dataTable: {
      ...defaultTableStyles.dataTable,
      height: TABLE_MIN_HEIGHT,

      '& td': {
        lineHeight: `${theme.spacing(2.5)}px`
      }
    },
    icon: {
      verticalAlign: -5,
      color: COLORS.BLACK_50,
      cursor: 'pointer'
    },
    tooltip: {
      backgroundColor: COLORS.BLACK_50,
      fontSize: 12,
      letterSpacing: 0,
      lineHeight: '16px',
      boxShadow: '0 2px 5px 0 rgba(0,0,0,0.26), 0 2px 10px 0 rgba(0,0,0,0.16)'
    },
    deleteButtonWrapper: {
      fontWeight: 300,
      padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`
    }
  }
})
