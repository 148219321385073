import React, { useMemo } from 'react'

import GlobalLicenseContent from 'global/components/features/license/LicenseContent'
import { config } from 'global/lib/config'

const BASE_I18N_KEY = 'sen.app.license'

const LicenseContent: React.FC = () => {
  return useMemo(
    () => (
      <GlobalLicenseContent
        product={config.PRODUCTS.SENTINEL}
        productPrefix={config.PRODUCT_ABBR.SENTINEL}
        BASE_I18N_KEY={BASE_I18N_KEY}
      />
    ),
    []
  )
}

export default LicenseContent
