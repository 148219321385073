import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { MenuItem, ListItemIcon } from '@barracuda-internal/bds-core'
import RecentActorsIcon from '@material-ui/icons/RecentActors'

import styles from 'global/components/lib/layout/navbar/navbarStyles'
import { useFormatMessage } from 'global/lib/localization'
import { NavbarContextMenuContext } from 'global/components/lib/layout/navbar/contextMenu/NavbarContextMenu'

export interface NavbarContextMenuLicenseProps {
  onGoToLicense: () => void
}

const BASE_I18N_KEY = 'app.logoutmenu'

const NavbarContextMenuLicense: React.FC<NavbarContextMenuLicenseProps> = ({ onGoToLicense }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const navbarContextMenuContext = useContext(NavbarContextMenuContext)
  const classes = styles()

  return (
    <MenuItem
      className={classes.navbarMenuContextItem}
      onClick={() => {
        navbarContextMenuContext.closeMenu()
        onGoToLicense()
      }}
    >
      <ListItemIcon>
        <RecentActorsIcon id="review_licenses" />
      </ListItemIcon>
      {formatMessage('review_licenses')}
    </MenuItem>
  )
}

NavbarContextMenuLicense.propTypes = {
  onGoToLicense: PropTypes.func.isRequired
}

export default NavbarContextMenuLicense
