import React, { useMemo } from 'react'

import GlobalUnifiedReporting from 'global/components/features/unifiedReporting/UnifiedReporting'

import useSideMenuLogic from './useUnifiedReportingSideMenuLogic'
import useUnifiedReportingLogic from './useUnifiedReportingLogic'
import useUnifiedReportingChartLogic from './useUnifiedReportingChartLogic'
import useUnifiedReportingHeaderLogic from './useUnifiedReportingHeaderLogic'
import useUnifiedReportingDatatableLogic from './useUnifiedReportingDatatableLogic'

const UnifiedReporting: React.FC = () => {
  const [sideMenuConfig] = useSideMenuLogic()
  const [reportingChartConfig] = useUnifiedReportingChartLogic()
  const [unifiedReportingLogic] = useUnifiedReportingLogic()
  const [unifiedReportingHeaderConfig] = useUnifiedReportingHeaderLogic()
  const [reportingDatatableConfig] = useUnifiedReportingDatatableLogic()

  const { alertConfig, isLoading } = unifiedReportingLogic

  return useMemo(
    () => (
      <GlobalUnifiedReporting
        alertConfig={alertConfig}
        isLoading={isLoading}
        chartProps={reportingChartConfig}
        dataTableProps={reportingDatatableConfig}
        sideMenuProps={sideMenuConfig}
        reportHeaderProps={unifiedReportingHeaderConfig}
      />
    ),
    [
      alertConfig,
      isLoading,
      reportingChartConfig,
      reportingDatatableConfig,
      sideMenuConfig,
      unifiedReportingHeaderConfig
    ]
  )
}

export default UnifiedReporting
