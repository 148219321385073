import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { v4 as uuidv4 } from 'uuid'
import DOMPurify from 'dompurify'
import { snakeCase } from 'lodash'

import {
  Typography,
  Button,
  TextField,
  Checkbox,
  Grid,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  Zoom,
  Popover,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Fade,
  Tooltip,
  Alert
} from '@barracuda-internal/bds-core'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'

import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import CircularProgress from 'global/components/lib/circularProgress/CircularProgress'
import DEFAULTS from 'global/configs/theme/defaults'
import { useFormatMessage, useErrorFormatMessage } from 'global/lib/localization'

import styles, { Dialog } from 'sen/components/lib/dialogs/testAi/testAiDialogStyles'
import useTestAiDialogLogic from 'sen/components/lib/dialogs/testAi/useTestAiDialogLogic'
import { Attack } from 'sen/components/lib/dialogs/testAi/spTemplates'
import IdentityAutocomplete from 'sen/components/lib/autocompletes/identity/IdentityAutocomplete'

export interface TestAiDialogProps {
  onClose: () => void
}

const BASE_I18N_KEY = 'sen.app.test_ai_dialog'
const BASE_RECIPIENTS_AUTOCOMPLETE_I18N_KEY = 'sen.app.autocompletes.recipients'
const BASE_SENDERS_AUTOCOMPLETE_I18N_KEY = 'sen.app.autocompletes.senders'

export const TestAiDialog: React.FC<TestAiDialogProps> = ({ onClose }) => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const errorFormatMessage = useErrorFormatMessage()

  const [
    testAiDialogLogic,
    recipientsSelector,
    sendersSelector,
    filterAttacksConfig,
    attackSelectorConfig
  ] = useTestAiDialogLogic({ onClose })

  return useMemo(() => {
    return (
      <Dialog
        style={{ zIndex: DEFAULTS.DIALOGS_ZINDEX, minHeight: 600 }}
        maxWidth="md"
        fullWidth
        open
        TransitionComponent={Zoom}
      >
        {testAiDialogLogic.isTestAttack && <LinearProgress className={classes.loadingIndicator} />}

        <DialogTitle>
          {testAiDialogLogic.errorMsg && (
            <Alert className={classes.error} severity="error">
              {errorFormatMessage(testAiDialogLogic.errorMsg)}
            </Alert>
          )}

          <Typography className={classes.dialogTitle}>{formatMessage('title')}</Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {testAiDialogLogic.isLoading && (
            <Grid container justifyContent="center" className={classes.loading}>
              <CircularProgress />
            </Grid>
          )}

          {!testAiDialogLogic.isLoading && (
            <>
              <Grid container direction="column">
                <Grid item xs={6}>
                  <Grid item>
                    <FormControl className={classes.formControl}>
                      <FormLabel>
                        <Typography className={classes.inputLabel}>{formatMessage('select_recipient')}</Typography>
                      </FormLabel>
                      <IdentityAutocomplete
                        {...recipientsSelector.config}
                        BASE_I18N_KEY={BASE_RECIPIENTS_AUTOCOMPLETE_I18N_KEY}
                        inProgress={testAiDialogLogic.isTestAttack}
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container>
                  <Typography className={classes.subtitle}>
                    {formatMessage('subtitle', {
                      recipient: recipientsSelector.selectedRecipient,
                      b: (txt: any) => <b key={txt}>{txt}</b>,
                      send: (txt: string) => {
                        if (!recipientsSelector.selectedRecipient) {
                          return ''
                        }

                        return txt
                      }
                    })}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Grid item>
                    <PopupState variant="popover" popupId="filter-attacks-popover">
                      {popupState => (
                        <>
                          <Button
                            className={classes.filterAttacksButton}
                            color="primary"
                            variant="text"
                            disabled={testAiDialogLogic.isTestAttack}
                            onClick={e => {
                              filterAttacksConfig.sendFilterEvent()
                              bindTrigger(popupState).onClick(e)
                            }}
                          >
                            {formatMessage('filter_attacks')}
                          </Button>
                          <Popover
                            style={{ zIndex: DEFAULTS.SECONDARY_DIALOGS_ZINDEX }}
                            keepMounted
                            {...bindPopover(popupState)}
                            TransitionComponent={Fade}
                            anchorOrigin={{
                              vertical: 'center',
                              horizontal: 'right'
                            }}
                            transformOrigin={{
                              vertical: 'center',
                              horizontal: 'center'
                            }}
                            PaperProps={{
                              style: {}
                            }}
                          >
                            <List>
                              {filterAttacksConfig.FILTER_ATTACKS.map(value => {
                                const labelId = `checkbox-list-label-${value}`

                                return (
                                  <ListItem
                                    key={value}
                                    role={undefined}
                                    dense
                                    button
                                    onClick={() => filterAttacksConfig.setFilterAttacks(value)}
                                  >
                                    <ListItemIcon>
                                      <Checkbox
                                        color="primary"
                                        edge="start"
                                        checked={filterAttacksConfig.filterAttacks.includes(value)}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                      />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={formatMessage(`attack_types.${value}`)} />
                                  </ListItem>
                                )
                              })}
                            </List>
                          </Popover>
                        </>
                      )}
                    </PopupState>
                  </Grid>

                  {!!attackSelectorConfig.selectedAttack && !attackSelectorConfig.selectedAttack?.senderName && (
                    <Grid item className={classes.senderSelector}>
                      <FormControl className={classes.formControl}>
                        <FormLabel>
                          <Typography className={classes.inputLabel}>
                            {formatMessage('select_impersonate_sender')}
                          </Typography>
                        </FormLabel>
                        <IdentityAutocomplete
                          {...sendersSelector.config}
                          BASE_I18N_KEY={BASE_SENDERS_AUTOCOMPLETE_I18N_KEY}
                          inProgress={testAiDialogLogic.isTestAttack}
                        />
                        <FormLabel>
                          <Typography className={classes.helpLabel}>{formatMessage('sender_help')}</Typography>
                        </FormLabel>
                      </FormControl>
                    </Grid>
                  )}

                  <Grid item>
                    <FormControl className={classes.formControl}>
                      <FormLabel>
                        <Typography className={classes.inputLabel}>{formatMessage('select_attack')}</Typography>
                      </FormLabel>
                      <Autocomplete
                        classes={{ popper: classes.popper }}
                        options={attackSelectorConfig.attacks}
                        groupBy={(attack: Attack) => formatMessage(`attack_types.${snakeCase(attack.attackType)}`)}
                        getOptionLabel={(attack: Attack) => attack.templateName || ''}
                        getOptionSelected={(option: Attack, value: Attack) => {
                          return option.templateName === value.templateName
                        }}
                        renderInput={(params: any) => <TextField {...params} />}
                        onChange={attackSelectorConfig.onSelectAttack as any}
                        disabled={testAiDialogLogic.isTestAttack}
                        value={attackSelectorConfig.selectedAttack}
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item className={classes.subject}>
                    <Tooltip title={attackSelectorConfig.selectedAttack?.subject || ''}>
                      <Typography noWrap>
                        {formatMessage('subject', {
                          subject: (txt: string) => {
                            return (
                              <data key={uuidv4()} className={classes.subjectTitle}>
                                {txt}
                              </data>
                            )
                          },
                          subjectText: attackSelectorConfig.selectedAttack?.subject || ''
                        })}
                      </Typography>
                    </Tooltip>
                  </Grid>
                  <Grid item className={classes.body}>
                    {attackSelectorConfig.selectedAttack?.body && (
                      <>
                        {attackSelectorConfig.selectedAttack?.body.contentType === 'html' ? (
                          <iframe
                            title="attack-template"
                            className={classes.frameContent}
                            srcDoc={DOMPurify.sanitize(attackSelectorConfig.selectedAttack?.body?.content)}
                          />
                        ) : (
                          <Grid item className={classes.frameContent}>
                            {attackSelectorConfig.selectedAttack?.body?.content}
                          </Grid>
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </DialogContent>

        {!testAiDialogLogic.isLoading && (
          <DialogActions>
            <Button color="primary" size="large" variant="text" onClick={onClose}>
              &nbsp;&nbsp;{formatMessage('cancel')}&nbsp;&nbsp;
            </Button>
            <Button
              disabled={testAiDialogLogic.isSendButtonDisabled}
              color="primary"
              variant="contained"
              size="large"
              onClick={testAiDialogLogic.onTestAttack}
            >
              &nbsp;&nbsp;{formatMessage('send_now')}&nbsp;&nbsp;
            </Button>
          </DialogActions>
        )}
      </Dialog>
    )
  }, [
    testAiDialogLogic,
    classes,
    onClose,
    formatMessage,
    errorFormatMessage,
    recipientsSelector,
    sendersSelector,
    filterAttacksConfig,
    attackSelectorConfig
  ])
}

TestAiDialog.propTypes = {
  onClose: PropTypes.func.isRequired
}

export default TestAiDialog
