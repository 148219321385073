import { createSlice } from '@reduxjs/toolkit'

import {
  addProduct,
  checkBCSSubscription,
  extendProductTrial,
  getSerialWithAccountId,
  getProductSerial,
  setSerialNumber
} from 'global/redux/features/account/accountApiThunks'
import { SerialInformation } from 'global/types/api/accountType'
import { inIdle, inProgress, successResponse, failedResponse, ApiStatus } from 'global/redux/toolkit/api'

export interface AccountState {
  addProductApiStatus: ApiStatus
  setSerialNumberApiStatus: ApiStatus
  setBCSSubscriptionApiStatus: ApiStatus
  getSerialWithAccountIdApiStatus: ApiStatus
  getProductSerialApiStatus: ApiStatus
  extendProductTrialApiStatus: ApiStatus
  productSerial: SerialInformation
  serialInformation: SerialInformation | {}
}

// initialState
export const INITIAL_STATE: AccountState = {
  addProductApiStatus: inIdle,
  setSerialNumberApiStatus: inIdle,
  setBCSSubscriptionApiStatus: inIdle,
  getSerialWithAccountIdApiStatus: inIdle,
  getProductSerialApiStatus: inIdle,
  extendProductTrialApiStatus: inIdle,
  productSerial: {} as SerialInformation,
  serialInformation: {}
}

/* eslint-disable no-param-reassign */
export const accountSlice = createSlice({
  name: 'ACCOUNT',
  initialState: INITIAL_STATE,
  reducers: {
    resetAddProduct: state => {
      state.addProductApiStatus = inIdle
    },
    resetSetSerialNumber: state => {
      state.setSerialNumberApiStatus = inIdle
    },
    resetExtendProductTrial: state => {
      state.extendProductTrialApiStatus = inIdle
    },
    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(addProduct.pending, state => {
        state.addProductApiStatus = inProgress
      })
      .addCase(addProduct.fulfilled, (state, action) => {
        state.addProductApiStatus = successResponse
      })
      .addCase(addProduct.rejected, (state, action) => {
        state.addProductApiStatus = failedResponse(action.payload as string)
      })
      .addCase(setSerialNumber.pending, state => {
        state.setSerialNumberApiStatus = inProgress
      })
      .addCase(setSerialNumber.fulfilled, state => {
        state.setSerialNumberApiStatus = successResponse
      })
      .addCase(setSerialNumber.rejected, (state, action) => {
        state.setSerialNumberApiStatus = failedResponse(action.payload as string)
      })
      .addCase(checkBCSSubscription.pending, state => {
        state.setBCSSubscriptionApiStatus = inProgress
      })
      .addCase(checkBCSSubscription.fulfilled, state => {
        state.setBCSSubscriptionApiStatus = successResponse
      })
      .addCase(checkBCSSubscription.rejected, (state, action) => {
        state.setBCSSubscriptionApiStatus = failedResponse(action.payload as string)
      })
      .addCase(getSerialWithAccountId.pending, state => {
        state.getSerialWithAccountIdApiStatus = inProgress
      })
      .addCase(getSerialWithAccountId.fulfilled, (state, action) => {
        state.getSerialWithAccountIdApiStatus = successResponse
        state.serialInformation = action.payload
      })
      .addCase(getSerialWithAccountId.rejected, (state, action) => {
        state.getSerialWithAccountIdApiStatus = failedResponse(action.payload as string)
      })
      .addCase(getProductSerial.pending, state => {
        state.getProductSerialApiStatus = inProgress
      })
      .addCase(getProductSerial.fulfilled, (state, action) => {
        state.getProductSerialApiStatus = successResponse
        state.productSerial = action.payload
      })
      .addCase(getProductSerial.rejected, (state, action) => {
        state.getProductSerialApiStatus = failedResponse(action.payload as string)
      })
      .addCase(extendProductTrial.pending, state => {
        state.extendProductTrialApiStatus = inProgress
      })
      .addCase(extendProductTrial.fulfilled, (state, action) => {
        state.extendProductTrialApiStatus = successResponse
        state.productSerial = action.payload
      })
      .addCase(extendProductTrial.rejected, (state, action) => {
        state.extendProductTrialApiStatus = failedResponse(action.payload as string)
      })
  }
})
/* eslint-enable no-param-reassign */

export const { resetAddProduct, resetSetSerialNumber, resetExtendProductTrial, reset } = accountSlice.actions

export {
  addProduct,
  checkBCSSubscription,
  extendProductTrial,
  getSerialWithAccountId,
  getProductSerial,
  setSerialNumber
}

export default accountSlice.reducer
