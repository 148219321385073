import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import reducerHandlers from 'global/redux/features/dataTables/reducerHandlers'
import { UpdateDataTable } from 'global/types/dataTables/dataTables'

import { DataTableState, ColumnsConfig, SortFields, GridColumns } from 'sen/redux/types/dataTables'

export const GRID_COLUMNS: GridColumns = {
  DISPLAY_NAME: 'display_name',
  EMAIL: 'email',
  TITLE: 'title',
  DEPARTMENT: 'department',
  LICENSED: 'licensed',
  PROTECTED: 'protected'
}

export const SORT_FIELDS: SortFields = {
  [GRID_COLUMNS.DISPLAY_NAME]: 'displayName',
  [GRID_COLUMNS.EMAIL]: 'email',
  [GRID_COLUMNS.TITLE]: 'title',
  [GRID_COLUMNS.DEPARTMENT]: 'department',
  [GRID_COLUMNS.LICENSED]: 'hasMailbox',
  [GRID_COLUMNS.PROTECTED]: 'isMonitored'
}

export const COLUMNS_CONFIG: ColumnsConfig = {
  [GRID_COLUMNS.DISPLAY_NAME]: {
    sortable: true,
    orderIndex: 0,
    show: true
  },
  [GRID_COLUMNS.EMAIL]: {
    sortable: true,
    orderIndex: 1,
    show: true
  },
  [GRID_COLUMNS.TITLE]: {
    sortable: true,
    orderIndex: 2,
    show: true
  },
  [GRID_COLUMNS.DEPARTMENT]: {
    sortable: true,
    orderIndex: 3,
    show: true
  },
  [GRID_COLUMNS.LICENSED]: {
    sortable: false,
    orderIndex: 4,
    show: true
  },
  [GRID_COLUMNS.PROTECTED]: {
    sortable: false,
    orderIndex: 5,
    show: true
  }
}

const ITEMS_PER_PAGE = 5

// Store
export const INITIAL_STATE: DataTableState = {
  SORT_FIELDS,
  GRID_COLUMNS,
  ITEMS_PER_PAGE,
  columnsConfig: COLUMNS_CONFIG,
  skip: 0, // offset
  take: ITEMS_PER_PAGE, // limit
  filter: null,
  search: '',
  sort: [{ field: GRID_COLUMNS.DISPLAY_NAME, dir: 'asc' }]
}

export const mailboxesSlice = createSlice({
  name: 'DATA_TABLE/MAILBOXES',
  initialState: INITIAL_STATE,
  reducers: {
    update: {
      reducer: (state: DataTableState, action: PayloadAction<{ config: UpdateDataTable }>) => {
        return reducerHandlers({ COLUMNS_CONFIG }).update({ state, payload: action.payload })
      },
      prepare: (config: UpdateDataTable) => ({
        payload: { config }
      })
    },
    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  }
})

export const { update, reset } = mailboxesSlice.actions

export default mailboxesSlice.reducer
