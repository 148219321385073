import { useMemo, useReducer, useCallback, useEffect } from 'react'
import { snakeCase } from 'lodash'
import { Launch as OpenInNewIcon } from '@barracuda-internal/bds-core/dist/Icons/Core'

import { NavbarNoticableWidgetProps } from 'global/components/lib/layout/navbar/noticableWidget/NavbarNoticableWidget'
import { config } from 'global/lib/config'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import useUserDataLib from 'global/lib/userData/useUserData'
import useAccessTokenLib from 'global/lib/accessToken/useAccessToken'
import useFeatureLib from 'global/lib/feature/useFeature'
import useProductLib from 'global/lib/product/useProduct'
import { logout } from 'global/redux/features/auth/authSlice'
import { reset as resetAccount } from 'global/redux/features/account/accountSlice'
import { getSentinelNoticeableInfo } from 'global/lib/configuration/configuration'
import { useFormatMessage } from 'global/lib/localization'
import { isPending } from 'global/redux/toolkit/api'
import { FEATURES, isMyFeatureOn } from 'global/lib/splitio/splitio'

import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import gotoReportPage from 'sen/lib/gotoReportPage'
import routesConfig, { UiRoute } from 'sen/lib/routes/routesConfig'

import {
  NavbarContextMenuO365Props,
  MenuItemProps,
  NavbarAccountSwitcherProps,
  NavbarContextMenuUserInfoProps,
  NavbarSideMenuProps
} from 'global/components/lib/layout/navbar'
import { SetSerialNumberDialogProps } from 'global/components/lib/dialogs/serialNumber/SetSerialNumberDialog'

export interface KeyType {
  [key: string]: string
}

export type NavbarLogicProp = {
  isCleanupPage: boolean
  isLicenseVisible: boolean
  isMSP: boolean
  isNavbarVisible: boolean
  isOnpremUser: boolean
  navbarAccountSwitcherProps: NavbarAccountSwitcherProps
  navbarContextMenuO365Props: NavbarContextMenuO365Props
  navbarContextMenuUserInfoProps: NavbarContextMenuUserInfoProps
  navbarNoticableWidgetProps: NavbarNoticableWidgetProps
  navbarSideMenuProps: NavbarSideMenuProps
  onGoToLicense: () => void
  onLogout: () => void
}

const BASE_I18N_KEY = 'app.navbar'
const BASE_SEN_I18N_KEY = 'sen.app.side_menu'

const MENU_FORENSICS_AND_INCIDENT_RESPONSE = 'forensics_and_menu_response'

const BUNDLE1_SIDE_MENU_ITEMS: string[] = [
  routesConfig.DASHBOARD.id,
  routesConfig.ACCOUNT_TAKEOVER.id,
  MENU_FORENSICS_AND_INCIDENT_RESPONSE
]
const DP_SIDE_MENU_ITEMS: string[] = [routesConfig.DOMAIN_FRAUD.id, MENU_FORENSICS_AND_INCIDENT_RESPONSE]
const ALL_SIDE_MENU_ITEMS: string[] = [
  routesConfig.DASHBOARD.id,
  routesConfig.ACCOUNT_TAKEOVER.id,
  routesConfig.DOMAIN_FRAUD.id,
  MENU_FORENSICS_AND_INCIDENT_RESPONSE
]

interface SubRoute {
  sideMenu: string
}

const SUB_ROUTES = Object.entries(routesConfig).reduce<{ [key: string]: SubRoute }>(
  (prev: { [key: string]: SubRoute }, [routeId, route]: [string, UiRoute]) => {
    return {
      ...prev,
      ...(route.metadata?.sideMenu && { [routeId]: { sideMenu: route.metadata.sideMenu } })
    }
  },
  {}
)

export default function useNavbarLogic(): [NavbarLogicProp] {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const sideMenuFormatMessage = useFormatMessage(BASE_SEN_I18N_KEY)
  const dispatch = useAppDispatch()
  const {
    accessToken,
    activePathId,
    isSerialNumberLoading,
    isNavbarVisible,
    isCleanupPage,
    splitStore
  } = useAppSelector(_stores => ({
    accessToken: _stores.accessToken.accessToken,
    activePathId: _stores.app.activePath.id,
    isCleanupPage: _stores.app.activePath?.id === routesConfig.CLEANUP_TABLE.id,
    isNavbarVisible: !!_stores.app.activePath.isNavbarVisible,
    isSerialNumberLoading: isPending(_stores.account.setSerialNumberApiStatus),
    splitStore: _stores.splitio
  }))
  const { userEmail, accounts } = useAppSelector(_stores => ({
    userEmail: _stores.user.data?.email || '',
    accounts: _stores.user.data?.accounts
  }))
  const [state, setState] = useReducer((_state: any, newState: any) => ({ ..._state, ...newState }), {
    disabledSideMenus: [],
    selectedMenu: ''
  })
  const [accessTokenLib] = useAccessTokenLib()
  const [userDataLib] = useUserDataLib()
  const [featureLib] = useFeatureLib()
  const [productLib] = useProductLib()
  const noticableInfo = getSentinelNoticeableInfo()

  // Use the current account Id to select splitio treatment value
  const accountId = useMemo(() => userDataLib.getAccountByAccessToken(accessToken?.id)?.accountId, [
    accessToken,
    userDataLib
  ])
  const isLicenseComplianceOn = useMemo(() => isMyFeatureOn(splitStore, FEATURES.IP_LICENSING_COMPLIANCE, accountId), [
    accountId,
    splitStore
  ])

  const SUB_MENU_ITEMS = {
    [routesConfig.UNIFIED_REPORTING_ROOT.id]: [
      {
        routeId: routesConfig.UNIFIED_REPORTING_ROOT.id,
        text: sideMenuFormatMessage(`${snakeCase(routesConfig.UNIFIED_REPORTING.id)}`)
      },
      {
        routeId: routesConfig.SCHEDULED_REPORTS.id,
        text: sideMenuFormatMessage(`${snakeCase(routesConfig.SCHEDULED_REPORTS.id)}`)
      }
    ]
  }

  useEffect(() => {
    setState({ selectedMenu: activePathId })
  }, [activePathId])

  const dfpBundleVersion: string = useMemo(() => {
    return productLib.getDFPSerialBundleForAccessToken(accessToken?.id)
  }, [productLib, accessToken])

  const sentinelBundleVersion: string = useMemo(() => {
    return productLib.getSentinelSerialBundleForAccessToken(accessToken?.id)
  }, [productLib, accessToken])

  const isOnpremUser: boolean = useMemo(() => {
    return dfpBundleVersion === config.BUNDLES.DP
  }, [dfpBundleVersion])

  const insertAtIndex = useCallback(
    (arr: any[], index: number, newItem: any) => [...arr.slice(0, index), newItem, ...arr.slice(index)],
    []
  )
  /* With the new PAM V2 activation we need to support bundle backwards compatibility until the DFP standalone is deployed */
  // TODO: BNFIR-3323 add the logic below during implementation
  const menuItems: string[] = useMemo(() => {
    switch (true) {
      case sentinelBundleVersion === config.BUNDLES.BUNDLE1 && !dfpBundleVersion:
        return insertAtIndex(BUNDLE1_SIDE_MENU_ITEMS, -1, routesConfig.UNIFIED_REPORTING_ROOT.id)
      case sentinelBundleVersion === config.BUNDLES.BUNDLE1 && !!dfpBundleVersion:
        return insertAtIndex(ALL_SIDE_MENU_ITEMS, -1, routesConfig.UNIFIED_REPORTING_ROOT.id)
      case sentinelBundleVersion === config.BUNDLES.BUNDLE2:
        return insertAtIndex(ALL_SIDE_MENU_ITEMS, -1, routesConfig.UNIFIED_REPORTING_ROOT.id)
      case sentinelBundleVersion === config.BUNDLES.DP:
        return DP_SIDE_MENU_ITEMS
      case !sentinelBundleVersion && !!dfpBundleVersion:
        return DP_SIDE_MENU_ITEMS
      case !sentinelBundleVersion && !dfpBundleVersion:
        return insertAtIndex(BUNDLE1_SIDE_MENU_ITEMS, -1, routesConfig.UNIFIED_REPORTING_ROOT.id)
      default:
        return insertAtIndex(ALL_SIDE_MENU_ITEMS, -1, routesConfig.UNIFIED_REPORTING_ROOT.id)
    }
  }, [dfpBundleVersion, insertAtIndex, sentinelBundleVersion])

  const navbarSideMenuProps = useMemo<NavbarSideMenuProps>(
    () => ({
      listItems: menuItems.map((sideMenu: string) => ({
        disabled: state.disabledSideMenus.includes(sideMenu),
        id: sideMenu,
        hasSubMenu: SUB_MENU_ITEMS[sideMenu] && SUB_MENU_ITEMS[sideMenu].length >= 2,
        subMenuItems: SUB_MENU_ITEMS[sideMenu],
        text: sideMenuFormatMessage(snakeCase(sideMenu)),
        ...(sideMenu === MENU_FORENSICS_AND_INCIDENT_RESPONSE && { icon: OpenInNewIcon })
      })),
      onListItemSelected: (selectedMenu: string) => {
        if (selectedMenu === MENU_FORENSICS_AND_INCIDENT_RESPONSE) {
          window.open(`${config.domains.forensics}/report/${accessToken?.id}`, '_blank')
        } else if (![state.selectedMenu, SUB_ROUTES[state.selectedMenu]?.sideMenu].includes(selectedMenu)) {
          setState({ selectedMenu })
          routesConfig[selectedMenu].goto({ reportId: accessToken?.id })
        }
      },
      selectedIndex: menuItems.reduce((selectedIndex: number, sideMenu: string, index: number) => {
        if ([state.selectedMenu, SUB_ROUTES[state.selectedMenu]?.sideMenu].includes(sideMenu)) {
          return index
        }

        return selectedIndex
      }, -1)
    }),
    [menuItems, state.disabledSideMenus, state.selectedMenu, SUB_MENU_ITEMS, sideMenuFormatMessage, accessToken]
  )

  const navbarAccountSwitcherProps: NavbarAccountSwitcherProps = useMemo(
    () => ({
      userDisplayName: userDataLib.getAccessTokenDisplayName(accessToken) || '',
      menuListItems: (isCleanupPage
        ? accessTokenLib.getValidSentinelAccessTokens()
        : accessTokenLib.getAllAccessTokens()
      ).map((account: any) => ({
        id: account.id,
        name: account.name,
        displayName: account.name === 'Default' ? config.ON_PREM_DEFAULTS.NAME : account.name,
        products: account.products,
        bundleVersion: productLib.getSentinelSerialBundleForAccessToken(account.id)
      })) as MenuItemProps[],
      onAccountSwitcherButton: (selectedAccessTokenId: string) => {
        if (selectedAccessTokenId !== accessToken?.id) {
          analyticsLib.trackAppEvent(analyticsLib.EVENTS.NAVBAR_SWITCH_ACCOUNT, { selectedAccessTokenId })
          gotoReportPage({ accessTokenId: selectedAccessTokenId })
        }
      },
      currentAccessToken: accessToken || null
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accessToken, accounts, dispatch, userDataLib]
  )

  const setSerialNumberDialogConfig: SetSerialNumberDialogProps = useMemo(
    () => ({
      reset: () => {
        dispatch(resetAccount())
      },
      accessTokenId: accessToken?.id || '',
      isSettingSerial: isSerialNumberLoading,
      BASE_I18N_KEY: 'sen.app.serial_number_dialog',
      product: config.PRODUCTS.SENTINEL
    }),
    [dispatch, accessToken, isSerialNumberLoading]
  )

  const navbarContextMenuUserInfoProps: NavbarContextMenuUserInfoProps = useMemo(
    () => ({
      userEmail,
      serialNumber: isCleanupPage ? undefined : productLib.getSentinelSerialNumberForAccessToken(accessToken?.id),
      serialExpiry: isCleanupPage ? undefined : productLib.getSentinelSerialExpiryForAccessToken(accessToken?.id),
      dialogConfig: setSerialNumberDialogConfig
    }),
    [userEmail, setSerialNumberDialogConfig, productLib, accessToken, isCleanupPage]
  )

  const navbarContextMenuO365Props: NavbarContextMenuO365Props = useMemo(
    () => ({
      successCb: (data: any, scanType: string) => {
        analyticsLib.trackAppEvent(analyticsLib.EVENTS.NAVBAR_CONNECT_O365_SUCCESSFUL, {
          accessTokenId: data.accessToken.id
        })
        if (scanType === config.SCAN_TYPES.SENTINEL) {
          window.location.replace(`${config.domains.ets}/report/${data.accessToken.id}`)
        } else {
          gotoReportPage({ accessTokenId: data.accessToken.id, user: data.user, scan: data.scan })
        }
      },
      showScanTypeSelection:
        (accounts && featureLib.hasSentinelFeatureForAnyAccessToken()) || featureLib.hasETSReadOnlyForAnyAccessToken(),
      accounts
    }),
    [accounts, featureLib]
  )

  const navbarNoticableWidgetProps: NavbarNoticableWidgetProps = useMemo(
    () => ({
      noticableInfo,
      title: formatMessage('noticeable_title'),
      trackNoticeableClick: () => {
        analyticsLib.trackAppEvent(analyticsLib.EVENTS.NAVBAR_CLICK_NOTICEABLE)
      }
    }),
    [formatMessage, noticableInfo]
  )

  // start logout
  const onLogout = useCallback(() => {
    dispatch(logout(true))
  }, [dispatch])

  const onGoToLicense = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.NAVBAR_REVIEW_LICENSES)
    routesConfig.LICENSE.goto({ reportId: accessToken?.id })
  }, [accessToken])

  return useMemo(
    () => [
      {
        accessTokenId: accessToken?.id,
        isCleanupPage,
        isLicenseVisible:
          !userDataLib.isMspManagedAccount(accessToken?.id) &&
          isLicenseComplianceOn &&
          accessTokenLib.hasSentinelEntitlement(accessToken?.id),
        isNavbarVisible,
        isMSP: userDataLib.isMspManagedAccount(accessToken?.id),
        isOnpremUser,
        navbarAccountSwitcherProps,
        navbarContextMenuO365Props,
        navbarContextMenuUserInfoProps,
        navbarNoticableWidgetProps,
        navbarSideMenuProps,
        onGoToLicense,
        onLogout
      }
    ],
    [
      accessToken,
      accessTokenLib,
      isCleanupPage,
      isLicenseComplianceOn,
      isNavbarVisible,
      isOnpremUser,
      navbarAccountSwitcherProps,
      navbarContextMenuO365Props,
      navbarContextMenuUserInfoProps,
      navbarNoticableWidgetProps,
      navbarSideMenuProps,
      onGoToLicense,
      onLogout,
      userDataLib
    ]
  )
}
