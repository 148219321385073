import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Dialog as MuiDialog } from '@barracuda-internal/bds-core'

import tabStyles from 'global/theme/tabStyles'

const Dialog = withStyles(() => ({
  paper: {
    maxWidth: 1100
  }
}))(MuiDialog)

export { Dialog }

export default makeStyles(theme => {
  const tabClasses = tabStyles(theme)

  return {
    ...tabClasses,
    dialogTabContent: {
      ...tabClasses.dialogTabContent,
      position: 'relative'
    },
    error: {
      marginBottom: theme.spacing(2)
    },
    loadingIndicator: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0
    },
    buttonsContainer: {
      marginBottom: theme.spacing(2)
    },
    formButton: {
      padding: `0 ${theme.spacing(2)}px`,
      marginLeft: theme.spacing(1)
    }
  }
})
