import React from 'react'

import sentintelContentLoader from 'sen/components/lib/useSentinelContentCondition/SentinelContentLoader'
import PermissionBanner from 'global/components/lib/layout/permissionBanner/PermissionBanner'
import AtoOverview from 'sen/components/pages/ato/overview/atoOverview'
import AtoContent from 'sen/components/pages/ato/content/AccountTakeoverContent'

export default sentintelContentLoader({
  default: () => (
    <>
      <PermissionBanner />
      <AtoOverview />
      <AtoContent />
    </>
  )
})
