import React, { useMemo, useCallback, useReducer, useState, useEffect } from 'react'

import { snakeCase } from 'lodash'

import { useEffectOnInit, useEffectOnlyOnce } from 'global/lib/useCustomEffect'
import toggleInArray from 'global/lib/toggleInArray'
import { AccessToken } from 'global/types/api/accessTokenType'
import { addSuccessAlert } from 'global/redux/features/app/appSlice'
import { useFormatMessage } from 'global/lib/localization'
import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { Recipient } from 'global/types/api/identity'
import { isPending, isSuccess, getErrorMessage } from 'global/redux/toolkit/api'

import {
  canSendReplyToAttack,
  resetCanSendReplyToAttack,
  getEmployeeDefaults,
  resetEmployeeDefaults,
  testAttack,
  resetTestAttack
} from 'sen/redux/features/sp/spSlice'
import { getRecipients, getSenders } from 'sen/redux/features/identity/identitySlice'
import { SP_ATTACK_TEMPLATES, parseTemplateValues, Attack } from 'sen/components/lib/dialogs/testAi/spTemplates'
import { useAppDispatch, useAppSelector } from 'sen/redux/toolkit/hooks'
import useRecipientsAutocompleteLogic, {
  RecipientsAutocompleteConfig
} from 'sen/components/lib/autocompletes/identity/recipients/useRecipientsAutocompleteLogic'
import useSendersAutocompleteLogic, {
  SendersAutocompleteConfig
} from 'sen/components/lib/autocompletes/identity/senders/useSendersAutocompleteLogic'

export type OnTestAttack = () => void

export interface UseTestAiDialogLogicProps {
  isLoading: boolean
  isSendButtonDisabled: boolean
  isTestAttack: boolean
  onTestAttack: OnTestAttack
  errorMsg: string | undefined
}

export interface RecipientsConfig {
  options: Recipient[]
  getOptionLabel: (option: Recipient) => string
  open: boolean
  onOpen: (e: any) => void
  onInputChange: (e: any) => void
  onClose: () => void
  getOptionSelected: (option: Recipient, value: any) => boolean
  loading: boolean
  value: Recipient
}

export interface RecipientsSelectorProps {
  selectedRecipient: string | undefined
  config: RecipientsAutocompleteConfig
}
export interface SendersSelectorProps {
  selectedSender: string | undefined
  config: SendersAutocompleteConfig
}

export interface FilterAttacksSelectorProps {
  filterAttacks: string[]
  setFilterAttacks: (type: string) => void
  FILTER_ATTACKS: string[]
  sendFilterEvent: () => void
}

export interface AttackSelectorProps {
  attacks: Attack[]
  selectedAttack: Attack
  onSelectAttack: (e: any, newValue: Attack) => void
}

export interface State {
  isInitialDataLoaded: boolean
  selectedAttack: Attack
}

export interface Props {
  onClose: () => void
}

const FILTER_ATTACKS = ['blackmailing', 'employee_impersonation', 'service_impersonation']
const BASE_I18N_KEY = 'sen.app.test_ai_dialog'

export default function useTestAiDialogLogic({
  onClose
}: Props): [
  UseTestAiDialogLogicProps,
  RecipientsSelectorProps,
  SendersSelectorProps,
  FilterAttacksSelectorProps,
  AttackSelectorProps
] {
  const dispatch = useAppDispatch()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const [filterAttacks, setFilterAttacks] = useState<string[]>([...FILTER_ATTACKS])
  const [state, setState] = useReducer((_state: State, newState: Partial<State>) => ({ ..._state, ...newState }), {
    isInitialDataLoaded: false,
    selectedAttack: SP_ATTACK_TEMPLATES[0]
  })
  const {
    isLoading,
    employeeDefaults,
    isGetEmployeeDefaultsSuccess,
    recipientIdentityDetails,
    senderIdentityDetails,
    accessToken,
    isTestAttack,
    isTestAttackSuccess,
    testAttackErrorMsg
  } = useAppSelector(_stores => ({
    isLoading:
      isPending(_stores.sp.canSendReplyToAttackApiStatus) || isPending(_stores.sp.getEmployeeDefaultsApiStatus),
    employeeDefaults: _stores.sp.employeeDefaults,
    isGetEmployeeDefaultsSuccess: isSuccess(_stores.sp.getEmployeeDefaultsApiStatus),
    recipientIdentityDetails: _stores.identity.recipientIdentityDetails,
    senderIdentityDetails: _stores.identity.senderIdentityDetails,
    accessToken: _stores.accessToken.accessToken,
    isTestAttack: isPending(_stores.sp.testAttackApiStatus),
    isTestAttackSuccess: isSuccess(_stores.sp.testAttackApiStatus),
    testAttackErrorMsg: getErrorMessage(_stores.sp.testAttackApiStatus)
  }))
  const [recipientsAutocompleteConfig, selectedRecipient] = useRecipientsAutocompleteLogic({
    shouldSetInititalIdentity: true,
    isRequired: true
  })
  const [sendersAutocompleteConfig, selectedSender] = useSendersAutocompleteLogic({
    shouldSetInititalIdentity: true,
    isRequired: true
  })

  // init
  useEffectOnInit(() => {
    dispatch(canSendReplyToAttack())
    dispatch(getEmployeeDefaults())

    return () => {
      dispatch(resetTestAttack())
      dispatch(resetCanSendReplyToAttack())
      dispatch(resetEmployeeDefaults())
    }
  }, [dispatch])

  // load the default recipient and sender identities
  useEffectOnlyOnce(
    () => {
      let recipient = employeeDefaults?.defaultResults?.data[0]
      let sender = employeeDefaults?.defaultResults?.data[employeeDefaults.defaultResults.count > 1 ? 1 : 0]

      if (employeeDefaults?.adminResults?.count) {
        // eslint-disable-next-line prefer-destructuring
        recipient = employeeDefaults.adminResults.data[0]
        sender = employeeDefaults.defaultResults.data.find((rec: any) => rec.email !== recipient?.email) || recipient
      }

      dispatch(getRecipients(recipient?.firstName || ''))
      dispatch(getSenders(sender?.firstName || ''))
    },
    [employeeDefaults],
    isGetEmployeeDefaultsSuccess
  )

  // render the dialog when the initial recipient is set
  useEffectOnlyOnce(
    () => {
      setState({ isInitialDataLoaded: true })
    },
    [selectedRecipient?.id],
    !!selectedRecipient?.id
  )

  // successfully sent email
  useEffectOnlyOnce(
    () => {
      const message = formatMessage('success_alert', {
        recipientName: selectedRecipient?.displayName || selectedRecipient?.email,
        b: (txt: any) => <b key={txt}>{txt}</b>
      })

      dispatch(addSuccessAlert(message))
      onClose()
    },
    [isTestAttackSuccess, dispatch, selectedRecipient],
    isTestAttackSuccess
  )

  useEffect(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.TEST_AI_FILTER_ATTACKS_CHECKBOX, {
      accessToken: accessToken?.id,
      attackFilter: filterAttacks
    })
  }, [accessToken, filterAttacks])

  const attacks: Attack[] = useMemo(() => {
    const filteredAttacks = SP_ATTACK_TEMPLATES.filter((attack: Attack) =>
      filterAttacks.includes(snakeCase(attack.attackType))
    )

    const isSelectedAttackPartOfTheNewSelection = filteredAttacks.some(attack => attack.id === state.selectedAttack?.id)

    if (!isSelectedAttackPartOfTheNewSelection) {
      setState({ selectedAttack: filteredAttacks[0] })
    }

    return filteredAttacks
  }, [filterAttacks, state.selectedAttack])

  const onAttacksSelected = useCallback(
    (_e, selectedValue) => {
      if (selectedValue) {
        setState({ selectedAttack: selectedValue })
        analyticsLib.trackAppEvent(analyticsLib.EVENTS.TEST_AI_TEMPLATE, {
          accessToken: accessToken?.id,
          template: state.selectedAttack
        })
      }
    },
    [state.selectedAttack, accessToken]
  )

  const sendFilterEvent = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.TEST_AI_FILTER_ATTACKS, { accessToken: accessToken?.id })
  }, [accessToken])

  const selectedAttack = useMemo(() => {
    return parseTemplateValues(state.selectedAttack, {
      recipient: recipientIdentityDetails,
      sender: senderIdentityDetails,
      accessToken: accessToken as AccessToken
    })
  }, [state.selectedAttack, recipientIdentityDetails, senderIdentityDetails, accessToken])

  const isSendButtonDisabled = useMemo(() => {
    return !state.selectedAttack || !selectedRecipient?.email || !selectedSender?.email || isTestAttack
  }, [state, selectedRecipient, selectedSender, isTestAttack])

  const onTestAttack: OnTestAttack = useCallback(() => {
    if (recipientIdentityDetails && senderIdentityDetails) {
      dispatch(
        testAttack({
          body: selectedAttack.body.content,
          contentType: selectedAttack.body.contentType,
          subject: selectedAttack.subject,
          recipient: recipientIdentityDetails,
          sender: senderIdentityDetails
        })
      )

      analyticsLib.trackAppEvent(analyticsLib.EVENTS.TEST_AI_SEND, {
        accessToken: accessToken?.id,
        sender: senderIdentityDetails,
        recipient: recipientIdentityDetails
      })
    }
  }, [dispatch, accessToken, recipientIdentityDetails, senderIdentityDetails, selectedAttack])

  return useMemo(() => {
    return [
      {
        isLoading: isLoading || !state.isInitialDataLoaded,
        isSendButtonDisabled,
        isTestAttack,
        onTestAttack,
        errorMsg: testAttackErrorMsg
      },
      {
        selectedRecipient: selectedRecipient?.displayName || selectedRecipient?.email,
        config: recipientsAutocompleteConfig
      },
      {
        selectedSender: selectedSender?.displayName || selectedSender?.email,
        config: sendersAutocompleteConfig
      },
      {
        filterAttacks,
        sendFilterEvent,
        setFilterAttacks: (type: string) => {
          setFilterAttacks(toggleInArray(filterAttacks, type))
        },
        FILTER_ATTACKS
      },
      {
        attacks,
        selectedAttack,
        onSelectAttack: onAttacksSelected
      }
    ]
  }, [
    selectedRecipient,
    recipientsAutocompleteConfig,
    selectedSender,
    sendersAutocompleteConfig,
    isLoading,
    state,
    filterAttacks,
    setFilterAttacks,
    sendFilterEvent,
    onAttacksSelected,
    attacks,
    selectedAttack,
    isSendButtonDisabled,
    isTestAttack,
    onTestAttack,
    testAttackErrorMsg
  ])
}
