import { FilterDescriptor, SortDescriptor } from '@progress/kendo-data-query'
import { createSlice } from '@reduxjs/toolkit'

import { GridColumns } from 'fir/redux/types/DataTables'

interface NewIncidentState {
  GRID_COLUMNS: GridColumns
  page: { skip: number; take: number }
  sort: SortDescriptor[]
  group: any[]
  filter: null | FilterDescriptor[] | string[]
  expandedRows: any[]
}

const ITEMS_PER_PAGE = 5

export const GRID_COLUMNS = {
  RECEIVED: 'received',
  SENDER: 'sender',
  MAILBOX: 'mailbox',
  SUBJECT: 'subject',
  EMAIL_DETAILS: 'emailDetails'
}

// Store
export const INITIAL_STATE: NewIncidentState = {
  GRID_COLUMNS,
  page: { skip: 0, take: ITEMS_PER_PAGE },
  sort: [{ field: GRID_COLUMNS.RECEIVED, dir: 'desc' }],
  group: [],
  filter: null,
  expandedRows: []
}

export const newIncidentSlice = createSlice({
  name: 'DATATABLE/NEW_INCIDENT',
  initialState: INITIAL_STATE,
  reducers: {
    update: (state, action) => {
      return {
        ...state,
        ...action.payload?.config
      }
    },
    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  }
})

export const { update, reset } = newIncidentSlice.actions

export default newIncidentSlice.reducer
