import { SortDescriptor } from '@progress/kendo-data-query'
import { createSlice } from '@reduxjs/toolkit'

import { ColumnsConfig, GridColumns } from 'fir/redux/types/DataTables'
import { config } from 'global/lib/config'

interface ClickedLinksByIncidentState {
  GRID_COLUMNS: GridColumns
  columnsConfig: ColumnsConfig
  page: { skip: number; take: number }
  collapsedGroups: any[]
  expandedRows: any[]
  sort: SortDescriptor[]
}

export const GRID_COLUMNS = {
  DATE_SENT: 'dateSent',
  RECIPIENTS: 'recipients',
  ESS_ID: 'essId',
  SUBJECT: 'subject',
  LINK: 'url',
  CLICKED_IPS: 'clickedIp',
  USER_AGENTS: 'userAgents'
}

export const COLUMNS_CONFIG = {
  [GRID_COLUMNS.DATE_SENT]: {
    orderIndex: 0,
    show: true
  },
  [GRID_COLUMNS.RECIPIENTS]: {
    orderIndex: 1,
    show: true
  },
  [GRID_COLUMNS.ESS_ID]: {
    orderIndex: 2,
    show: true
  },
  [GRID_COLUMNS.SUBJECT]: {
    show: true,
    orderIndex: 3
  },
  [GRID_COLUMNS.LINK]: {
    orderIndex: 4,
    show: true
  },
  [GRID_COLUMNS.CLICKED_IPS]: {
    orderIndex: 5,
    show: true
  },
  [GRID_COLUMNS.USER_AGENTS]: {
    orderIndex: 6,
    show: true
  }
}

// Store
export const INITIAL_STATE: ClickedLinksByIncidentState = {
  GRID_COLUMNS,
  columnsConfig: COLUMNS_CONFIG,
  page: { skip: 0, take: config.DATA_TABLE.FORENSICS.ITEMS_PER_PAGE },
  collapsedGroups: [],
  expandedRows: [],
  sort: [{ field: GRID_COLUMNS.DATE_SENT, dir: 'desc' }]
}

export const clickedLinksByIncidentSlice = createSlice({
  name: 'DATATABLE/CLICKED_LINKS_BY_INCIDENT',
  initialState: INITIAL_STATE,
  reducers: {
    update: (state, action) => {
      return {
        ...state,
        ...action.payload?.config
      }
    },
    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  }
})

export const { update, reset } = clickedLinksByIncidentSlice.actions

export default clickedLinksByIncidentSlice.reducer
