import { createAsyncThunk } from '@reduxjs/toolkit'

import restClient, { validateApiError } from 'global/lib/api/restClient'
import { globalApiRoutes } from 'global/lib/api/apiRoutes'
import { AccessToken } from 'global/types/api/accessTokenType'
import { Account } from 'global/types/api/accountType'
import { Products } from 'global/types/productsType'
import { generateUserDataLib } from 'global/lib/userData/userData'
import { updateCurrentAccessToken } from 'global/redux/features/accessToken/accessTokenSlice'
import { RootState } from 'global/redux/toolkit/store'

// TODO: remove fir reference
import { setBCSSubscriptionForAccessToken } from 'fir/redux/features/user/userSlice'

export interface AddProduct {
  accessTokenId: AccessToken['id']
  email: string
  product: Products
}

export interface SetSerialNumber {
  accessTokenId: AccessToken['id']
  linkingCode: string
  product: Products
  serialNumber: string
  userEmail: string
}

export interface CheckBCSSubscription {
  accessToken: AccessToken
  accountId: Account['accountId']
  bcsEntitled: Account['bcsEntitled']
  bccId: Account['bccId']
}

export interface GetSerialWithAccountId {
  product: Products
  accountIds: string[]
}

export interface GetProductSerial {
  accountId: string
  accessTokenId: string
  product: Products
}

export const addProduct = createAsyncThunk('ACCOUNT/addProduct', async function doAddProduct(
  payload: AddProduct,
  { dispatch, getState, rejectWithValue }
) {
  const { accessTokenId, email, product } = payload

  try {
    const {
      data: { products, features }
    }: { data: { products: Products[]; features: string[] } } = await restClient(
      globalApiRoutes.ADD_PRODUCT_ASSIGNMENT,
      {
        data: { accessTokenId, email, product }
      }
    )

    const { accessToken } = getState() as RootState

    if (accessToken.accessToken) {
      dispatch(updateCurrentAccessToken({ ...accessToken.accessToken, products, features }))
    }

    return { products, features }
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const setSerialNumber = createAsyncThunk('ACCOUNT/setSerialNumber', async function doSetSerialNumber(
  payload: SetSerialNumber,
  { rejectWithValue, getState, dispatch }
) {
  const { accessTokenId, linkingCode, product, serialNumber, userEmail } = payload

  try {
    const resp = await restClient(globalApiRoutes.SET_SERIAL_NUMBER, {
      data: { accessTokenId, linkingCode, product, serialNumber, userEmail }
    })

    if (resp.data.subscription && resp.data.subscription.description) {
      return rejectWithValue(validateApiError(resp.data.subscription.description))
    }

    const userDataLib = generateUserDataLib(() => (getState() as any).user)
    const accessToken = userDataLib.getAccessTokenById(accessTokenId)

    if (accessToken) {
      const updatedSerials = {
        ...accessToken.serialNumbers,
        [product]: { ...accessToken.serialNumbers[product], serialNumber }
      }

      dispatch(updateCurrentAccessToken({ ...accessToken, serialNumbers: updatedSerials } as AccessToken))
    }

    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const checkBCSSubscription = createAsyncThunk(
  'ACCOUNT/checkBCSSubscription',
  async function doCheckBCSSubscription(payload: CheckBCSSubscription, { rejectWithValue, getState, dispatch }) {
    const { accessToken, accountId, bccId, bcsEntitled } = payload

    try {
      const resp = await restClient(globalApiRoutes.CHECK_BCS_SUBSCRIPTION, {
        data: { accessToken, bccId, bcsEntitled }
      })

      // Update user.data.accounts with BCS subscription data
      dispatch(
        setBCSSubscriptionForAccessToken({
          accessTokenId: resp.data.accessTokenId,
          accountId,
          bcsSubscription: resp.data.subscriptionData
        })
      )

      const state = getState() as RootState

      // Update accessToken.accessToken with BCS subscription data
      if (state.accessToken.accessToken?.id === resp.data.accessTokenId) {
        dispatch(
          updateCurrentAccessToken({
            ...(state.accessToken.accessToken as AccessToken),
            bcsSubscription: resp.data.subscriptionData
          })
        )
      }

      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getSerialWithAccountId = createAsyncThunk(
  'ACCOUNT/getSerialWithAccountId',
  async function doGetSerialWithAccountId(payload: GetSerialWithAccountId, { rejectWithValue }) {
    const { product, accountIds } = payload

    try {
      const resp = await restClient(globalApiRoutes.GET_SERIAL_WITH_ACCOUNT_ID, {
        data: { product, accountIds }
      })

      return resp.data.serialInformation
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getProductSerial = createAsyncThunk('ACCOUNT/getProductSerial', async function doGetProductSerial(
  payload: GetProductSerial,
  { rejectWithValue }
) {
  const { product, accountId, accessTokenId } = payload

  try {
    const resp = await restClient(globalApiRoutes.GET_PRODUCT_SERIAL, {
      data: { product, accountId, accessTokenId }
    })

    return resp.data.productLicense
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const extendProductTrial = createAsyncThunk('ACCOUNT/extendProductTrial', async function doExtendProductTrial(
  payload: GetProductSerial,
  { rejectWithValue }
) {
  const { product, accountId, accessTokenId } = payload

  try {
    const resp = await restClient(globalApiRoutes.EXTEND_PRODUCT_TRIAL, {
      data: { product, accountId, accessTokenId }
    })

    return resp.data.productLicense
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})
