import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import reducerHandlers from 'global/redux/features/dataTables/reducerHandlers'
import { UpdateDataTable } from 'global/types/dataTables/dataTables'

import { DataTableState, GridColumns } from 'sen/redux/types/dataTables'

export const GRID_COLUMNS: GridColumns = {
  DATE: 'date',
  ACCOUNT_TAKEN_OVER: 'account_taken_over',
  ACTIVITY: 'activity',
  STATUS: 'status',
  DETAILS: 'details'
}

export const SORT_FIELDS = {}

export const COLUMNS_CONFIG = {
  [GRID_COLUMNS.DATE]: {
    sortable: false,
    orderIndex: 0,
    show: true
  },
  [GRID_COLUMNS.ACCOUNT_TAKEN_OVER]: {
    sortable: false,
    orderIndex: 1,
    show: true
  },
  [GRID_COLUMNS.ACTIVITY]: {
    sortable: false,
    orderIndex: 2,
    show: true
  },
  [GRID_COLUMNS.STATUS]: {
    sortable: false,
    orderIndex: 3,
    show: true
  },
  [GRID_COLUMNS.DETAILS]: {
    sortable: false,
    orderIndex: 4,
    show: true
  }
}

const ITEMS_PER_PAGE = 10
const ITEMS_PER_REQUEST = 10

// initialState
export const INITIAL_STATE: DataTableState = {
  SORT_FIELDS,
  GRID_COLUMNS,
  ITEMS_PER_REQUEST,
  ITEMS_PER_PAGE,
  columnsConfig: COLUMNS_CONFIG,
  skip: 0, // offset
  take: ITEMS_PER_PAGE, // limit
  filter: null,
  search: '',
  sort: []
}

export const alertsSlice = createSlice({
  name: 'DATA_TABLE/ALERTS',
  initialState: INITIAL_STATE,
  reducers: {
    update: {
      reducer: (state: DataTableState, action: PayloadAction<{ config: UpdateDataTable }>) => {
        return reducerHandlers({ COLUMNS_CONFIG }).update({ state, payload: action.payload })
      },
      prepare: (config: UpdateDataTable) => ({
        payload: { config }
      })
    },
    reset: () => {
      return {
        ...INITIAL_STATE
      }
    }
  }
})

export const { update, reset } = alertsSlice.actions

export default alertsSlice.reducer
